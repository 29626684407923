import dbService from './dbService';
import { insertOrUpdateDocument } from "./serviceUtils";
import generateHash from 'random-hash';
import * as UserService from "./userServices";

let db;

export async function initIndexes(){
	db = dbService();
	// let result = await createDocumentSearchIndex();
	// console.log("log: initIndexes -> createDocumentSearchIndex", result);
	let result = await createActivitySearchIndex();
	console.log("log: initIndexes -> createActivitySearchIndex", result);

	let activityPerUserIndex = await createActivityPerUserIndex();
    console.log("BeaconService: initIndexes -> documentsByTypeIndex", activityPerUserIndex);
}

// async function createDocumentSearchIndex() {
// 	try {
// 	  	var result = await db.createIndex({
// 			index: {
// 				fields: ["type", "_id"],
// 				name: "documentSearchIndex",
// 				ddoc: "documentSearchIndex",
// 				type: "json"
// 			}
// 		});
// 	  	return result;
// 	} catch (err) {
// 	  	console.error(err);
// 	}
// }

async function createActivitySearchIndex() {
	db = dbService();
	try {
	  	var result = await db.createIndex({
			index: {
				fields: ["type", "owner", "activityName", "beaconId", "firstName", "lastName"],
				//fields: ["type", "owner", "beaconCountryCode", "activityName", "lastEditDate"],
				name: "activitySearchIndex",
				ddoc: "activitySearchIndex",
				type: "json"
			}
		});
	  	return result;
	} catch (err) {
	  	console.error(err);
	}
}

export async function searchActivities(criterias, offset, limit) {
	console.log('ActivityService:searchActivities - criterias', criterias);
	console.time("ActivityService:searchActivities")
	db = dbService();
	let selector = {
		type: "tempActivity",
		owner: { $gte: null },
		activityName: { $gte: null },
		// beaconCountryCode: { $gte: null },
	};

	if(criterias.owner) {
		selector["owner"] = criterias.owner;
	}
	if(criterias.beaconCountryCode) {
		selector["beaconCountryCode"] = criterias.beaconCountryCode;
	}
	if(criterias.activityName) {
		// selector["activityName"] = { $gte: criterias.activityName, $lte: criterias.activityName + "\uffff" };
		selector["activityName"] = { $regex: "(?i)" + criterias.activityName + "(?i)" };
	}

	if(criterias.beaconId) {
		selector["beaconId"] = criterias.beaconId;
	}

	// for SAR
	if(criterias.isOngoing){
		let currentDate = Date.now();
		selector["$or"] = [
			{duration: 'ongoing'},
			{
				$and: [
					{durationSpecificStartDate: {$lte: currentDate} },
					{durationSpecificEndDate: {$gte: currentDate} }
				]
			}
		]
	}

	// search by account owner name
	if (criterias.accountOwnerName) {
		let userIds = await UserService.getUserProfileByOwnerName(criterias.accountOwnerName);
		selector["owner"] = { $in: userIds };
	}

	// search by beacon holder name: firstName + lastName
	if (criterias.holderName) {
		selector["$or"] = [{ firstName: { $regex: "(?i)" + criterias.holderName + "(?i)" } }, { lastName: { $regex: "(?i)" + criterias.holderName + "(?i)" } }];
	}

	console.log('ActivityService:searchActivities - selector', selector);

	try {
		var result = await db.find({
			selector: selector,
			use_index: "activitySearchIndex",
			// limit: 500
		});
		console.log('ActivityService:searchActivities - result', result);
		console.timeEnd("ActivityService:searchActivities")
		return result;
	} catch (err) {
		console.error("ActivityService:searchActivities - error", err);
		throw err;
	}
}

export async function saveActivity(activity, ownerId){
	db = dbService();
	try {
		const activityInfo = {
			_id: (activity._id == '' || activity._id === undefined) ? "tempActivity_" + generateHash({ length: 16 }) : activity._id,
			owner: ownerId,
			type: "tempActivity",
			...activity,

			/*activityName: activity.activityName || '',
			primaryActivity: activity.primaryActivity || '',
			secondaryActivity: activity.secondaryActivity || '',
			departureLocation: activity.departureLocation || '',
			departureNearestTown: activity.departureNearestTown || '',
			departureState: activity.departureState || '',
			departureCountry: activity.departureCountry || '',
			arrivalLocation: activity.arrivalLocation || '',
			arrivalNearestTown: activity.arrivalNearestTown || '',
			arrivalState: activity.arrivalState || '',
			arrivalCountry: activity.arrivalCountry || '',
			duration: activity.duration || '',
			durationUnspecified: activity.durationUnspecified || '',
			durationSpecificStartDate: activity.durationSpecificStartDate || '',
			durationSpecificEndDate: activity.durationSpecificEndDate || '',
			participantAdults: activity.participantAdults || '',
			participantTeens: activity.participantTeens || '',
			participantChildren: activity.participantChildren || '',
			participantAdditionalComments: activity.participantAdditionalComments || '',
			// equiptment: activity.equiptment || '',
			safetyEquiptment: activity.safetyEquiptment || '',
			communicationEquiptment: activity.communicationEquiptment || '',

			//beacon
			beaconId: activity.selectBeacon || '',
			beaconType: activity.beaconType || '',
			beaconCountryCode: activity.beaconCountryCode || '',
			// beaconMailCountry: activity.beaconMailCountry || '',

			holderId: activity.holderId || '',
			vehicleId: activity.vehicleId || ''*/
		};

		let result = await insertOrUpdateDocument(activityInfo, db);
		return result;

	} catch (err) {
        console.error('ActivityService:saveActivity - error', err);
	}
}

export async function getDocumentById(id) {
    try {
			db = dbService();
        let result = await db.get(id);
        return result;
    } catch (err) {
        console.error("ActivityService:getDocumentById - error", err);
    }
}



async function createActivityPerUserIndex() {
	try {
		var ddoc = {
		_id: "_design/activityPerUser",
		views: {
			activities: {
				map: function(doc) {
						emit(doc.type + doc.owner);
					}.toString(),
					reduce: "_count",
				},
			},
		};

		db = dbService();
		return await insertOrUpdateDocument(ddoc, db);
	} catch (error) {
		console.log("log: createUserCountIndex -> error", error);
	}
}

export async function getTotalActivitiesByOwner(owner) {
	console.log("log: getTotalActivitiesByOwner -> owner", owner);
	try {
	  db = dbService();
	  let result = await db.query("activityPerUser/activities", {
			// key: "tempActivity" + "org.couchdb.user:" + ownerName,
			key: "tempActivity" + owner,
			include_docs: false,
			group: true,
			reduce: true,
	  });
		console.log("log: getTotalActivitiesByOwner -> result", result);
		let nbRows = result.rows[0];
		return (nbRows && nbRows.value) || 0; //result.total_rows;
	} catch (error) {
	  	console.log("log: getBeaconTotalRows -> error", error);
	}
}

// export async function getDocumentByTypeAndId(type, id) {
// 	try {
// 		let result =  await db.find({
// 			selector: {
// 				type: type,
// 				_id: id,
// 				limit: 1
// 			},
// 			use_index: "documentSearchIndex"
// 		});
// 		return result;
// 	} catch (err) {
// 		console.error("ActivityService:getDocumentByTypeAndId - error", err);
// 	}
// }

