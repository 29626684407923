import i18n from "i18next";
import React, { Component } from "react";
import { Segment, Header, Icon, List, Button, Container, Accordion } from "semantic-ui-react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import DefaultPageLayout from "../../components/defaultPageLayout";
import SpecialMessage from '../specialMessage';
import AppConfigState from '../../state/appConfigState';
import AppState from '../../state/appState';
import "./faq.css";

@observer
export default class faq extends Component {

  state = {
    activeFAQId: '',
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props

    if(params.id) {
      this.setState({activeFAQId: params.id});
    }
  }

  render() {
    let currentLocale = AppState.currentLocale
      return (
          <DefaultPageLayout>
            <FaqMainPage activeFAQId={this.state.activeFAQId} currentLocale={currentLocale} />
          </DefaultPageLayout>
        );
  }
}

const FaqMainPage = ({ activeFAQId }) => {
  return (
    <Container className="faq-container">
      <Header as="h2" icon>
       {i18n.t('help-and-faq')}
      </Header>
      <SpecialMessage pageKey="Faq" />
      <Segment style={{ margin: "0 0 20px" }}>
        <FaqQuestionAndAnswer activeFAQId={activeFAQId} />
      </Segment>
    </Container>
  );
};

@observer
class FaqQuestionAndAnswer extends Component {
  state = {
    activeIndex: -1,
    panels: null
  }

  componentDidMount() {
    setTimeout(() => {
        this.getQuestionAndAnswer();
    }, 2000);
  }

  getQuestionAndAnswer() {
    let data = AppConfigState.getPublishedPageContentWithCurrentLanguage('FaqQ&A');
    let panels = [];
    for(let i=0; i<data.length; i++) {
      if(this.props.activeFAQId && this.props.activeFAQId == data[i].pageContentKey) {
        this.setState({ activeIndex: i });
      }
      panels.push({
        key: data[i].pageContentKey,
        title: data[i].subject,
        content: {
          content: ( <div dangerouslySetInnerHTML={{__html: data[i].content}} /> )
        }
      });
    }
    this.setState({ panels: panels });
  }

  handleTitleClick = (e, itemProps) => {
    const { index } = itemProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    return (
      <Accordion className="question-answer" fluid
        panels={this.state.panels}
        activeIndex={this.state.activeIndex}
        onTitleClick={this.handleTitleClick}
      />
    );
  }
}
