import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Header, Grid, Divider, Button, Icon, GridRow, GridColumn } from 'semantic-ui-react'
import AssociatedUserState from '../../../state/associatedUserState';
import AppConfigState from '../../../state/appConfigState'
import i18n from "i18next";
@observer
export default class NewUserReview extends Component {

	onEditUserDetailsClick = () => {
        AssociatedUserState.setCurrentStep(0);
	}

	onEditContactClick = () => {
        AssociatedUserState.setCurrentStep(1);
    }

	onBackClick = () =>{
		AssociatedUserState.setPreviousStep();
	}

	onSaveClick = () => {
		AssociatedUserState.saveAssociatedUser();
		AssociatedUserState.setNextStep();
	}

	render() {
		let userFields = AssociatedUserState.userFields;
		let userName = (userFields.firstName || '') + " " + (userFields.lastName || '');

		return (
                <Container className='activityReviewDetails-container animated fadeIn faster' >
					<div className='print-only'>
						<h2>{i18n.t('certificate-of-406-mhz-beacon-registration')}</h2>
					</div>

					<Header as='h2' className="beaconReviewDetails-header">
						<Header.Content>
							<span className="font-blue">{i18n.t('beacon-holder-summary')}</span>
						</Header.Content>
					</Header>
					<Grid columns={2} className="beaconReviewDetails-grid" stackable >
						<Grid.Row>
							<Grid.Column>
								<Header className="font-blue">{'Beacon Holder Detail'.toUpperCase()} <span className='noprint'>|</span> <a onClick={this.onEditUserDetailsClick} className="beaconReviewDetails-editLink noprint">Edit</a></Header>
								<div>{i18n.t('title')} : <strong>{userFields.title}</strong></div>
                                <div>{i18n.t('beacon-holder-name')} : <strong>{userName}</strong></div>
								<div>{i18n.t('relationship-to-beacon-owner')}: <strong>{userFields.userLink}</strong></div>
								<div>{i18n.t('language')}: <strong>{userFields.operationLanguage}</strong></div>
								<div>{i18n.t('medications-allergies-0')}: <strong>{userFields.medicalInfo}</strong></div>

								<div>{i18n.t('address')}: <strong>{userFields.address}</strong></div>
								<div>{i18n.t('city')}: <strong>{userFields.city}</strong></div>
								<div>{i18n.t('state-province')}: <strong>{userFields.state}</strong></div>
								<div>{i18n.t('country')}: <strong>{countryCodeToText(userFields.mailCountry)}</strong></div>
								<div>{i18n.t('zip-postal-code-0')}: <strong>{userFields.mailCode}</strong></div>

								<div>{i18n.t('phone-1')}: <strong>{userFields.phoneNumber} {userFields.phoneType ? '('+ phoneTypeToText(userFields.phoneType, userFields.phoneTypeOther) +')' : ''}</strong></div>
								<div>{i18n.t('phone-2')}: <strong>{userFields.phoneNumber2} {userFields.phoneType2 ? '('+ phoneTypeToText(userFields.phoneType2, userFields.phoneType2Other) +')' : ''}</strong></div>

                                <br/><br/>
                                <div>
                                    <a className="beaconReviewDetails-editLink noprint" onClick={() => window.print()}> <Icon name="print" color="grey" size="large" />{i18n.t('print-activity-summary')}</a>
                                </div>
							</Grid.Column>

							<Grid.Column>
                                <Header className="font-blue">{'Emergency Contact'.toUpperCase()} <span className='noprint'>|</span> <a onClick={this.onEditContactClick} className="beaconReviewDetails-editLink noprint">Edit</a></Header>
								<div>{i18n.t('24-hour-emergency-contact-name')}: <strong>{userFields.primaryContactName}</strong></div>
								<div>{i18n.t('emergency-contact-address-line-1')}: <strong>{userFields.primaryContactAddressLine1}</strong></div>
								<div>{i18n.t('emergency-contact-address-line-2')}: <strong>{userFields.primaryContactAddressLine2}</strong></div>
								<div>{i18n.t('emergency-contact-phone-number-1')}: <strong>{userFields.primaryPhone1Num}{userFields.primaryPhone1Type ? ' (' + phoneTypeToText(userFields.primaryPhone1Type, userFields.primaryPhone1TypeOther) + ')' : ""}</strong></div>
								<div>{i18n.t('emergency-contact-phone-number-2')}: <strong>{userFields.primaryPhone2Num}{userFields.primaryPhone2Type ? ' (' + phoneTypeToText(userFields.primaryPhone2Type, userFields.primaryPhone2TypeOther) + ')' : ""}</strong></div>
								<div>{i18n.t('emergency-contact-phone-number-3')}: <strong>{userFields.primaryPhone3Num}{userFields.primaryPhone3Type ? ' (' + phoneTypeToText(userFields.primaryPhone3Type, userFields.primaryPhone3TypeOther) + ')' : ""}</strong></div>
								<div>{i18n.t('emergency-contact-phone-number-4')}: <strong>{userFields.primaryPhone4Num}{userFields.primaryPhone4Type ? ' (' + phoneTypeToText(userFields.primaryPhone4Type, userFields.primaryPhone4TypeOther) + ')' : ""}</strong></div>

								<div>{i18n.t('alternate-24-hour-emergency-contact-name')}: <strong>{userFields.alternateContactName}</strong></div>
								<div>{i18n.t('alternate-contact-address-line-1')}: <strong>{userFields.alternateContactAddressLine1}</strong></div>
								<div>{i18n.t('alternate-contact-address-line-2')}: <strong>{userFields.alternateContactAddressLine2}</strong></div>
								<div>{i18n.t('alternate-contact-phone-number-1')}: <strong>{userFields.alternatePhone1Num}{userFields.alternatePhone1Type ? ' (' + phoneTypeToText(userFields.alternatePhone1Type, userFields.alternatePhone1Type) + ')' : ""}</strong></div>
								<div>{i18n.t('alternate-contact-phone-number-2')}: <strong>{userFields.alternatePhone2Num}{userFields.alternatePhone2Type ? ' (' + phoneTypeToText(userFields.alternatePhone2Type, userFields.alternatePhone2Type) + ')' : ""}</strong></div>
								<div>{i18n.t('alternate-contact-phone-number-3')}: <strong>{userFields.alternatePhone3Num}{userFields.alternatePhone3Type ? ' (' + phoneTypeToText(userFields.alternatePhone3Type, userFields.alternatePhone3Type) + ')' : ""}</strong></div>
								<div>{i18n.t('alternate-contact-phone-number-4')}: <strong>{userFields.alternatePhone4Num}{userFields.alternatePhone4Type ? ' (' + phoneTypeToText(userFields.alternatePhone4Type, userFields.alternatePhone4Type) + ')' : ""}</strong></div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<div className="activityDetail-submitContainer noprint">
						<Button className="registerBeacon-submitButton" onClick={this.onBackClick} >{i18n.t('back')}</Button>
						<Button className="registerBeacon-submitButton" type="submit" primary onClick={this.onSaveClick}>{i18n.t('save-user')}</Button>
					</div>
				</Container>
		)
	}
}

function phoneTypeToText(id, otherType = ""){
	let phoneTypes = AppConfigState.phoneTypes
	let item = phoneTypes.find(row => row.value == id)
	if(!item) return ''
	if(item.value == 'OTHR'){
		return item.text + ' - ' + otherType
	}
	return item.text
}

function countryCodeToText(countryCode){
	if(!countryCode) return

	let countryInfo = AppConfigState.countryNamesWithCode.find(row => row.key == countryCode)
	return countryInfo.text
}