import React, { Component } from 'react';
import { Form, Input, Button, Popup, Select, Header, Message, Icon } from 'semantic-ui-react';
import RouteState from '../../../state/routeState';
import AssociatedUserState from '../../../state/associatedUserState';
import i18n from "i18next";
// import '../registerActivity/registerActivity.css';

export default class NewUserComplete extends Component {

	returnToDashboardClick = () => {
		AssociatedUserState.resetSteps();
		RouteState.setRoute('/dashboard/users');
	}

	addNewUserClick = ()=>{
		AssociatedUserState.resetSteps();
		AssociatedUserState.resetData();
		RouteState.setRoute('/registerAssociatedUser');
	}


	render() {

		return (
			<div>

				<Header as='h2' icon textAlign='center'>
					<Icon name='check' circular color="green" />
					<Header.Content>{i18n.t('registration-complete')}</Header.Content>
				</Header>
				<div className="activityDetail-submitContainer">
						<Button className="registerBeacon-submitButton" onClick={this.returnToDashboardClick} >{i18n.t('return-to-dashboard')}</Button>
						<Button className="registerBeacon-submitButton" type="submit" primary onClick={this.addNewUserClick}>{i18n.t('add-a-new-user')}</Button>
					</div>
			</div>
		)
	}
}