import i18n from "i18next";
import React, { Component, useState } from "react";
import { Button, Label, Icon, Dropdown, Image, Popup, Modal, Header, Sidebar, Menu } from "semantic-ui-react";
import RouteState from "../state/routeState";
import UserState from "../state/userState";
import AppState from "../state/appState";
import logo from "../images/topmenu_406logo.png"
import { observer } from "mobx-react";
import "./topMenu.css";

const languageOption = [{ text: "English", value: "en" }, { text: "French", value: "fr" }];

const TopMenu = ({ nLanguageChange, hasTopBanner = false }) => {
  let loggedInBar;
  let currentUser = UserState.currentUser;
  let currentUserProfile = UserState.currentUserProfile;
  let isOnline = AppState.isOnline;
  let accountButton;
  let emailValidated = true;
  const t = AppState.t;
  if (currentUser != null) {
    emailValidated = currentUserProfile.emailValidated == true;
    accountButton = (
      <Button basic icon onClick={() => RouteState.setRoute("/registerUser")}>
        {emailValidated ? "" : <Popup content={t("please-verify-your-email-address")} trigger={<Icon name="warning circle" color="yellow" />} />}
        {t("account")}
      </Button>
    );
  }

  function onClickHome() {
    let currentUser = UserState.currentUser;
    if (currentUser != null) {
      RouteState.setRoute("/dashboard");
    } else {
      RouteState.setRoute("/");
    }
  }

  function onCancelRoute() {
    RouteState.cancelRoute();
  }

  function onConfirmRoute() {
    RouteState.confirmRoute();
  }

  let defaultLanguage = AppState.currentLocale;
  console.log("log ~ file: topMenu.js ~ line 47 ~ TopMenu ~ defaultLanguage", defaultLanguage);

  let langDropdown = <Dropdown value={defaultLanguage} selection options={languageOption} onChange={onLanguageChange} />;

  const showRouteConfirm = RouteState.needToConfirmRoute;

  let isOpenBurgerMenu = RouteState.isShowBurgerMenu;
  function onOpenBurgerMenu(value) {
    RouteState.setIsShowBurgerMenu(value);
  }

  return (
    <>
      <OfflineBanner isOnline={isOnline} />
      <LoggedInBanner currentUser={currentUser} currentUserProfile={currentUserProfile} />
      <div className="home-topMenu">
        <Image src={logo} floated="left" onClick={() => RouteState.setRoute("/")} />

        <div className="home-topMenu-rightSection noprint">
          <Button basic onClick={onClickHome}>
            {t("home")}
          </Button>
          {accountButton}

          <Button basic onClick={() => RouteState.setRoute("/contact-list")}>
            {t("beacon-registration-contacts")}
          </Button>

          <Button basic onClick={() => RouteState.setRoute("/approved-tac")}>
            {t("approved-beacon-models-tacs")}
          </Button>

          {/* <Label basic as="a" className="home-topMenu-help" size="big">
            <Icon name="info circle" color="orange" />
            {i18n.t("HOME.HELP")}
          </Label> */}
          <Button basic icon onClick={() => RouteState.setRoute("/faq")}>
            <Icon name="info circle" color="orange" />
            {t("help")}
          </Button>
          {/* <Dropdown defaultValue="en" selection options={languageOption} onChange={onLanguageChange} /> */}
          {langDropdown}
        </div>

        <div className="burger-menu">
          <Button primary icon className="burger-btn" onClick={() => onOpenBurgerMenu(true)}>
            <Icon name="sidebar" className="burger-icon" />
          </Button>
          <BurgerMenu visible={isOpenBurgerMenu} setVisible={onOpenBurgerMenu} onClickHome={onClickHome} accountButton={accountButton} emailValidated={emailValidated} langDropdown={langDropdown} />
        </div>
      </div>
      <div className="clearFix" />

      {hasTopBanner === false ? "" : <TopBanner />}
      <Modal size="tiny" centered={false} open={showRouteConfirm} className="centered">
        <Modal.Content>
          <Header className="modal-header">{t("warning")}</Header>
          {t("do-you-want-to-cancel-registration")}
          <div className="activityDetail-submitContainer">
            <Button className="registerBeacon-submitButton" onClick={onConfirmRoute}>
              {t("yes")}
            </Button>
            <Button className="registerBeacon-submitButton" onClick={onCancelRoute} primary>
              {t("no")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default observer(TopMenu);

const TopBanner = () => {
  let state = RouteState.getState();
  //console.log('log: TopBanner -> state', state);
  const t = AppState.t;
  return (
    <div className="home-topBanner noprint">
      <div className="home-topBanner-welcomeMessage">{t("welcome-to-the-international-406mhz-beacon-registration-database")}</div>
    </div>
  );
};

const LoggedInBanner = ({ currentUser, currentUserProfile }) => {
  if (!currentUser) {
    return null;
  }
  let onLogout = () => {
    UserState.logOut();
  };
  const t = AppState.t;
  return (
    <div className="home-loggedInBanner noprint">
      <div className="home-loggedInBannerlogout" onClick={onLogout}>
        {" "}
        {t("logout")}{" "}
      </div>
      <div className="home-loggedInBanner-username">
        {t("logged-in-as")} {" " + currentUser.name}
      </div>
    </div>
  );
};

const OfflineBanner = ({ isOnline }) => {
  if (isOnline) return null;
  else {
    return <div className="home-offline-banner">You are currently offline</div>;
  }
};

const onLanguageChange = (e, { value }) => {
  console.log("********************* dropdown value:" + value);
  console.log("********************* current language:" + i18n.language);
  i18n.changeLanguage(value, (err, t) => {
    if (err) return console.log("something went wrong loading", err);
    console.log("********************* current language:" + i18n.language);
    AppState.setLanguage(value);
  });
};

const BurgerMenu = ({ visible, setVisible, onClickHome, accountButton, emailValidated, langDropdown }) => {
  const onClick_Home = () => {
    onClickHome();
    setVisible(false);
  };
  const onClick_Account = () => {
    RouteState.setRoute("/registerUser");
    setVisible(false);
  };
  const onClick_RegContact = () => {
    RouteState.setRoute("/contact-list");
    setVisible(false);
  };
  const onClick_ApproveTac = () => {
    RouteState.setRoute("/approved-tac");
    setVisible(false);
  };
  const onClick_Faq = () => {
    RouteState.setRoute("/faq");
    setVisible(false);
  };
  const onClose = () => {
    setVisible(false);
  };
  const t = AppState.t;
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      // inverted
      onHide={() => setVisible(false)}
      vertical
      visible={visible}
      width="wide"
    >
      <Menu.Item as="a" onClick={onClick_Home}>
        <Icon name="home" />
        HOME
      </Menu.Item>
      {accountButton && (
        <Menu.Item as="a" onClick={onClick_Account}>
          {!emailValidated && <Popup content="Please verify your email address" trigger={<Icon name="warning circle" color="yellow" />} />}
          ACCOUNT
        </Menu.Item>
      )}
      <Menu.Item as="a" onClick={onClick_RegContact}>
        {t("beacon-registration-contacts")}
      </Menu.Item>
      <Menu.Item as="a" onClick={onClick_ApproveTac}>
        {t("approved-beacon-models-tacs")}
      </Menu.Item>

      <Menu.Item as="a" onClick={onClick_Faq}>
        {i18n.t("help")}
      </Menu.Item>
      <Menu.Item as="a">{langDropdown}</Menu.Item>
      <Button basic icon onClick={onClose}>
        <Icon name="close" color="red" size="big" />
      </Button>
    </Sidebar>
  );
};
