import PouchDB from "pouchdb";
import PouchDbAuth from "pouchdb-authentication";
import findPlugin from "pouchdb-find";
PouchDB.plugin(findPlugin);
import * as beaconService from "./beaconService";
import AppConfigState from "../state/appConfigState";
PouchDB.plugin(PouchDbAuth);
import AppState from "../state/appState";

let beaconDb_remote;
let userDb_remote;
let tacDb_remote;
let pocDb_remote;
let accessLog_remote;
let accountDb_remote;
let tokenDb_remote;

let beaconDb_local;
let userDb_local;
let tacDb_local;
let pocDb_local;
let accessLog_local;
let accountDb_local;
let tokenDb_local;

let isOnline = navigator.onLine;

export function initDbConnections() {
  const username = AppConfigState.dbConfig.username;
  const password = AppConfigState.dbConfig.password;
  const host = AppConfigState.dbConfig.host;
  const dbUrl = "https://" + username + ":" + password + "@" + host;
  console.log("log: initDbConnections -> dbUrl", dbUrl);
  accountDb_remote = new PouchDB(dbUrl + "/_users", { skip_setup: true });
  beaconDb_remote = new PouchDB(dbUrl + "/ibrd_dev", { skip_setup: true });
  userDb_remote = new PouchDB(dbUrl + "/userimport_dev", { skip_setup: true });
  tacDb_remote = new PouchDB(dbUrl + "/tac_dev", { skip_setup: true });
  pocDb_remote = new PouchDB(dbUrl + "/poc_dev", { skip_setup: true });
  accessLog_remote = new PouchDB(dbUrl + "/accesslog_dev", { skip_setup: true });
  tokenDb_remote = new PouchDB(dbUrl + "/tokens_dev", { skip_setup: true });

  accountDb_local = new PouchDB("_users", { size: 500, revs_limit: 20, auto_compaction: true });
  beaconDb_local = new PouchDB("ibrd_dev", { size: 500, cache: false, revs_limit: 20, auto_compaction: true });
  userDb_local = new PouchDB("userimport_dev", { size: 500, revs_limit: 20, auto_compaction: true });
  tacDb_local = new PouchDB("tac_dev", { size: 500, revs_limit: 20, auto_compaction: true });
  pocDb_local = new PouchDB("poc_dev", { size: 500, revs_limit: 20, auto_compaction: true });
  accessLog_local = new PouchDB("accesslog_dev", { size: 500, revs_limit: 20, auto_compaction: true });
}

export function getAccessLogDb(getOffline) {
  if (AppState.isOnline) {
    return accessLog_remote;
  } else {
    return accessLog_local;
  }
}

export function getAccountDb(getOffline) {
  if (AppState.isOnline) {
    return accountDb_remote;
  } else {
    return accountDb_local;
  }
}

export function getOfflineAccountDb() {
  return accountDb_local;
}

export default function db(getOffline) {
  if (AppState.isOnline) {
    return beaconDb_remote;
  } else {
    return beaconDb_local;
  }
}

export function getOfflineBeaconDb() {
  return beaconDb_local;
}

export function getUserProfileDb(getOffline) {
  if (AppState.isOnline) {
    return userDb_remote;
  } else {
    return userDb_local;
  }
}

export function getOfflineUserProfileDb() {
  return userDb_local;
}

export function getPocDb(getOffline) {
  if (AppState.isOnline) {
    return pocDb_remote;
  } else {
    return pocDb_local;
  }
}

export function getTokenDb(getOffline) {
  if (AppState.isOnline) {
    return tokenDb_remote;
  } else {
    return tokenDb_local;
  }
}

export function getTACDb(getOffline) {
  if (AppState.isOnline) {
    return tacDb_remote;
  } else {
    return tacDb_local;
  }
}

export function replicateDb() {
  console.log('log ~ file: dbService.js ~ line 119 ~ replicateDb ~ replicateDb');
  beaconDb_local.replicate
    .to(beaconDb_remote)
    .on("complete", function() {
      console.log("log ~ file: dbService.js ~ line 122 ~ .on replicateDb ~ complete", complete);
    })
    .on("error", function(err) {
      console.error("log ~ file: dbService.js ~ line 126 ~ .on  replicateDb ~ err", err);
    });

    userDb_local.replicate
    .to(userDb_remote)
    .on("complete", function() {
      console.log("log ~ file: dbService.js ~ line 122 ~ .on  replicateDb ~ complete", complete);
    })
    .on("error", function(err) {
      console.error("log ~ file: dbService.js ~ line 126 ~ .on  replicateDb ~ err", err);
    });
}

export async function offlineIndexExists() {
  try {
    let result = await beaconDb_local.get("_design/documentsByType");
    console.log("log ~ file: dbService.js ~ line 121 ~ offlineIndexExists ~ result", result);
    return true;
  } catch (error) {
    console.log("log ~ file: dbService.js ~ line 123 ~ offlineIndexExists ~ error", error);
    return false;
  }
}

export async function storeOffline(object, db) {
  console.log("log ~ file: dbService.js ~ line 111 ~ storeOffline ~ object", object);
  let _id = object._id;
  console.log("log ~ file: dbService.js ~ line 121 ~ storeOffline ~ _id", _id);
  let _rev;
  try {
    let oldObject = await db.get(_id);
    console.log("log ~ file: dbService.js ~ line 115 ~ storeOffline ~ oldObject", oldObject);
    _rev = oldObject._rev;
  } catch (error) {
    console.log("log ~ file: dbService.js ~ line 116 ~ storeOffline ~ error", error);
  }

  if (_rev) {
    object._rev = _rev;
  }
  try {
    return await db.put(object, { force: true });
  } catch (error) {
    console.log("log ~ file: dbService.js ~ line 135 ~ storeOffline ~ error", error);
    throw error;
  }
}
