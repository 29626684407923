import { action, observable, computed, toJS } from "mobx";
import beaconDecode from "../utils/beaconDecode/BeaconDecode";
import * as UserService from "../services/userServices";
import * as BeaconService from "../services/beaconService";
import AppConfigState from "./appConfigState";
import * as MailService from "../services/mailService";
import UserState from "./userState";
import { createDelegateBeaconToken, createSetBeaconToSoldToken } from "../services/authActionService";
import decodeBeacon from "../utils/beaconDecode/gen2decode";
import * as PocService from "../services/pocService";

class RegistrationState {
  @observable currentStep = 0;
  @observable beaconCurrentStep = 0;
  @observable ownerCurrentStep = 0;
  @observable vehiculeCurrentStep = 0;
  @observable isNewUser = false;
  @observable beaconHex = "";
  @observable decodedBeacon = {};
  @observable user;
  @observable errors = {};
  @observable userToEdit;
  @observable isNewRegisterBeacon = true;
  @observable isPrintCertificate = false;
  @observable isBeaconSaved = false;
  @observable isSaving = false;
  @observable hasRegulationUrl = false;
  @observable isPrintSummary = false;
  @observable isUpdateLastEditDate = false;
  @observable showCompleteScreen = false;
  newUserAccountValue = {};
  hasUnsavedChanges = false;

  constructor() {

    
  }

  @action setIsNewUser(value){
    this.isNewUser = value;
  }

  @action setShowCompleteScreen(value){
    this.showCompleteScreen = value;
  }

  @action setIsPrintSummary(value) {
    this.isPrintSummary = value;
  }

  @action setHasRegulationUrl(value) {
    this.hasRegulationUrl = value;
  }

  @action setIsSaving(value) {
    this.isSaving = value;
  }

  @action setIsBeaconSaved(value) {
    this.isBeaconSaved = value;
  }

  @action setIsPrintCertificate(value) {
    this.isPrintCertificate = value;
  }

  @action setIsNewRegisterBeacon(value) {
    this.isNewRegisterBeacon = value;
  }

  @action updateBeaconFields(values) {
    console.log("log: RegistrationState -> @actionupdateBeaconFields -> values", values);
    this.decodedBeacon = { ...this.decodedBeacon, ...values };
    console.log('log: RegistrationState -> @actionupdateBeaconFields -> this.decodedBeacon', this.decodedBeacon.vehicleName);
  }


  @action setCurrentStep(step) {
    console.log("IBRD: RegistrationState -> setCurrentStep -> step", step);
    this.currentStep = step;
  }

  @action setNextStep() {
    this.currentStep = this.currentStep + 1;
  }

  @action setPreviousStep() {
    this.currentStep = this.currentStep - 1;
  }

  @action setBeaconNextStep() {
 
    this.setNextStep();
  }

  @action setBeaconPreviousStep() {
    this.beaconCurrentStep = this.beaconCurrentStep - 1;
  }

  @action setOwnerStep(step) {
    this.ownerCurrentStep = step;
  }

  @action setOwnerNextStep() {
     this.setNextStep();
  }

  @action setOwnerPreviousStep() {
    this.setPreviousStep();
  }

  @action setVehiculeStep(step) {
    this.vehiculeCurrentStep = step;
  }

  @action setVehiculeNextStep() {
    // if (this.vehiculeCurrentStep === 2) {
    //   this.setNextStep();
    // } else {
    //   this.vehiculeCurrentStep = this.vehiculeCurrentStep + 1;
    // }
    this.setNextStep();
  }

  @action setVehiculePreviousStep() {
    if (this.vehiculeCurrentStep === 0) {
      this.setPreviousStep();
    } else {
      this.vehiculeCurrentStep = this.vehiculeCurrentStep - 1;
    }
  }

  saveOnFieldChange(name, value){

    this.decodedBeacon[name] = value;
  }

  async saveBeacon(isUpdateStatus=false) {
    try {

      let decodedBeacon = toJS(this.decodedBeacon)

      let beaconExist;
      try {
        beaconExist = await BeaconService.getBeaconByHexId(decodedBeacon.hexId);
      } catch (error) {
        beaconExist = null;
        console.log("log: RegistrationState -> saveBeacon -> beacon doesn't exist", error);
      }

      //trigger delegation process
      let processDelegation = false;
      let roleId = UserState.currentUserProfile ? UserState.currentUserProfile.roleId : "5";
      if(roleId==="1") {
        // beacon is created by NDP
        decodedBeacon.createdByNdp = UserState.currentUserProfile._id;

        if(decodedBeacon.isDelegated) {
          if(!beaconExist) { //NDP delegates new beacon
            processDelegation = true;
          }
          else if (beaconExist.isDelegated != decodedBeacon.isDelegated) { //NDP delegates existing beacon
            processDelegation = true;
          }
          else if (beaconExist.emailAddress != decodedBeacon.emailAddress) { //NDP changes beacon owner's email address or update email for NDP Delegated Incomplete
            processDelegation = true;
          }
        }
        else {
          //NDP Managed
          decodedBeacon.owner = ""; //remove beacon owner
          //reset delegation
          decodedBeacon.isDelegated = false;
          decodedBeacon.delegatedDate = null;
          decodedBeacon.delegateConfirmDate = null;
          delete decodedBeacon.delegationIncomplete;
        }
      }

      //manage beacon's owner
      let token = null;
      let sendToEmail;
      if (roleId == "7") {
        //not change owner
      }
      else if (UserState.currentUserProfile) {
        if(roleId == "5") {
          decodedBeacon.owner = UserState.currentUserProfile._id;
          sendToEmail = UserState.currentUserProfile.emailAddress;
        }
      }
      else if (this.userToEdit) {
        //user registered but haven't login before adding beacon
        let user = await UserService.getUserObject(this.userToEdit.username);
        if (user) {
          decodedBeacon.owner = user._id;
          sendToEmail = user.emailAddress;
        }
      }
      console.log("log: RegistrationState -> saveBeacon -> assign owner: ", decodedBeacon.owner);

      //set default value for indexedFields if not present (search and sort).
      if (!decodedBeacon.aircraft24BitAddress) {
        decodedBeacon.aircraft24BitAddress = "";
      }
      if (!decodedBeacon.aircraft24BitAddressDecoded) {
        decodedBeacon.aircraft24BitAddressDecoded = "";
      }
      if (!decodedBeacon.typeApprovalCertificate) {
        decodedBeacon.typeApprovalCertificate = "";
      }
      if (!decodedBeacon.serialNumber) {
        decodedBeacon.serialNumber = "";
      }
      if (!decodedBeacon.owner) {
        decodedBeacon.owner = "";
      }
      if (!decodedBeacon.vehicleName) {
        decodedBeacon.vehicleName = "";
      }
      if (!decodedBeacon.callSign) {
        decodedBeacon.callSign = "";
      }
      if (!decodedBeacon.MMSI) {
        decodedBeacon.MMSI = "";
      }
      if (!decodedBeacon.vehicleRegistrationNumber) {
        decodedBeacon.vehicleRegistrationNumber = "";
      }
      // if (!this.decodedBeacon.lastConfirmationDate) {
      //   this.decodedBeacon.lastConfirmationDate = "";
      // }
      if (!decodedBeacon.vehicleType) {
        decodedBeacon.vehicleType = "";
      }

      //set default beacon special status if not exist
      if (!decodedBeacon.specialStatus || this.decodedBeacon.specialStatus == "") {
        decodedBeacon.specialStatus = "ACTIVE";
      }

      //remove hex because we already got hexId from beaconDecode function
      delete decodedBeacon.hex;

      if(decodedBeacon.specialStatus && decodedBeacon.specialStatus != "RELEASETOMAINTENANCE") {
        //restore current maintProvider or set blank
        if(beaconExist && beaconExist.maintProvider) {
          decodedBeacon.maintProvider = beaconExist.maintProvider;
        }
        else {
          decodedBeacon.maintProvider = "";
        }
      }

      //remove searchers if beacon special status is changed
      if(beaconExist && beaconExist.specialStatus != decodedBeacon.specialStatus) {
        decodedBeacon.searchers = null;
      }

      if(!beaconExist) {
        decodedBeacon.initialDate = new Date();
      }
      //this.decodedBeacon.lastEditDate = Date.now(); //this is automatically updated via the core function
      console.log("log: RegistrationState -> saveBeacon -> data before save: ", decodedBeacon);
      //save beacon
      let result = await BeaconService.saveBeacon(decodedBeacon);
      let getBeacon = await BeaconService.getBeaconByHexId(decodedBeacon.hexId);
      console.log("log: RegistrationState -> saveBeacon -> result", result, getBeacon);
      console.log("this.decodedBeacon", decodedBeacon)

      if(processDelegation) {
        result = await this.delegateBeaconToUser(decodedBeacon);
      }
      else {
        //send confirmation email
        let isSendEmail = false;
        let notSendEmailByStatus = ['SOLD', 'RELEASETOMAINTENANCE'];
        let beaconStatus = decodedBeacon.specialStatus;
        if(!notSendEmailByStatus.includes(beaconStatus)){
          if(roleId == "1") {
            //NDP -> not send email for "managed" beacon
            isSendEmail = decodedBeacon.isDelegated && !decodedBeacon.delegationIncomplete;
          }
          else {
            isSendEmail = true;
          }
        }

        if (isSendEmail && sendToEmail) {
          let emailTemplateKey;
          if(isUpdateStatus) {
            emailTemplateKey = AppConfigState.getEmailTemplateKey("BEACON_STATUS");
          }
          else if(roleId == "5") {
            if(beaconExist)
              emailTemplateKey = AppConfigState.getEmailTemplateKey("BEACON_MODIFICATION");
            else
              emailTemplateKey = AppConfigState.getEmailTemplateKey("BEACON_REGISTRATION");
          }

          if (emailTemplateKey) {
            const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
            let emailData = MailService.prepareEmailData(emailTemplate, decodedBeacon, null, token, null);
            let response;
            if(isUpdateStatus) {
              response = await MailService.sendMailWithAttachment(sendToEmail, emailData.subject, emailData.content, emailData.beaconInfo);
            }
            else {
              response = await MailService.sendMail(sendToEmail, emailData.subject, emailData.content);
            }
          }
        }
      }
      return result;
    } catch (error) {
      console.log("log: RegistrationState -> saveBeacon -> error", error);
    }
  }

  async updateBeacon() {
    try {
      //this.decodedBeacon.lastEditDate = Date.now(); //this is automatically updated via the core function
      let result = await BeaconService.updateBeacon(this.decodedBeacon);
      return result;
    } catch (error) {
      console.log("log: RegistrationState -> updateBeacon -> error ", error);
    }
  }

  async updateBeaconLastEditDate() {
    try {
      let allowToUpdate = ["5"];
      if(!allowToUpdate.includes(UserState.currentUserProfile.roleId)) return;
      let beaconExist = await BeaconService.getBeaconByHexId(this.decodedBeacon.hexId);
      //beaconExist.lastEditDate = Date.now(); //this is automatically updated via the core function
      let result = await BeaconService.updateBeacon(beaconExist);
      this.setIsUpdateLastEditDate(true);
      return result;
    } catch (error) {
      console.log("log: RegistrationState -> updateBeacon -> error ", error);
    }
  }

  @action setIsUpdateLastEditDate(value){
    this.isUpdateLastEditDate = value;
  }

  @action resetBeaconData() {

    console.log('log: RegistrationState -> @actionresetBeaconData -> resetBeaconData');
    this.decodedBeacon = {};
    this.beaconHex = "";
    this.isPrintCertificate = false;
    this.isBeaconSaved = false;
    this.isPrintSummary = false;
    this.showCompleteScreen = false;
    this.hasUnsavedChanges = false;
    this.currentStep = 0;
  }

  async bulkUpdateDelegationStatus(beaconIds, delegationStatusToUpdate) {
    console.log("RegistrationState: bulkUpdateDelegationStatus (" + (delegationStatusToUpdate ? "NDP Delegated" : "NDP Managed") + ") -> beaconIds", beaconIds)
    let count = 0;
    let error = 0;
    let statusError = 0;
    let result = await BeaconService.getBeaconByIds(beaconIds);
    if(result.rows) {
      console.log("RegistrationState: bulkUpdateDelegationStatus -> getBeaconByIds", result.rows);
      for (let item of result.rows) {
        let beacon = item.doc;

        let notUpdateStatus = ['SOLD', 'RELEASETOMAINTENANCE'];
        if(beacon.specialStatus && notUpdateStatus.includes(beacon.specialStatus)){
          statusError++;
        }
        else {
          beacon.isDelegated = delegationStatusToUpdate;
          if(delegationStatusToUpdate) {
            result = await this.delegateBeaconToUser(beacon);
            if(!result) {
              error++;
            }
          }
          else {
            //NDP Managed
            beacon.owner = ""; //remove beacon owner
            //reset delegation
            beacon.isDelegated = false;
            beacon.delegatedDate = null;
            beacon.delegateConfirmDate = null;
            delete beacon.delegationIncomplete;
            result = await BeaconService.saveBeacon(beacon);
            console.log("RegistrationState: bulkUpdateDelegationStatus -> NDP Managed result", result)
          }
        }
        count++;
      }
    }

    result = {affected: count, error: error, statusError: statusError};
    console.log("RegistrationState: bulkUpdateDelegationStatus -> result", result)
    return result;
  }

  async delegateBeaconToUser(beacon) {
    debugger;
    if(!beacon.emailAddress) {
      beacon.isDelegated = true;
      beacon.delegatedDate = Date.now();
      beacon.delegateConfirmDate = null;
      beacon.delegationIncomplete = true;
      let result = await BeaconService.saveBeacon(beacon);
      console.log("log: RegistrationState -> delegateBeaconToUser (no email) -> saveBeacon", result, beacon);
      return false;
    }

    //manage beacon's owner
    let token;
    let templateName;
    let userExist = await UserService.checkIfEmailExist(beacon.emailAddress);
    if (userExist) {
      beacon.owner = userExist._id;
      token = await createDelegateBeaconToken(beacon._id);
      templateName = "DELEGATE_BEACON_REGISTRATION";
    } else {
      let userResult = await this.createUserForNDPDelegate(beacon);
      console.log("log: RegistrationState -> delegateBeaconToUser -> create owner's user result", userResult);
      beacon.owner = userResult.userId;
      token = userResult.validateMailToken;
      templateName = "DELEGATE_BEACON_REGISTRATION_NEWUSER";
    }
    console.log("log: RegistrationState -> delegateBeaconToUser -> assign owner: ", beacon.owner);

    beacon.isDelegated = true;
    beacon.delegatedDate = Date.now();
    beacon.delegateConfirmDate = null;
    delete beacon.delegationIncomplete;
    let result = await BeaconService.saveBeacon(beacon);
    console.log("log: RegistrationState -> delegateBeaconToUser -> saveBeacon", result, beacon);

    //get poc
    let midInfo = AppConfigState.config.MIDInfo.find((row) => row.Mid == beacon.beaconCountryCode);
    let beaconType = -1;
    if (beacon.beaconType.includes("EPIRB")) {
      beaconType = "0";
    } else if (beacon.beaconType.includes("ELT")) {
      beaconType = "1";
    } else if (beacon.beaconType.includes("PLB")) {
      beaconType = "2";
    } else if (beacon.beaconType.includes("SSAS")) {
      beaconType = "3";
    }
    let pocInfo = await this.getPointOfContact(midInfo, beaconType);

    //send confirmation email
    const emailTemplateKey = AppConfigState.getEmailTemplateKey(templateName);
    const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
    let emailData = MailService.prepareEmailData(emailTemplate, beacon, null, token, pocInfo);
    let response = await MailService.sendMail(beacon.emailAddress, emailData.subject, emailData.content);

    return true;
  }

  async createUserForNDPDelegate(decodedBeacon) {
    try {
      //create a new user for beacon owner
      let ownerUser = {};
      ownerUser.ownerName = decodedBeacon.ownerName;
      ownerUser.emailAddress = decodedBeacon.emailAddress;
      ownerUser.medicalInfo = decodedBeacon.medicalInfo;
      ownerUser.operatorLanguage = decodedBeacon.operatorLanguage;
      ownerUser.address = decodedBeacon.address;
      ownerUser.city = decodedBeacon.city;
      ownerUser.province = decodedBeacon.province;
      ownerUser.mailCountry = decodedBeacon.mailCountry;
      ownerUser.mailCode = decodedBeacon.mailCode;
      ownerUser.phone1Num = decodedBeacon.phone1Num;
      ownerUser.phone1Type = decodedBeacon.phone1Type;
      ownerUser.phone2Num = decodedBeacon.phone2Num;
      ownerUser.phone2Type = decodedBeacon.phone2Type;
      ownerUser.phone3Num = decodedBeacon.phone3Num;
      ownerUser.phone3Type = decodedBeacon.phone3Type;
      ownerUser.phone4Num = decodedBeacon.phone4Num;
      ownerUser.phone4Type = decodedBeacon.phone4Type;
      ownerUser.hexId = decodedBeacon.hexId;
      ownerUser.emailValidated = false;
      ownerUser.challengeQuestion = "";
      ownerUser.challengeResponse = "";
      ownerUser.requireToSetPassword = true;
      let userResult = await UserService.lambdaCreateDelegatedUser(ownerUser);
      return userResult;
    }
    catch (error) {
      console.log("IBRD: RegistrationState -> createUserForNDPDelegate -> error", error);
      throw error;
    }
  }

  @action setError(errors, errorForm) {
    this.error[errorForm] = errors;
  }

  @action resetSteps() {
    this.currentStep = 0;
    this.beaconCurrentStep = 0;
    this.ownerCurrentStep = 0;
    this.vehiculeCurrentStep = 0;
  }

  setBeaconCurrentStep(step) {
    console.log("IBRD: RegistrationState -> setBeaconCurrentStep -> step", step);
    this.beaconCurrentStep = step;
  }

  async processAttemptToRegisterBeacon(hex) {
    let beacon = await BeaconService.getBeaconByHexId(hex);      
    let user = await UserService.getUserProfile(beacon.owner);
    console.log("log: processAttemptToRegisterBeacon, beacon & user", beacon, user);
    
    if(user && user.emailAddress) {
      let token = await createSetBeaconToSoldToken(beacon._id, user._id);      
      const emailTemplateKey = AppConfigState.getEmailTemplateKey("ATTEMPT_REGISTER_EXISTED_BEACON");
      const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
      let emailData = MailService.prepareEmailData(emailTemplate, beacon, user, token, null);
      let response = await MailService.sendMail(user.emailAddress, emailData.subject, emailData.content);
      //console.log("log: processAttemptToRegisterBeacon -> response", response);
    }

    //keep searcher id into beacon if searcher has an account
    if(UserState.currentUserProfile) {      
      if(beacon.searchers == null)
        beacon.searchers = [UserState.currentUserProfile._id];
      else { //if(!beacon.searchers.includes(UserState.currentUserProfile._id)) {          
        let searchers = [...beacon.searchers, UserState.currentUserProfile._id];
        beacon.searchers = searchers;
      }
    
      let result = await BeaconService.updateBeacon(beacon);
      //console.log("log: processAttemptToRegisterBeacon -> add searchers into beacon", response);
    }

  }

  async validateHexForRegistration(hex) {
    console.log('log: RegistrationState -> validateHexForRegistration -> hex', hex);
    let alreadyExist = await BeaconService.checkIfHexIsRegistered(hex);
    if (alreadyExist) {
      
      //send message to the user who own beacon
      //LP: https://app.liquidplanner.com/space/93943/projects/show/61867367
      //await this.processAttemptToRegisterBeacon(hex);

      let error = {
        //code: "1",
        code: "SOLD_STATUS",
        message: "This Beacon HexID has been previously registered in the IBRD. Click OK button to send a request to the previous owner to release this registration record – you will be notified if the previous owner releases the record, and you will receive a notification to return to the IBRD website to complete your own registration of HexID " + hex + ". If the previous owner does not release the record, please ensure that you have proof of ownership of the device and contact us using the contact form <a href='/contact'>here</a>."
      };
      throw error;
    }

    if (!hex) {
      let error = {
        //code: "1",
        message: "Hex ID is required!",
      };
      throw error;
    }

    let decodedBeacon = await this.decodedBeaconInfo(hex);
    if (!decodedBeacon) {
      let error = {
        //code: "1",
        message: "Beacon could not be decoded",
      };
      throw error;
    }
    console.log("decodedBeacon", decodedBeacon);
    let midInfo = AppConfigState.config.MIDInfo.find((row) => row.Mid == decodedBeacon.beaconCountryCode);

    if (!midInfo) {
      console.log("validateHexForRegistration -> Cannot find MID info for this beacon", decodedBeacon);
      let message = this.getValidationMessage(decodedBeacon, null);
      let error = {
        code: "2",
        message: message,
      };
      throw error;
    }
    let beaconType = "-1";
    if (decodedBeacon.beaconType) {
      if (decodedBeacon.beaconType.includes("EPIRB")) {
        beaconType = "0";
      } else if (decodedBeacon.beaconType.includes("ELT")) {
        beaconType = "1";
      } else if (decodedBeacon.beaconType.includes("PLB")) {
        beaconType = "2";
      } else if (decodedBeacon.beaconType.includes("SSAS")) {
        beaconType = "3";

        console.log("validateHexForRegistration -> SSAS beacon is not support on IBRD", decodedBeacon);
        let pocInfo = await this.getPointOfContact(midInfo, beaconType);
        let message = this.getValidationMessage(decodedBeacon, pocInfo);
        let error = {
          code: "3",
          message: message,
        };
        throw error;
      } else if (decodedBeacon.beaconType.includes("TEST")) {
        beaconType = "4";

        console.log("validateHexForRegistration -> This is a Test Beacon, IBRD is not support to register the Test Beacon.", decodedBeacon);
        let pocInfo = await this.getPointOfContact(midInfo, beaconType);
        let message = this.getValidationMessage(decodedBeacon, pocInfo);
        let error = {
          code: "4",
          message: message,
        };
        throw error;
      }
    }
    let roleId = UserState.currentUserProfile ? UserState.currentUserProfile.roleId : "5";
    if (roleId == "5") {
      if (midInfo.Supported != "Y") {
        console.log("validateHexForRegistration -> IBRD is not allow user to register a beacon.", decodedBeacon);
        let pocInfo = await this.getPointOfContact(midInfo, beaconType);
        let message = this.getValidationMessage(decodedBeacon, pocInfo);
        let error = {
          code: "5",
          message: message,
        };
        throw error;
      }
      if (!midInfo.SupportedTypes.includes(beaconType)) {
        console.log("validateHexForRegistration -> A beacon with type " + decodedBeacon.beaconType + " is not allow to register.", decodedBeacon);
        let pocInfo = await this.getPointOfContact(midInfo, beaconType);
        let message = this.getValidationMessage(decodedBeacon, pocInfo);
        let error = {
          code: "6",
          message: message,
        };
        throw error;
      }
    } else if (roleId == "1") {
      if (!UserState.currentUserProfile.countryNumber || !UserState.currentUserProfile.countryNumber.includes(decodedBeacon.beaconCountryCode)) {
        console.log("validateHexForRegistration -> IBRD is not allow NDP user to register a beacon.", decodedBeacon);
        let pocInfo = await this.getPointOfContact(midInfo, beaconType);
        let message = this.getValidationMessage(decodedBeacon, pocInfo);
        let error = {
          code: "7",
          message: message,
        };
        throw error;
      }
      if (!midInfo.NDPSupportedTypes.includes(beaconType)) {
        console.log("validateHexForRegistration -> A beacon with type " + decodedBeacon.beaconType + " is not allow to register.", decodedBeacon);
        let pocInfo = await this.getPointOfContact(midInfo, beaconType);
        let message = this.getValidationMessage(decodedBeacon, pocInfo);
        let error = {
          code: "8",
          message: message,
        };
        throw error;
      }
    } else {
      console.log("validateHexForRegistration -> Please check if the system support this role", roleId);
      let error = {
        //code: "9",
        message: "Please check if the system support this role " + roleId,
      };
      throw error;
    }
  }

  getValidationMessage(decodedBeacon, pocInfo) {
    console.log("log: RegistrationState -> getValidationMessage -> decodedBeacon", decodedBeacon);
    let content = "<b>The beacon country is not supported by this registry. Contact info if available:</b><br/>";
    content = content.concat("<b>", decodedBeacon.countryName.toUpperCase(), "(", decodedBeacon.beaconCountryCode, ")</b>");
    if (pocInfo) {
      content = content.concat("<h3>", pocInfo.name, "</h3>");
      content = content.concat("<p>", pocInfo.address, "</p>");
      content = content.concat("<p>City: ", pocInfo.city || "", "<br/>");
      content = content.concat("Zip code: ", pocInfo.zipcode || "", "<br/>");
      content = content.concat("Fax: ", pocInfo.fax1 || "", "<br/>");
      content = content.concat("Phone: ", pocInfo.telephone1 || "", "<br/>");
      content = content.concat("Email: ", pocInfo.email ? "<a href=mailto:" + pocInfo.email + ">" + pocInfo.email : "", "</a><br/>");
      content = content.concat("Website: ", pocInfo.website_url ? "<a target='_blank' href=" + pocInfo.website_url + ">" + pocInfo.website_url : "", "</a><br/>");
      content = content.concat(
        "Handbook: ",
        pocInfo.website_url ? "<a target='_blank' href=" + pocInfo.website_url + ">" + pocInfo.website_url : "",
        "</a><br/>"
      );
      content = content.concat("</p>");
      content = content.replace(/\r\n/g, "<br/>");
    }
    return content;
  }

  async getPointOfContact(midInfo, beaconType) {
    let pocIndex = midInfo.REGCrossRef;
    if (beaconType == "0" && midInfo.REGEPIRB != "0") {
      pocIndex = midInfo.REGEPIRB;
    } else if (beaconType == "1" && midInfo.REGELT != "0") {
      pocIndex = midInfo.REGELT;
    } else if (beaconType == "2" && midInfo.REGPLB != "0") {
      pocIndex = midInfo.REGPLB;
    } else if (beaconType == "3" && midInfo.REGSSAS != "0") {
      pocIndex = midInfo.REGSSAS;
    }

    if (pocIndex !== "0") {
      try {
        let result = await PocService.getPOCByIndex(pocIndex);
        //console.log("RegistrationState:getPointOfContact -> result", pocIndex, result.docs[0]);
        return result;
      } catch (error) {
        console.error("RegistrationState:getPointOfContact -> error", pocIndex, error);
      }
    }
    return null;
  }

  async submitHexForRegistration(hex) {
    console.log("log: RegistrationState -> submitHexForRegistration -> hex", hex);
    this.beaconHex = hex;
    let decodedBeacon = await beaconDecode(hex);
    if (this.user) {
      decodedBeacon = { ...decodedBeacon, ...this.user };
    }
    console.log("log: RegistrationState -> submitHexForRegistration -> decodedBeacon", decodedBeacon);

    this.setDecodedBeacon(decodedBeacon);

    return decodedBeacon;

  }

  @action setDecodedBeacon(beacon) {
    this.decodedBeacon = beacon;
  }

  async checkIfUsernameExist(values) {
    console.log('log: RegistrationState -> checkIfUsernameExist -> values', values);

    try {
      let result = await UserService.checkIfUsernameExist(values.username);

      console.log('log: RegistrationState -> checkIfUsernameExist -> result', result);
      return result;
    } catch (error) {
      console.log('log: RegistrationState -> checkIfUsernameExist -> error', error);
      this.newUserAccountValue = values;
      this.userToEdit = values;
      throw error;
    }
  }

  async checkIfEmailExist(emailAddress) {
    if (emailAddress == "") {
      return false;
    }
    try {
      let result = await UserService.checkIfEmailExist(emailAddress);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async signUp(values) {

    try {
      //let values = this.newUserAccountValue; //That did not work? Because checkIfUsernameExist in userService was changed to find?
      values.hexId = this.beaconHex;
      values.emailValidated = false;
      console.log('log: RegistrationState -> signUp -> values', values);

      let result = await UserService.lambdaSignup(values);
      console.log("log: RegistrationState -> signUp -> result", result);

      if (!result.error) {
        this.isNewUser = true;

        let response;
        if (values.emailAddress) {
          //send confirmation email (for block user)
          const emailTemplateKey = AppConfigState.getEmailTemplateKey("USER_REGISTRATION");
          const emailTemplate = AppConfigState.getEmailTemplate(emailTemplateKey);
          let emailData = MailService.prepareEmailData(emailTemplate, null, values, result.validateMailToken, null);
          let response = await MailService.sendMail(values.emailAddress, emailData.subject, emailData.content);

          if (response && response.err) {
            console.log("send mail response -> err", response);
            result.error = "send mail error";
            result.reason = response.err.message;
            result.status = response.err.statusCode;
            result.name = response.err.code;
            result.message = response.err.message;
            delete result.ok;
          }
        }
      }
      return result;
    } catch (error) {
      console.log("IBRD: RegistrationState -> signUp -> error", error);
      throw error;
    }
  }

  async checkIfUsernameExistForAdmin(values) {
    try {
      let result = await UserService.checkIfUsernameExist(values.username);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async decodedBeaconInfo(hex) {
    try {
      let decodedBeacon = await beaconDecode(hex.trim());
      console.log("log: RegistrationState -> decodedBeaconInfo: ", decodedBeacon);
      // this.setDecodedBeacon(decodedBeacon);
      return decodedBeacon;
    } catch (error) {
      console.error("log: RegistrationState -> decodedBeaconInfo -> error: ", error);
    }
  }


  getNotSupportMessage(decodedBeacon, pocInfo) {
    let content = "<b>The beacon country is not supported by this registry. Contact info if available:</b><br/><br/>";
    content = content.concat("<b>", decodedBeacon.countryName.toUpperCase(), "(", decodedBeacon.beaconCountryCode, ")</b>");
    if (pocInfo) {
      pocInfo.name ? content = content.concat("<h3>", pocInfo.name, "</h3>") : '';
      pocInfo.address ? content = content.concat("<p>", pocInfo.address, "</p>") : '';
      pocInfo.city ? content = content.concat("<p>City: ", pocInfo.city || "", "<br/>") : '';
      pocInfo.zipcode ? content = content.concat("Zip code: ", pocInfo.zipcode || "", "<br/>") : '';
      pocInfo.fax1 ? content = content.concat("Fax: ", pocInfo.fax1 || "", "<br/>") : '';
      pocInfo.telephone1 ? content = content.concat("Phone: ", pocInfo.telephone1 || "", "<br/>") : '';
      pocInfo.email ? content = content.concat("Email: ", pocInfo.email ? "<a href=mailto:" + pocInfo.email + ">" + pocInfo.email : "", "</a><br/>") : '';
      pocInfo.website_url ? content = content.concat("Website: ", pocInfo.website_url ? "<a target='_blank' href=" + pocInfo.website_url + ">" + pocInfo.website_url : "", "</a><br/>") : '';
      pocInfo.website_url ? content = content.concat(
        "Handbook: ",
        pocInfo.website_url ? "<a target='_blank' href=" + pocInfo.website_url + ">" + pocInfo.website_url : "",
        "</a><br/>"
      ) : '';
      pocInfo.aftn ? content = content.concat("AFTN: ", pocInfo.aftn || "", "<br/>") : '';
      content = content.concat("</p>");
      content = content.replace(/\r\n/g, "<br/>");
    }
    else{
      content = content.concat("<br/><br/><p>N/A</p>");
    }
    return content;
  }
}

const singleton = new RegistrationState(); //export class as singleton to be used through the application.
export default singleton;
