import { fetchDatafromAPI } from './serviceUtils'

const ACTIONS = {
  VALIDATE_EMAIL_CREATE: "validateEmailCreate",
  VALIDATE_EMAIL_EXECUTE: "validateEmailExecute",
  //VALIDATE_EMAIL_GETTOKEN: "getValidateEmailToken",
  DELEGATE_BEACON_CREATE: "delegateBeaconCreate",
  DELEGATE_BEACON_EXECUTE: "delegateBeaconExecute",
  //DELEGATE_BEACON_GETTOKEN: "getDelegateBeaconToken",
  REMINDER_CONFIRMATION_CREATE: "reminderConfirmationCreate",
  REMINDER_CONFIRMATION_EXECUTE: "reminderConfirmationExecute",
  SET_BEACON_TO_SOLD_CREATE: "setBeaconToSoldCreate",
	SET_BEACON_TO_SOLD_EXECUTE: "setBeaconToSoldExecute"
};

export async function createValidateEmailToken(userId) {
  try {
    let data = {
      action: ACTIONS.VALIDATE_EMAIL_CREATE,
      params: {
        userId: userId
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
  }
}

export async function executeValidateEmailToken(token) {
  try {
    let data = {
      action: ACTIONS.VALIDATE_EMAIL_EXECUTE,
      params: {
        token: token
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: executeValidateEmailToken -> error", error);
  }
}

/*export async function getValidateEmailToken(userId) {
  try {
    let data = {
      action: ACTIONS.VALIDATE_EMAIL_GETTOKEN,
      params: {
        userId: userId
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: getValidateEmailToken -> error", error);
  }
}*/

export async function createDelegateBeaconToken(beaconId) {
  try {
    let data = {
      action: ACTIONS.DELEGATE_BEACON_CREATE,
      params: {
        beaconId: beaconId
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: createDelegateBeaconToken -> error", error);
  }
}

export async function executeDelegateBeaconToken(token, userId) {
  try {
    let data = {
      action: ACTIONS.DELEGATE_BEACON_EXECUTE,
      params: {
        token: token,
        userId: userId
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: executeDelegateBeaconToken -> error", error);
  }
}

/*export async function getDelegateBeaconToken(beaconId) {
  try {
    let data = {
      action: ACTIONS.DELEGATE_BEACON_GETTOKEN,
      params: {
        beaconId: beaconId
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: getDelegateBeaconToken -> error", error);
  }
}*/

export async function createReminderConfirmationToken(beaconId) {
  try {
    let data = {
      action: ACTIONS.REMINDER_CONFIRMATION_CREATE,
      params: {
        beaconId: beaconId
      }
    };
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: createReminderConfirmationToken -> error", error);
  }
}

export async function executeReminderConfirmationToken(token) {
  try {
    let data = {
      action: ACTIONS.REMINDER_CONFIRMATION_EXECUTE,
      params: {
        token: token
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: executeReminderConfirmationToken -> error", error);
  }
}

export async function createSetBeaconToSoldToken(beaconId, userId) {
  try {
    let data = {
      action: ACTIONS.SET_BEACON_TO_SOLD_CREATE,
      params: {
        beaconId: beaconId,
        userId: userId
      }
    };
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: createSetBeaconToSoldToken -> error", error);
  }
}

export async function executeSetBeaconToSoldToken(token) {
  try {
    let data = {
      action: ACTIONS.SET_BEACON_TO_SOLD_EXECUTE,
      params: {
        token: token
      }
    };			
    return await fetchDatafromAPI(IBRD_CONFIG.authActionsEndpoint, data);
  } catch (error) {
    console.log("log: executeSetBeaconToSoldToken -> error", error);
  }
}
