import i18n from "i18next";
import React, { Component, useState } from "react";
import RegistrationState from "../../state/registrationState";
import * as Yup from "yup";
import { Form, Button, Message, Popup, Grid, Header, Modal, Icon } from "semantic-ui-react";
import withSemanticUIFormik from "../../utils/formHelper";
import RouteState from "../../state/routeState";
import AppState from "../../state/appState"
import QRInput from "../../components/QRInput"

import getFiveCharChecksum from "../../utils/beaconDecode/checksum.js"
import "../registerBeacon/registerBeacon.css";
var md5 = require('md5');

const InnerNewAccountForm = props => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, status, isValid, setStatus } = props;

  const [showModal, setShowModal] = useState(false);
  const [showModalWithOkCancel, setShowModalWithOkCancel] = useState(false);
  const [hexValidateMessage, setHexValidateMessage] = useState('');

  const validateBeaconSubmit = () => {
    RegistrationState.validateHexForRegistration(values.hex)
      .then(() => {
        handleSubmit();
      })
      .catch(error => {
        //console.log("validateHexForRegistration: fail", error);
        if(error.code) {
          setHexValidateMessage(error.message);

          if(error.code == "SOLD_STATUS") {
            setShowModalWithOkCancel(true);
          }
          else {            
            setShowModal(true);
          }
        }
        else if(error.message) {
          props.setStatus(error.message);
        }
        else {
          props.setStatus(error);
          console.log('validateBeaconSubmit -> error', error);
        }
      });
  }

  const onModalClose = () => {
    setShowModal(false);
    setHexValidateMessage('');
  };

  const onCloseModalWithOkCancel = () => {
    setShowModalWithOkCancel(false);
    setHexValidateMessage('');
  };

  const onSendEmailToBeaconOwner = () => {    
    RegistrationState.processAttemptToRegisterBeacon(values.hex)
      .then(() => {
        setShowModalWithOkCancel(false);
        setHexValidateMessage('');
      });
  };

  const checkboxChange = (e, data) => {
    values.skipChecksumValidation = data.checked
  }

  let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
  const t = AppState.t;
  const isOnline = AppState.isOnline;
  console.log('log ~ file: newAccountForm.js ~ line 74 ~ isOnline', isOnline);
  return (
    <Form id="newAccountForm"  error={hexHasError || status}>
      <div className="home-regHex-label">
        <span>{t('enter-your-beacon-hex-id')} </span>
        <Popup content="Hexadecimal ([0-9] and [A-F]) Beacon Identification Code" trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
      </div>
      <QRInput placeholder="Hex ID" name="hex" rows="2" className="home-regHex-textArea" value={values.hex} onChange={handleChange} onBlur={handleBlur} error={hexHasError}  />
      <div className="home-regHex-label">
        <span>{t('beacon-checksum-optional')} </span>
        {/* <Popup content="A digit representing the sum of the correct digits in a piece of stored or transmitted digital data, against which later comparisons can be made to detect errors in the data." trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} /> */}
      </div>
      <Form.Input placeholder="Beacon checksum" name="checksum" rows="2" className="home-regHex-textArea" onChange={handleChange} onBlur={handleBlur} error={hexHasError} />

      { (status !== undefined && status.includes('The checksum you entered does not match')) ?
        <><Form.Checkbox name='skipChecksumValidation' label={t('register-with-invalid-checksum')} onChange={checkboxChange} /></>
        : ''
      }

      <Grid centered columns={1}>
        <Grid.Row centered columns={2}>
          <Grid.Column>
             <Message className="newBeacon-errorMessage animated fadeIn" compact error content={errors.hex || status} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={1}>
          <Grid.Column>
             <Button type="submit" disabled={isOnline} content={t('create-account')} primary loading={isSubmitting} onClick={validateBeaconSubmit} fluid />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal centered={false} open={showModal} size={"tiny"} className="centered">
        <Modal.Content>
          <Header className="modal-header">WARNING</Header>
            <div className="modal-content hexValidateMessage" dangerouslySetInnerHTML={{__html: hexValidateMessage}} />
            <div className="submitContainer">
              <Button className="submitButton"  onClick={onModalClose}>OK</Button>
            </div>
        </Modal.Content>
      </Modal>

      <Modal centered={false} open={showModalWithOkCancel} size={"tiny"} className="centered">
        <Modal.Content>
          <Header className="modal-header">WARNING</Header>
            <div className="modal-content hexValidateMessage" dangerouslySetInnerHTML={{__html: hexValidateMessage}} />
            <div className="submitContainer">
              <Button className="submitButton" onClick={onSendEmailToBeaconOwner}>OK</Button>
              <Button className="submitButton" onClick={onCloseModalWithOkCancel}>Cancel</Button>
            </div>
        </Modal.Content>
      </Modal>
    </Form>
  );
};

//Higher order function wrapping the form to handle initial input using mapPropsToValues or submit using handleSubmit
const NewAccountForm = withSemanticUIFormik({
  mapPropsToValues: () => ({ hex: "", checksum:"" }),
  validationSchema: Yup.object().shape({
    hex: Yup.string()
      .required("Hex ID is required!")
      .matches(/^[0-9A-Fa-f]{1,64}$/, {
        //regex for valid HEX code.
        message: "Please enter only valid hexadecimal characters A-F, 0-9"
      })
      .test("len", "Please enter only valid hexadecimal characters A-F, 0-9", function(val) {
        if (val && (val.length === 15 || val.length === 23)) return true;
        else return false;
      })
  }),

  handleSubmit: (values, { setSubmitting, isValid, setStatus }) => {
    console.log("handleSubmit: values", values);

    if(values.checksum){
      let hexCheck = getFiveCharChecksum(values.hex)

      if(hexCheck !== values.checksum && !values.skipChecksumValidation){
        setStatus("The checksum you entered does not match the one encoded in the Beacon Hex ID.  Please check your information or contact your beacon manufacturer.  You may register this beacon at your own risk.")
        setSubmitting(false)
        return;
      }
    }

    setSubmitting(true);
    RegistrationState.submitHexForRegistration(values.hex)
      .then(beaconDecoded => {
        setSubmitting(false);
        delete values.skipChecksumValidation
        RouteState.setRoute("/registerUser");
        // RouteState.setRoute("/disclaimer")
      })
      .catch(error => {
        console.log("handleSubmit: error", error);
        setSubmitting(false);
        setStatus(error);
      });
  }
})(InnerNewAccountForm);

export default NewAccountForm;
