import i18n from "i18next";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Message, Radio, Container } from "semantic-ui-react";
import DashboardState from "../../../state/dashboardState";
import VehicleState from "../../../state/vehicleState";
import withSemanticUIFormik from "../../../utils/formHelper";
import { countryCode } from "../../../utils/beaconDecode/countryCode";
import RouteState from "../../../state/routeState";
import ActivityState from "../../../state/activityState";

import AppConfigState from "../../../state/appConfigState";
import ImageUploader from "../../../components/imageUpload";

const typeOptions = [
  { key: "aircraft", text: "Aircraft", value: "Aircraft" },
  { key: "boat", text: "Boat", value: "Boat" },
  { key: "helicopter", text: "Helicopter", value: "Helicopter" },
  { key: "landvehicle", text: "Land Vehicle", value: "Land Vehicle" },
  { key: "none", text: "None", value: "None" },
  { key: "other", text: "Other", value: "Other" },
];

const specificUseOptions = [
  { key: "fishing", text: "Fishing", value: "Fishing" },
  { key: "hiking", text: "Hiking", value: "Hiking" },
  { key: "hunting", text: "Hunting", value: "Hunting" },
  { key: "other", text: "Other", value: "Other" },
];

const countryCodeOptions = countryCode
  .sort((a, b) => a.country.localeCompare(b.country))
  .map((country) => {
    return {
      text: country.code + " - " + country.country,
      value: country.code,
      key: country.code,
    };
  });

const existingVehicleOptions = [{ key: "v1", text: "Vehicle 1", value: "Vehicle 1" }, { key: "v2", text: "Vehicle 2", value: "Vehicle 2" }];

class InnerForm extends Component {
  state = {
    vehicleOptions: [],
    vehicleInfos: [],
  };

  onClose = () => {
    if (VehicleState.hasRelatedActivity) {
      ActivityState.setPreviousStep();
    } else {
      this.props.onCancel();
    }
  };

  componentDidMount = () => {
    DashboardState.searchVehicles({}).then((response) => {
      if (!response) return;
      const vehicle = response.map((row, index) => {
        var _type = row.vehicleType == "" || row.vehicleType === undefined ? "n/a" : row.vehicleType;
        return {
          text: _type + " - " + row.vehicleModel,
          value: row._id,
          key: row._id,
        };
      });

      this.setState({
        vehicleOptions: vehicle,
        vehicleInfos: response,
      });
    });
  };

  onSelectVehicleChange = (e, data) => {
    // set selected when changed
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);
    }

    // set selecting vehicle info
    var selecting = this.state.vehicleInfos.find((row) => row._id === data.value);
    (this.props.values.vehicleType = selecting.vehicleType),
      (this.props.values.vehicleModel = selecting.vehicleModel),
      (this.props.values.registrationNumber = selecting.registrationNumber),
      (this.props.values.registrationCountry = selecting.registrationCountry),
      (this.props.values.vehicleColor = selecting.vehicleColor),
      (this.props.values.specificUsage = selecting.specificUsage);

    // change state data selecting
    VehicleState.setVehicleFields(selecting);
  };

  handleChangeSpecificUsage = (e, data) => {
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);
    }
    VehicleState.setCurrentSpecificUsage(data.value);
    if (data.value != "Other") {
      this.props.values.specificUsageOther = "";
    }
  };

  saveAndClose = () => {
    if (ActivityState.isEditActivity) {
      ActivityState.setIsSaving(true);
      VehicleState.updateVehicleFields(this.props.values);
      ActivityState.saveActivity().then(() => {
        ActivityState.setIsSaving(false);
        RouteState.history.goBack();
      });
    }
  };

  render() {
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid, onClose, allowSelectVehicle } = this.props;

    if (ActivityState.isAnEltAssociated()) {
      return <ELTVehicleDisplay handleSubmit={handleSubmit} onClose={onClose} />;
    }

    if (ActivityState.isAnEpirbAssociated()) {
      return <EPIRBVehicleDisplay handleSubmit={handleSubmit} onClose={onClose} />;
    }

    return (
      <Form className="activityDetail-form animated fadeIn faster">
        <Header as="h2" icon textAlign="center">
          <Header.Content>Add the vehicle details</Header.Content>
        </Header>

        <div style={allowSelectVehicle ? { display: "block" } : { display: "none" }}>
          <Form.Field>
            <Radio
              label={i18n.t('select-a-vehicle')}
              name="vehicleGroup"
              value="selectExistingVehicle"
              checked={values.vehicleGroup === "selectExistingVehicle"}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Select
              name="selectVehicle"
              options={this.state.vehicleOptions}
              placeholder="Select"
              onChange={this.onSelectVehicleChange}
              onBlur={handleBlur}
              value={values.selectVehicle}
              disabled={!ActivityState.isEditable() || !(values.vehicleGroup === "selectExistingVehicle")}
            />
            <Form.Field />
          </Form.Group>

          <Form.Field>
            <Radio
              label={i18n.t('add-a-new-vehicle')}
              name="vehicleGroup"
              value="addNewVehicle"
              checked={values.vehicleGroup === "addNewVehicle"}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>
        </div>

        <Form.Group widths="equal">
          <Form.Field>
            <Form.Select
              fluid
              name="vehicleType"
              label="Vehicle Type"
              options={typeOptions}
              placeholder="Select"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vehicleType}
              disabled={!ActivityState.isEditable()}
            />
            {values.vehicleType == "Other" ? (
              <Form.Input
                fluid
                placeholder=""
                name="vehicleTypeOther"
                value={values.vehicleTypeOther}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!ActivityState.isEditable()}
              />
            ) : (
              (values.vehicleTypeOther = "")
            )}
          </Form.Field>
          <Form.Input
            fluid
            name="vehicleModel"
            label="Vehicle Model"
            placeholder=""
            value={values.vehicleModel}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            name="registrationNumber"
            label="Registration Number"
            placeholder=""
            value={values.registrationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!ActivityState.isEditable()}
          />
          <Form.Select
            search
            fluid
            name="registrationCountry"
            label="Registration Country"
            placeholder="Select"
            value={values.registrationCountry}
            onChange={handleChange}
            onBlur={handleBlur}
            options={countryCodeOptions}
            disabled={!ActivityState.isEditable()}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              fluid
              name="vehicleColor"
              label="Colour"
              placeholder=""
              value={values.vehicleColor}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!ActivityState.isEditable()}
            />
          </Form.Field>

          <Form.Field>
            <label>
              Specific Usage{" "}
              <Popup
                content={i18n.t('select-the-checkbox-that-is-most-appropriate-to-the-way-the-beacon-will-be-specifically-used-if-other-is-selected-enter-a-description-in-the-box-below-other')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              fluid
              name="specificUsage"
              options={specificUseOptions}
              placeholder="Select"
              value={values.specificUsage}
              onChange={this.handleChangeSpecificUsage}
              disabled={!ActivityState.isEditable()}
            />

            {VehicleState.currentSpecificUsage == "Other" ? (
              <Form.Input
                fluid
                placeholder=""
                name="specificUsageOther"
                value={values.specificUsageOther}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!ActivityState.isEditable()}
              />
            ) : (
              ""
            )}
          </Form.Field>
        </Form.Group>

        {/* {VehicleState.currentSpecificUsage == 'Other' ? <Form.Group widths='equal' >
                    <Form.Field></Form.Field>
                    <Form.Field>
                        <Form.Input fluid placeholder='' name='specificUsageOther' value={values.specificUsageOther} onChange={handleChange} onBlur={handleBlur} />
                    </Form.Field>
                    </Form.Group>
                    : ''
                } */}

        <Header as="h2" icon textAlign="center">
          <Header.Content>{i18n.t('comments-and-image')}</Header.Content>
        </Header>

        <Form.TextArea label="Comments" placeholder="" name="comment" value={values.comment} onBlur={handleBlur} onChange={handleChange} disabled={!ActivityState.isEditable()} />

        <ImageUploader name="image1" value={values.image1} onChange={handleChange} disabled={!ActivityState.isEditable()} />
        <ImageUploader name="image2" value={values.image2} onChange={handleChange} disabled={!ActivityState.isEditable()} />

        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={this.onClose}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
          {/* {
						ActivityState.isEditActivity ?
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
        </div>
      </Form>
    );
  }
}

const VehicleDetailForm = withSemanticUIFormik({
  mapPropsToValues: ({ activity, allowPreview }) => {
    if (activity) {
      return {
        vehicleGroup: activity.vehicleGroup || "",
        selectVehicle: activity.selectVehicle || "",
        vehicleType: activity.vehicleType || "",
        vehicleModel: activity.vehicleModel || "",
        registrationNumber: activity.registrationNumber || "",
        registrationCountry: activity.registrationCountry || "",
        vehicleColor: activity.vehicleColor || "",
        specificUsage: activity.specificUsage || "",
        specificUsageOther: activity.specificUsageOther || "",
        vehicleTypeOther: activity.vehicleTypeOther || "",
        comment: activity.comment || "",
        image1: activity.image1 || "",
        image2: activity.image2 || "",
        allowPreview: allowPreview ? allowPreview : true,
      };
    } else {
      return {
        vehicleGroup: "",
        selectVehicle: "",
        vehicleType: "",
        vehicleModel: "",
        registrationNumber: "",
        registrationCountry: "",
        vehicleColor: "",
        specificUsage: "",
        specificUsageOther: "",
        vehicleTypeOther: "",
      };
    }
  },

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values===================@vehicle form", values, isValid);
    ActivityState.updateActivityFields(values);
    ActivityState.setNextStep();
  },
})(InnerForm);

export function ELTVehicleDisplay({ handleSubmit, onClose, hideButtons, activity, hideHeader }) {
  let associatedBeacon = ActivityState.getAssociatedBeacon(activity);
  console.log("log: ELTVehicleDisplay -> associatedBeacon", associatedBeacon);
  return (
    <Container className="activity-vehicleInfo">
      {!hideHeader && (
        <Header as="h3">
          {i18n.t('vehicle-that-will-be-used-during-the-activity')}
          {!hideButtons && <Header.Subheader>{i18n.t('the-vehicle-information-has-been-prepopulated-from-the-beacon-selected-for-this-activity')}</Header.Subheader>}
          {!hideButtons && (
            <Header.Subheader>
              {i18n.t('vehicle-information-can-only-be-updated-by-editing-the-beacon-record-after-saving-the-activity-you-may-edit-the-beacon-record-from-your-beacon-dashboard-by-clicking-on-the-beacon-hex-id')}
            </Header.Subheader>
          )}
        </Header>
      )}
      <div>
        <b>{i18n.t('hex-id')}: </b> {associatedBeacon.hexId || ""}
      </div>
      <div>
        <b>{i18n.t('beacon-type')}: </b> {associatedBeacon.beaconType || ""}
      </div>
      <div>
        <b>{i18n.t('vehicle-type')}: </b> {associatedBeacon.vehicleType || ""}
        {associatedBeacon.vehicleType == "Other" ? " (" + associatedBeacon.vehicleTypeOther + ")" : ""}
      </div>
      <div>
        <b>{i18n.t('vehicle-registration-number')}:</b> {associatedBeacon.vehicleRegistrationNumber || ""}
      </div>
      <div>
        <b>{i18n.t('home-icao-code')}:</b> {associatedBeacon.homeICAOCode || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-manufacturer')}:</b> {associatedBeacon.aircraftManufacturer || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-model')}:</b> {associatedBeacon.aircraftModel || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-colour')}:</b> {associatedBeacon.aircraftColor || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-agency')}:</b> {associatedBeacon.aircraftOperatingAgency || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-agency-phone')}:</b> {associatedBeacon.aircraftOperatingAgencyPhone || ""}
      </div>
      <div>
        <b>{i18n.t('radio-equipment')}:</b> {associatedBeacon.radioEquipment || ""}
      </div>
      <div>
        <b>{i18n.t('radio-equipment-other')}:</b> {associatedBeacon.radioEquipmentOther || ""}
      </div>
      <div>
        <b>{i18n.t('deployable-survival-craft')}s:</b> {associatedBeacon.deployableSurvivalCrafts || ""}
      </div>
      <div>
        <b>{i18n.t('fixed-survival-crafts')}:</b> {associatedBeacon.fixedSurvivalCrafts || ""}
      </div>
      <div>
        <b>{i18n.t('max-endurance')}:</b> {associatedBeacon.maxEndurance || ""}
      </div>
      <div>
        <b>{i18n.t('cruise-air-speed')}:</b> {associatedBeacon.cruiseAirSpeed || ""}
      </div>
      <div>
        <b>{i18n.t('length')}:</b> {associatedBeacon.length || ""}
      </div>
      <div>
        <b>{i18n.t('wingspan')}:</b> {associatedBeacon.wingSpan || ""}
      </div>
      <div>
        <b>{i18n.t('people-capacity')}:</b> {associatedBeacon.peopleCapacity || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-24-bit-address')}:</b> {associatedBeacon.aircraft24BitAddress || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-24-bit-address-decoded')}:</b> {associatedBeacon.aircraft24BitAddressDecoded || ""}
      </div>
      <div>
        <b>{i18n.t('aircraft-nationality')}:</b> {associatedBeacon.vehicleNationality || ""}
      </div>
      <div>
        <b>{i18n.t('additional-vehicle-usage-information')}:</b> {associatedBeacon.additionalComment || ""}
      </div>
      {/* Vehicle images */}
      {associatedBeacon.picture1 ? (
        <div style={{ marginTop: "10px" }}>
          <img style={{ width: "100%" }} src={associatedBeacon.picture1} />
        </div>
      ) : (
        ""
      )}
      {associatedBeacon.picture2 ? (
        <div style={{ marginTop: "10px" }}>
          <img style={{ width: "100%" }} src={associatedBeacon.picture2} />
        </div>
      ) : (
        ""
      )}
      {!hideButtons && (
        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={onClose}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
          {/* {
						ActivityState.isEditActivity ?
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
        </div>
      )}
    </Container>
  );
}

export function EPIRBVehicleDisplay({ handleSubmit, onClose, hideButtons, activity, hideHeader }) {
  console.log("log: EPIRBVehicleDisplay -> hideButtons", hideButtons);
  let associatedBeacon = ActivityState.getAssociatedBeacon(activity);
  return (
    <Container className="activity-vehicleInfo">
      {!hideHeader && (
        <Header as="h2">
          {i18n.t('vehicle-that-will-be-used-during-the-activity')}
          {!hideButtons && <Header.Subheader>{i18n.t('the-vehicle-information-has-been-prepopulated-from-the-beacon-selected-for-this-activity')}</Header.Subheader>}
          {!hideButtons && (
            <Header.Subheader>
              {i18n.t('vehicle-information-can-only-be-updated-by-editing-the-beacon-record-after-saving-the-activity-you-may-edit-the-beacon-record-from-your-beacon-dashboard-by-clicking-on-the-beacon-hex-id')}
            </Header.Subheader>
          )}
        </Header>
      )}
      <div>
        <b>{i18n.t('vehicle-type')}:</b> {associatedBeacon.vehicleType || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('vehicle-registration-number')}:</b> {associatedBeacon.vehicleRegistrationNumber || ""}
      </div>
      <div>
        <b>{i18n.t('vessel-name')}:</b> {associatedBeacon.vehicleName || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('vessel-model')}:</b> {associatedBeacon.vehicleModel || ""}
      </div>
      <div>
        <b>{i18n.t('home-port')}:</b> {associatedBeacon.homePort || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('vessel-colour')}:</b> {associatedBeacon.vehicleColor || ""}
      </div>
      <div>
        <b>{i18n.t('number-of-life-boats')}:</b> {associatedBeacon.nbLifeBoat || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('number-of-life-rafts')}:</b> {associatedBeacon.nbLifeRaft || ""}
      </div>
      <div>
        <b>{i18n.t('radio-equipment')}:</b> {associatedBeacon.radioEquipment || ""}
        {associatedBeacon.radioEquipment == "Other" ? " (" + associatedBeacon.radioEquipmentOther + ")" : ""}
      </div>
      <div>
        <b>{i18n.t('radio-call-sign')}:</b> {associatedBeacon.callSign || ""}
      </div>
      <div>
        <b>{i18n.t('radio-call-sign-decoded')}:</b> {associatedBeacon.callSignDecoded || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('ais-number')}:</b> {associatedBeacon.aisNumber || ""}
      </div>
      <div>
        <b>{i18n.t('inmarsat')}:</b> {associatedBeacon.Imarsat || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('vessel-cellular')}:</b> {associatedBeacon.vehicleCellularNum || ""}
      </div>
      <div>
        <b>{i18n.t('vessel-satellite-phone')}:</b> {associatedBeacon.vehicleSatellitePhone || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('mmsi-number')}:</b> {associatedBeacon.MMSI || ""}
      </div>
      <div>
        <b>{i18n.t('mmsi-number-decoded')}:</b> {associatedBeacon.MMSIDecoded || ""}{" "}
      </div>
      <div>
        <b>{i18n.t('length')}:</b> {associatedBeacon.length || ""}
      </div>
      <div>
        <b>{i18n.t('capacity-crew-and-passengers')}:</b> {associatedBeacon.peopleCapacity || ""}
      </div>
      <div>
        <b>{i18n.t('vehicle-nationality')}:</b> {countryCodeToText(associatedBeacon.vehicleNationality) || ""}
      </div>
      <div>
        <b>{i18n.t('equipped-with-simplified-voyage-data-recorder')}:</b> {associatedBeacon.equippedWithDataRecord || ""}
      </div>
      <div>
        <b>{i18n.t('additional-vehicle-usage-information')}:</b> {associatedBeacon.additionalComment || ""}
      </div>
      {/* Vehicle images */}
      {associatedBeacon.picture1 ? (
        <div style={{ marginTop: "10px" }}>
          <img style={{ width: "100%" }} src={associatedBeacon.picture1} />
        </div>
      ) : (
        ""
      )}
      {associatedBeacon.picture2 ? (
        <div style={{ marginTop: "10px" }}>
          <img style={{ width: "100%" }} src={associatedBeacon.picture2} />
        </div>
      ) : (
        ""
      )}
      {!hideButtons && (
        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={onClose}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
          {/* {
						ActivityState.isEditActivity ?
						<Button className="activityDetail-submitButton" primary onClick={this.saveAndClose} loading={ActivityState.isSaving} >
						Save & Close
						</Button>
						: ''
					} */}
        </div>
      )}
    </Container>
  );
}

export default VehicleDetailForm;

function countryCodeToText(id) {
  let countryNamesWithCode = AppConfigState.countryNamesWithCode;
  let item = countryNamesWithCode.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}
