import React, { Component } from "react";
import i18n from "i18next";
import { observer } from "mobx-react";
import { Tab, Menu, Container, Divider, Label, Icon, Button, Modal, Header, Responsive, Segment } from "semantic-ui-react";
import Beacons from "./beacons";
import Activities from "./activities";
import Users from "./users";
import Vehicle from "./vehicle";
import DashboardState from "../../state/dashboardState";
import RouteState from "../../state/routeState";
import UserState from "../../state/userState";
import AppState from "../../state/appState";
import RegistrationState from "../../state/registrationState";
import { Redirect } from "react-router-dom";
import ActivityState from "../../state/activityState";
import AssociatedUserState from "../../state/associatedUserState";
import VehicleState from "../../state/vehicleState";
import "./dashboard.css";
import DashboardLayout from '../../components/dashboardLayout';

@observer
export default class Dashboard extends Component {
  state = {
    activeTab: 0,
    showModal: false,
    showGroupUserNotice: false,
    groupUserNoticeMessage: ""
  };

  componentDidMount = async () => {
    const {
      match: { params }
    } = this.props;
    this.setState({
      activeTab: convertParamTotabIndex(params.tab)
    });
    console.log("log: Dashboard componentDidMount -> params", params);

    if(DashboardState.filterUsername){
      let ownerId = 'org.couchdb.user:' + DashboardState.filterUsername;
      DashboardState.searchActivities({owner: ownerId});
    }
    else{
      if(DashboardState.displayGroupUserNotice) {
        let content = await DashboardState.getGroupUserNoticeContent();
        if(content) {
          this.setState({
            showGroupUserNotice: true,
            groupUserNoticeMessage: content
          });
        }
      }

      // DashboardState.setIsLoading(true);
      // await DashboardState.searchBeacons({});
      // DashboardState.setIsLoading(false);

      await DashboardState.searchBeacons({});
      //DashboardState.searchActivities({});
      //DashboardState.searchAssociatedUsers({});
      //DashboardState.searchVehicles({});
    }
  }

  onTabChange = (e, { activeIndex }) => {
    console.log("log: Dashboard -> onTabChange -> activeIndex", activeIndex);
    RouteState.setRoute("/dashboard/" + convertIndexToRoute(activeIndex));
  };

  onAddBeacon = e => {
    e.stopPropagation(); //This prevent the tab itself to register the click.
    RegistrationState.resetSteps();
    RouteState.setRoute("/newBeacon");
  };

  onAddActivity2 = e => {
    console.log("log: onAddActivity -> e", e);

    this.setState({
      showModal: true
    });
    e.stopPropagation(); //This prevent the tab itself to register the click.
  };

  onModalCancel = () => {
    this.setState({
      showModal: false
    });
  };

  onAddActivity = () => {
    this.setState({
      showModal: false
    });
    ActivityState.resetSteps();
    ActivityState.resetData();
    AssociatedUserState.resetSteps();
    AssociatedUserState.resetData();
    VehicleState.resetSteps();
    VehicleState.resetData();
    ActivityState.setIsEditActivity(false);
    AssociatedUserState.setHasRelatedActivity(true);
    VehicleState.setHasRelatedActivity(true);
    RouteState.setRoute("/registerActivity");
  };

  onAddAssociatedUser = e => {
    e.stopPropagation(); //This prevent the tab itself to register the click.
    AssociatedUserState.resetSteps();
    AssociatedUserState.resetData();
    RouteState.setRoute("/registerAssociatedUser");
  };

  onAddVehicle = e => {
    e.stopPropagation(); //This prevent the tab itself to register the click.
    VehicleState.resetSteps();
    VehicleState.resetData();
    RouteState.setRoute("/registerVehicle");
  };

  onGroupNoticeModalClose = () => {
    this.setState({
      showGroupUserNotice: false
    });
  }

  render() {
    let appLoaded = AppState.appLoaded;
    let currentUser = UserState.currentUser;
    if (appLoaded && !currentUser) {
      return <Redirect to="/" />;
    }

    const { activeTab, showModal, showGroupUserNotice, groupUserNoticeMessage } = this.state;
    let totalBeacons = DashboardState.totalBeacons;
    // if(totalBeacons > 1000){
    //   totalBeacons = "1000+"
    // }

    let totalActivities = DashboardState.activities.length;
    // if(totalActivities > 1000){
    //   totalActivities = "1000+"
    // }
    let totalUsers = DashboardState.associatedUsers.length;
    let totalVehicles = DashboardState.vehicles.length;

    const panes = getPanes({
      totalBeacons: totalBeacons,
      totalActivities: totalActivities,
      totalUsers: totalUsers,
      totalVehicles: totalVehicles,
      onTabChange: this.onTabChange,
      onAddBeacon: this.onAddBeacon,
      onAddActivity: this.onAddActivity,
      onAddAssociatedUser: this.onAddAssociatedUser,
      onAddVehicle: this.onAddVehicle
    });
    return (
      <div className="dashboard-page">
        <DashboardLayout>
          {/* <Container className="dashboard-container"> */}
          <Container className="dashboard-container">
            <Tab className="dashboard-tab" panes={panes}  activeIndex={activeTab}  onTabChange={this.onTabChange} />
          </Container>
        </DashboardLayout>

        {showModal && <Modal centered={false} open={showModal} size={"tiny"} className="centered">
          <Modal.Content>
            <Header className="modal-header">{i18n.t('warning')}</Header>
           {i18n.t('this-information-will-be-consulted')}
            <div className="activityDetail-submitContainer">
              <Button className="registerBeacon-submitButton" onClick={this.onModalCancel}>
                {i18n.t('cancel')}
              </Button>
              <Button className="registerBeacon-submitButton" onClick={this.onModalAccept} primary>
                {i18n.t('continue')}
              </Button>
            </div>
          </Modal.Content>
        </Modal>}

        {showGroupUserNotice && <Modal centered={false} open={showGroupUserNotice} size={"tiny"} className="centered">
          <Modal.Content>
            <Header className="modal-header">ATTENTION</Header>
            <div dangerouslySetInnerHTML={{__html: groupUserNoticeMessage}} />
            <div className="activityDetail-submitContainer">
              <Button className="registerBeacon-submitButton" onClick={this.onGroupNoticeModalClose} primary>
                {i18n.t('continue')}
              </Button>
            </div>
          </Modal.Content>
        </Modal>}
      </div>
    );
  }
}

function getPanes({ totalBeacons, totalActivities, totalUsers, totalVehicles, onAddBeacon, onAddActivity, onAddAssociatedUser, onAddVehicle }) {
  console.log('log: getPanes -> totalBeacons', totalBeacons);
  if(!UserState.currentUserProfile) return;

  let roleId = UserState.currentUserProfile.roleId;
  console.log('log: getPanes -> roleId', roleId);
  let roleIdLimit = ["1","7"];
  let limitDisplayByRole = new RegExp(roleIdLimit.join('|')).test(roleId);
  let isAdmin = DashboardState.isAdmin();
  console.log('log: getPanes -> isAdmin', isAdmin);
  if(limitDisplayByRole) {
    return [
      {
        menuItem: (
          // <Menu.Item key="beacons" className="dashboard-menuItem">
          //   <Icon name="rss" className="dashboard-menuItem-icon" />
          //   Beacons
          //   <Label color="red" floating  className="dashboard-tab-label">{totalBeacons}</Label>
          //   <Button icon="plus circle" content="ADD" floated="right" className="dashboard-tab-addButton" onClick={onAddBeacon}></Button>
          // </Menu.Item>

          <Menu.Item key="beacons" className="dashboard-menuItem">
            <div className="for-desktop">
              <Icon name="rss" className="dashboard-menuItem-icon" />
              {i18n.t('beacons')}
              <Label color="red" floating  className="dashboard-tab-label">{totalBeacons}</Label>
              
            </div>
            <div className="for-mobile">
              {i18n.t('beacons')}<br/>
              <Icon name="rss" className="dashboard-menuItem-icon" />({totalBeacons})<br/>
              
            </div>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Beacons />
          </Tab.Pane>
        )
      }
    ];
  }

  let isSarUser = UserState.notAllowToEditByRoleId.includes(roleId) ? true : false;
  if(isSarUser && !DashboardState.filterUsername) {
    return [
      {
        menuItem: (
          // <Menu.Item key="beacons" className="dashboard-menuItem">
          //   <Icon name="rss" className="dashboard-menuItem-icon" />
          //   Search
          //   <Label color="red" floating  className="dashboard-tab-label">{totalBeacons}</Label>
          // </Menu.Item>

          <Menu.Item key="beacons" className="dashboard-menuItem">
            <div className="for-desktop">
              <Icon name="rss" className="dashboard-menuItem-icon" />
              {i18n.t('search')}
              <Label color="red" floating  className="dashboard-tab-label">{totalBeacons}</Label>
            </div>
            <div className="for-mobile">
              {i18n.t('search')}<br/>
              <Icon name="rss" className="dashboard-menuItem-icon" />({totalBeacons})
            </div>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Beacons />
          </Tab.Pane>
        )
      }
    ];
  }
  else if(isSarUser && DashboardState.filterUsername){
    return [
      {
        menuItem: (
          // <Menu.Item key="beacons" className="dashboard-menuItem">
          //   <Icon name="rss" className="dashboard-menuItem-icon" />
          //   Search
          //   <Label color="red" floating  className="dashboard-tab-label">{totalBeacons}</Label>
          // </Menu.Item>

          <Menu.Item key="beacons" className="dashboard-menuItem">
            <div className="for-desktop">
              <Icon name="rss" className="dashboard-menuItem-icon" />
              {i18n.t('search')}
              <Label color="red" floating  className="dashboard-tab-label">{totalBeacons}</Label>
            </div>
            <div className="for-mobile">
              {i18n.t('search')}<br/>
              <Icon name="rss" className="dashboard-menuItem-icon" />({totalBeacons})
            </div>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Beacons />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          // <Menu.Item key="activities" className="dashboard-menuItem">
          //   <Icon name="sun"  className="dashboard-menuItem-icon" />
          //   Activities
          //   <Label color="red" floating className="dashboard-tab-label">{totalActivities}</Label>
          // </Menu.Item>

          <Menu.Item key="beacons" className="dashboard-menuItem">
            <div className="for-desktop">
              <Icon name="sun"  className="dashboard-menuItem-icon" />
              {i18n.t('activities')}
              <Label color="red" floating className="dashboard-tab-label">{totalActivities}</Label>
            </div>
            <div className="for-mobile">
               {i18n.t('activities')}<br/>
              <Icon name="sun"  className="dashboard-menuItem-icon" />({totalBeacons})
            </div>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Activities onAddActivity={onAddActivity} onAddAssociatedUser={onAddAssociatedUser} onAddVehicle={onAddVehicle} />
          </Tab.Pane>
        )
      }
    ];
  }


  return [
    {
      menuItem: (
        <Menu.Item key="beacons" className="dashboard-menuItem">
          <div className="for-desktop">
           
            {i18n.t('beacons')}
          </div>
          <div className="for-mobile">
            {i18n.t('beacons')}<br/>
           
          </div>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Beacons />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item key="activities" className="dashboard-menuItem">
          <div className="for-desktop">
           
            {i18n.t('activities')}
           
          </div>
          <div className="for-mobile">
            {i18n.t('activities')}<br/>
           
          </div>

        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Activities onAddActivity={onAddActivity} onAddAssociatedUser={onAddAssociatedUser} onAddVehicle={onAddVehicle}/>
        </Tab.Pane>
      )
    },
    
  ];
}

function convertParamTotabIndex(param) {
  switch (param) {
    case "beacons":
      return 0;
    case "activities":
      return 1;
    case "users":
      return 2;
    case "vehicle":
      return 3;
    default:
      return 0;
  }
}

function convertIndexToRoute(index) {
  switch (index) {
    case 0:
      return "beacons";
    case 1:
      return "activities";
    case 2:
      return "users";
    case 3:
      return "vehicle";
    default:
      return 0;
  }
}
