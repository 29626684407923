import * as CONSTANTS from "./beaconConstants";
import getCountryByCode from "./countryCode";
import * as FileSaver from "file-saver";
import AppConfigState from "../../state/appConfigState";
import * as Utils from "../utils";
import UserState from "../../state/userState";
const { Parser } = require("json2csv");

export function getBeaconProtocol(beaconBinary) {
  // let protocolBit = beaconBinary.substring(CONSTANTS.PROTOCOL_BITS_START, CONSTANTS.PROTOCOL_BITS_END);
  let protocolBit = beaconBinary.substring(CONSTANTS.PROTOCOL_BITS.start, CONSTANTS.PROTOCOL_BITS.end);
}

export function getCountryCodeAndName(beaconBinary) {
  let countryCodeBinary = beaconBinary.substring(CONST.COUNTRY_CODE_BITS.start, CONST.COUNTRY_CODE_BITS.end);
  let countryCode = parseInt(countryCodeBinary, 2);
  let countryName = getCountryByCode(countryCode);
  return {
    countryCode,
    countryName,
  };
}

export function beaconExport(currentExportType, beaconsToExport) {
  // for export all beacons
  let allBeacons = beaconsToExport.map((row) => {
    if (row.specialStatus) {
      row.specialStatus = row.specialStatus.toUpperCase();
    }

    if (row.specialStatus === "DESTROYED") {
      row.specialStatus = "OUTOFSERVICE";
    }

    let newFormat = {
      // --- beacon
      hexId: row.hexId || "",
      beaconType: row.beaconType || "",
      countryName: row.countryName || "",
      beaconCountryCode: row.beaconCountryCode || "",
      beaconManufacturer: row.beaconManufacturer || "",
      beaconManufacturerOther: row.beaconManufacturerOther || "",
      serialNumber: row.serialNumber || "",
      beaconModel: row.beaconModel || "",
      typeApprovalCertificate: row.typeApprovalCertificate || "",
      beaconHomingDevice: row.beaconHomingDevice || "",
      beaconHomingDeviceOther: row.beaconHomingDeviceOther || "",
      beaconActivationMethod: row.beaconActivationMethod || "",
      additionalBeaconData: row.additionalBeaconData || "",
      initialDate: row.initialDate || "",
      lastEditDate: row.lastEditDate || "",
      specialStatus: row.specialStatus || "ACTIVE",
      ...(["1", "3"].includes(UserState.currentUserProfile.roleId) && { specialStatusInfo: row.specialStatusInfo || "" }),
      specialStatusDate: row.specialStatusDate || "",
      lastConfirmationDate: row.lastConfirmationDate || "",
      type: row.type, //added this so that we have it in case user uses this as an import template,

      // --- vehicle
      vehicleType: row.vehicleType || "",
      vehicleTypeOther: row.vehicleTypeOther || "",
      vehicleRegistrationNumber: row.vehicleRegistrationNumber || "",
      homeICAOCode: row.homeICAOCode || "",
      aircraftManufacturer: row.aircraftManufacturer || "",
      aircraftModel: row.aircraftModel || "",
      aircraftColor: row.aircraftColor || "",
      aircraftOperatingAgency: row.aircraftOperatingAgency || "",
      aircraftOperatingAgencyPhone: row.aircraftOperatingAgencyPhone || "",
      radioEquipment: row.radioEquipment || "",
      radioEquipmentOther: row.radioEquipmentOther || "",
      deployableSurvivalCrafts: row.deployableSurvivalCrafts || "",
      fixedSurvivalCrafts: row.fixedSurvivalCrafts || "",
      maxEndurance: row.maxEndurance || "",
      cruiseAirSpeed: row.cruiseAirSpeed || "",
      length: row.length || "",
      wingSpan: row.wingSpan || "",
      peopleCapacity: row.peopleCapacity || "",
      aircraft24BitAddress: row.aircraft24BitAddress || "",
      aircraft24BitAddressDecoded: row.aircraft24BitAddressDecoded || "",
      vehicleNationality: row.vehicleNationality || "",
      additionalComment: row.additionalComment || "",
      vehicleName: row.vehicleName || "",
      vehicleModel: row.vehicleModel || "",
      homePort: row.homePort || "",
      vehicleColor: row.vehicleColor || "",
      nbLifeBoat: row.nbLifeBoat || "",
      nbLifeRaft: row.nbLifeRaft || "",
      callSign: row.callSign || "",
      callSignDecoded: row.callSignDecoded || "",
      aisNumber: row.aisNumber || "",
      Imarsat: row.Imarsat || "",
      vehicleCellularNum: row.vehicleCellularNum || "",
      vehicleSatellitePhone: row.vehicleSatellitePhone || "",
      MMSI: row.MMSI || "",
      MMSIDecoded: row.MMSIDecoded || "",
      equippedWithDataRecord: row.equippedWithDataRecord || "",
      usageMoreInfo: row.usageMoreInfo || "",
      usageMoreInfoOther: row.usageMoreInfoOther || "",
    };

    let ownerInfo = {
      // --- owner
      ownerName: row.ownerName || "",
      emailAddress: row.emailAddress || "",
      address: row.address || "",
      mailCode: row.mailCode || "",
      city: row.city || "",
      province: row.province || "",
      mailCountry: row.mailCountry || "",
      medicalInfo: row.medicalInfo || "",
      operatorLanguage: row.operatorLanguage || "",
      phone1Num: row.phone1Num || "",
      phone1Type: phoneTypeToText(row.phone1Type) || "",
      phone1TypeOther: row.phone1TypeOther || "",
      phone2Num: row.phone2Num || "",
      phone2Type: phoneTypeToText(row.phone2Type) || "",
      phone2TypeOther: row.phone2TypeOther || "",
      phone3Num: row.phone3Num || "",
      phone3Type: phoneTypeToText(row.phone3Type) || "",
      phone3TypeOther: row.phone3TypeOther || "",
      phone4Num: row.phone4Num || "",
      phone4Type: phoneTypeToText(row.phone4Type) || "",
      phone4TypeOther: row.phone4TypeOther || "",
      primaryContactName: row.primaryContactName || "",
      primaryContactAddressLine1: row.primaryContactAddressLine1 || "",
      primaryContactAddressLine2: row.primaryContactAddressLine2 || "",
      primaryPhone1Num: row.primaryPhone1Num || "",
      primaryPhone1Type: phoneTypeToText(row.primaryPhone1Type) || "",
      primaryPhone1TypeOther: row.primaryPhone1TypeOther || "",
      primaryPhone2Num: row.primaryPhone2Num || "",
      primaryPhone2Type: phoneTypeToText(row.primaryPhone2Type) || "",
      primaryPhone2TypeOther: row.primaryPhone2TypeOther || "",
      primaryPhone3Num: row.primaryPhone3Num || "",
      primaryPhone3Type: phoneTypeToText(row.primaryPhone3Type) || "",
      primaryPhone3TypeOther: row.primaryPhone3TypeOther || "",
      primaryPhone4Num: row.primaryPhone4Num || "",
      primaryPhone4Type: phoneTypeToText(row.primaryPhone4Type) || "",
      primaryPhone4TypeOther: row.primaryPhone4TypeOther || "",
      alternateContactName: row.alternateContactName || "",
      alternateContactAddressLine1: row.alternateContactAddressLine1 || "",
      alternateContactAddressLine2: row.alternateContactAddressLine2 || "",
      alternatePhone1Num: row.alternatePhone1Num || "",
      alternatePhone1Type: phoneTypeToText(row.alternatePhone1Type) || "",
      alternatePhone1TypeOther: row.alternatePhone1TypeOther || "",
      alternatePhone2Num: row.alternatePhone2Num || "",
      alternatePhone2Type: phoneTypeToText(row.alternatePhone2Type) || "",
      alternatePhone2TypeOther: row.alternatePhone2TypeOther || "",
      alternatePhone3Num: row.alternatePhone3Num || "",
      alternatePhone3Type: phoneTypeToText(row.alternatePhone3Type) || "",
      alternatePhone3TypeOther: row.alternatePhone3TypeOther || "",
      alternatePhone4Num: row.alternatePhone4Num || "",
      alternatePhone4Type: phoneTypeToText(row.alternatePhone4Type) || "",
      alternatePhone4TypeOther: row.alternatePhone4TypeOther || "",
    };

    let notAllFieldsRole = ["2"];
    if (!notAllFieldsRole.includes(UserState.currentUserProfile.roleId)) {
      newFormat = { ...newFormat, ...ownerInfo };
    }

    if (UserState.currentUserProfile.roleId === "1" && AppConfigState.config.ndpDelegationActivation) {
      newFormat.isDelegated = row.isDelegated || false;
    }

    return newFormat;
  });

  let file;
  switch (currentExportType) {
    case "xml":
      let xml = jsonToXML(allBeacons);
      file = new Blob([xml], { type: "application/xml;charset=utf-8" });
      FileSaver.saveAs(file, "beacons.xml");
      break;

    case "json":
      file = new File([JSON.stringify(allBeacons)], { type: "application/json;charset=utf-8" });
      FileSaver.saveAs(file, "beacons.json");
      break;

    case "csv":
      var csv = convertToCSV(allBeacons, ",");
      let blob = new Blob([csv], { type: "text/csv;charset=ANSI" });
      FileSaver.saveAs(blob, "beacons.csv");
      break;

    case "tsv":
      var str = convertToCSV(allBeacons, "  ");
      let strBlob = new Blob([str], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(strBlob, "beacons.txt");
      break;
  }
}

export function convertToCSV(objArray, delemiter = ",") {
  const json2csvParser = new Parser({
    quote: '"',
    escapedQuote: "\\'",
    excelStrings: true,
  });
  const csv = json2csvParser.parse(objArray);
 
  return csv;
  //================ Add header
  var headers = {};
  for (const [key, value] of Object.entries(objArray[0])) {
    var item = { [key]: key };
    headers = { ...headers, ...item };
  }
  objArray.unshift(headers);
  //================

  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += delemiter;
      if (delemiter == ",") {
        line += array[i][index] === undefined ? "" : '"=""' + array[i][index] + '"""';
      } else {
        line += array[i][index] === undefined ? '""' : '"' + array[i][index] + '"';
      }
    }
    str += line + "\r\n";
  }
  return str;
}

function jsonToXML(json) {
  let text = "";
  text += '<?xml version="1.0" encoding="UTF-8"?>';
  text += "<beacons>";
  json.map((row) => {
    text += "<beacon>";
    for (const [key, value] of Object.entries(row)) {
      text += "<" + key + ">" + escapeXML(value) + "</" + key + ">";
    }
    text += "</beacon>\r\n";
  });
  text += "</beacons>";
  return text;
}

function escapeXML(text) {
  return String(text).replace(/(['"<>&'])(\w+;)?/g, (match, char, escaped) => {
    if (escaped) return match;

    switch (char) {
      case '"':
        return "&quot;";
      case "'":
        return "&apos;";
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case "&":
        return "&amp;";
    }
  });
}

export function manufacturerToText(id, other = "") {
  let manufacturerOption = AppConfigState.manufacturerOption;
  let item = manufacturerOption.find((row) => row.value == id);
  if (!item) return "";
  if (item.value == "Other") {
    return item.text + " (" + other + ")";
  }
  return item.text;
}

export function findLabelByValue(value, dataSet) {
  let option = dataSet.find((entry) => entry.value === value);
  return option.text;
}

export function specialStatusToText(id) {
  let specialStatus = AppConfigState.beaconStatusOption;
  let item = specialStatus.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}

export function phoneTypeToText(id, otherType = "") {
  if (id === "cellular") return "CELL";
  return id;
}

export function activationMedthodToText(id) {
  const activationMethodOptions = AppConfigState.beaconActivationMethod;
  let item = activationMethodOptions.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}

export function countryCodeToText(id) {
  let countryNamesWithCode = AppConfigState.countryNamesWithCode;
  let item = countryNamesWithCode.find((row) => row.value == id);
  if (!item) return "";
  return item.text;
}
