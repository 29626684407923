import { action, observable, computed, toJS } from "mobx";
import * as BeaconService from "../services/beaconService";
import AppState from "./appState";
import UserState from "./userState";
import MidInfoState from "./midInfoState";
import * as TacService from "../services/tacService";

class AppCOnfigState {
  @observable config;
  dbConfig = "";

  async getClientConfig() {
    this.dbConfig = IBRD_CONFIG;
  }

  async getAppCOnfig() {
    try {
      let appConfig = await BeaconService.getConfig();
      console.log("log: AppCOnfigState -> getConfig -> result", appConfig);
      this.setConfig(appConfig);
      return appConfig;
    } catch (error) {
      console.log("ibrd: AppCOnfigState -> getConfig -> error", error);
    }
  }

  async updateAppConfig(config) {
    try {
      let appConfig = await BeaconService.updateConfig(config);
      console.log("log: AppCOnfigState -> updateAppConfig -> result", appConfig);
      this.setConfig(config);
      //return appConfig;
    } catch (error) {
      console.log("ibrd: AppCOnfigState -> updateAppConfig -> error", error);
    }
  }

  @action setConfig(config) {
    console.log("log: AppCOnfigState -> @actionsetConfig -> config", config);

    this.config = config;
  }

  @computed get radioEquipmentOption() {
    if (!this.config) return [];
    const options = this.config.radioEquipment
      .map((entry) => {
        return { key: entry.RadioEquipment, value: entry.RadioEquipment, text: entry.RadioEquipment };
      })
      .sort((a, b) => a.text.localeCompare(b.text));
    let other = options.find((row) => row.value === "Other");
    let newOptions = options.filter((row) => row.value != "Other");
    newOptions.push(other);
    return newOptions;
  }

  @computed get homingDeviceOption() {
    if (!this.config) return [];
    const options = this.config.beaconHomingDevices
      .map((entry) => {
        return { key: entry.BeaconHomingDeviceDescription, value: entry.BeaconHomingDeviceDescription, text: entry.BeaconHomingDeviceDescription };
      })
      .sort((a, b) => a.text.localeCompare(b.text));
    let other = options.find((row) => row.value === "Other");
    let newOptions = options.filter((row) => row.value != "Other");
    newOptions.push(other);
    return newOptions;
  }

  @computed get beaconStatusOption() {
    if (!this.config) return [];
    const options = this.config.specialStatus.map((entry) => {
      return { key: entry.SpecialStatus, value: entry.SpecialStatus, text: entry.SpecialStatusDescription };
    });
    return options;
  }

  @computed get manufacturerOption() {
    if (!this.config) return [];
    const options = this.config.beaconManufacturer.map((entry) => {
      return { key: entry.BeaconManufacturerKey, value: entry.BeaconManufacturerKey, text: entry.BeaconManufacturerName };
    });
    options.unshift({ key: "", value: "", text: "Select" });
    options.push({ key: "Other", value: "Other", text: "Other" });
    return options;
  }

  @computed get beaconActivationMethod() {
    if (!this.config) return [];
    const options = this.config.beaconActivationMethod.map((entry) => {
      let text = entry.BeaconActivationMethodDescription.toLowerCase();
      let text2 = text.charAt(0).toUpperCase() + text.slice(1);
      return { key: entry.BeaconActivationMethod, value: entry.BeaconActivationMethod, text: text2 };
    });
    return options;
  }

  @computed get phoneTypes() {
    if (!this.config || !this.config) return [];
    const options = this.config.phoneType.map((entry) => {
      // Changed display from "Cellular" to "Mobile"
      if (entry.PhoneType === "CELL") {
        entry.PhoneTypeDescription = "Mobile";
      }
      return { key: entry.PhoneType, value: entry.PhoneType, text: entry.PhoneTypeDescription };
    });
    options.unshift({ key: "0", value: "", text: "" });
    let other = options.find((row) => row.value === "OTHR");
    let newOptions = options.filter((row) => row.value != "OTHR");
    newOptions.push(other);
    return newOptions;
  }

  @computed get languages() {
    if (!this.config || !this.config.languages) return [];

    const options = this.config.languages.map((entry) => {
      return { key: entry.englishName, value: entry.englishName, text: entry.nativeName };
    });
    return options;
  }

  @computed get vehiculeTypeELT() {
    if (!this.config) return [];
    const options = this.config.ELTVehiculeType.map((entry) => {
      return { key: entry.ELTVehicleType, value: entry.ELTVehicleType, text: entry.ELTVehicleType };
    });
    const index = options.findIndex((obj) => obj.key == "Other");
    options.push(options.splice(index, 1)[0]);
    return options;
  }

  @computed get vehiculeTypeEPIRB() {
    if (!this.config) return [];
    const options = this.config.EPIRBVehiculeType.map((entry) => {
      return { key: entry.EPIRBVehicleType, value: entry.EPIRBVehicleType, text: entry.EPIRBVehicleType };
    });
    const index = options.findIndex((obj) => obj.key == "Other");
    options.push(options.splice(index, 1)[0]);
    return options;
  }

  @computed get vehiculeTypePLB() {
    if (!this.config) return [];
    const options = this.config.PLBVehiculeType.map((entry) => {
      return { key: entry.PLBVehicleType, value: entry.PLBVehicleType, text: entry.PLBVehicleType };
    });
    const index = options.findIndex((obj) => obj.key == "Other");
    options.push(options.splice(index, 1)[0]);
    return options;
  }

  @computed get emailTemplateKeys() {
    return this.config.emailTemplateKey;
  }

  @computed get countryNames() {
    if (!this.config || !this.config.countries) return [];
    const options = this.config.countries.map((entry) => {
      return { key: entry.MailCountry, value: entry.MailCountry, text: entry.MailCountry };
    });
    return options;
  }
  // @computed get countryNames(){

  //   if(!this.config || !this.config.MIDInfo) return [];
  //    const options = this.config.MIDInfo.map(entry => {
  //     return { key: entry.Mid, value: entry.CountryName, text: entry.CountryName };
  //   }).filter(entry => entry.value !== "UNALLOCATED");
  //   return options;
  // }

  getCountryNameByMid(mid){
    
    for(let entry of this.config.MIDInfo){
      if(entry.Mid === mid){
        //console.log('log ~ file: appConfigState.js ~ line 174 ~ AppCOnfigState ~ getCountryNameByMid ~ mid', mid, entry);
        return entry.CountryName
      }
    }
    return ""
  }

  @computed get countryNamesWithCode() {
    if (!this.config.MIDInfo) return [];
    const options = this.config.MIDInfo.map((entry) => {
      let text = entry.CountryName + " (" + entry.Mid + ")";
      return { key: entry.Mid, value: entry.Mid, text: text };
    });
    return options;
  }

  @computed get countryCode() {
    if (!this.config.MIDInfo) return [];
    const options = this.config.MIDInfo.map((entry) => {
      let text = entry.CountryName + " (" + entry.Mid + ")";
      return { key: entry.Mid, value: entry.Mid, text: text };
    });
    return options;
  }

  @computed get midNpdOnly() {
    if (!this.config.MIDInfo) return [];
    let MID_NDP = this.config.MIDInfo.filter((row) => row.NDPSupportedTypes.includes("0") || row.NDPSupportedTypes.includes("1") || row.NDPSupportedTypes.includes("2"));
    const options = MID_NDP.map((entry) => {
      let text = entry.CountryName + " (" + entry.Mid + ")";
      return { key: entry.Mid, value: entry.Mid, text: text };
    });
    options.sort((a, b) => a.text.localeCompare(b.text));
    return options;
  }

  // BAK
  // @computed get delegationEnabled() {
  //   let countryNumber = UserState.currentUserProfile.countryNumber;
  //   if(!countryNumber) return false;
  //   for (let info of this.config.MIDInfo) {
  //     if (countryNumber.includes(info.Mid)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  @computed get delegationEnabled() {
    let countryNumber = UserState.currentUserProfile.countryNumber;
    if(!countryNumber) return false;
    for (let info of this.config.MIDInfo) {
      if (countryNumber.includes(info.Mid) && (info.delegateEPIRB === "Y" || info.delegateELT=== "Y" || info.delegatePLB=== "Y")) {
        return true;
      }
    }
    return false;
  }

  // BAK
  // delegationEnabledByType(beacon) {
  //   let countryNumber = UserState.currentUserProfile.countryNumber;
  //   if(!countryNumber) return false;
  //   for (let info of this.config.MIDInfo) {
  //     if (countryNumber.includes(info.Mid) && beacon.beaconType) {
  //       if (beacon.beaconType.includes("EPIRB") && info.delegateEPIRB === "Y") {
  //         return false;
  //       } else if (beacon.beaconType.includes("ELT")&& info.delegateELT=== "Y") {
  //         return false;
  //       } else if (beacon.beaconType.includes("PLB")&& info.delegatePLB=== "Y") {
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // }
  delegationEnabledByType(beacon) {
    let countryNumber = UserState.currentUserProfile.countryNumber;
    // console.log('-----> check delegation: ', toJS(countryNumber), beacon)
    if(!countryNumber) return false;
    for (let info of this.config.MIDInfo) {
      if (countryNumber.includes(info.Mid) && beacon.beaconType) {
        if (beacon.beaconType.includes("EPIRB") && info.delegateEPIRB === "Y") {
          return true;
        } else if (beacon.beaconType.includes("ELT") && info.delegateELT=== "Y") {
          return true;
        } else if (beacon.beaconType.includes("PLB") && info.delegatePLB=== "Y") {
          return true;
        }
      }
    }
    return false;
  }

  @computed get countryNamesForReport() {
    if (!this.config.MIDInfo) return [];
    const options = this.config.MIDInfo.map((entry) => {
      return { key: entry.Mid, value: entry.Mid, text: entry.CountryName };
    });
    return options;
  }

  @computed get roleId() {
    if (!this.config || !this.config.roles) return [];
    const options = this.config.roles.map((entry) => {
      return { key: entry.RoleId, value: entry.RoleId, text: entry.RoleName };
    });
    options.unshift({ key: "99", value: "", text: "" });
    return options;
  }

  getRoleName(roleId) {
    if (!this.config || !this.config.roles) return [];

    return this.config.roles.filter((item) => item.RoleId == roleId).map((item) => item.RoleName);
  }

  @computed get challengeQuestions() {
    if (!this.config || !this.config.passwordQuestionsEnglish) return [];
    switch (AppState.currentLanguage) {
      case "en-US":
        let option = this.config.passwordQuestionsEnglish.map((entry) => {
          return { key: entry.ChallengeQuestion, value: entry.ChallengeQuestion, text: entry.ChallengeQuestion };
        });
        option.unshift({ key: "0", value: "", text: "" });
        return option;
        break;

      default:
        break;
    }
  }

  getEmailTemplateKey(variableName) {
    if (!this.config || !this.config.emailTemplateKey) return [];
    return this.config.emailTemplateKey.filter((item) => item.variableName == variableName).map((item) => item.emailtemplateKey);
  }

  // getCultureKey(languageCode) {
  //   return this.config.languages
  //           .filter(item => item.code == languageCode)
  //           .map(item => item.cultureKey);
  // }

  // getEmailTemplate(emailTemplateKey, cultureKey) {
  //   return this.config.emailTemplateLanguages
  //             .find(item => (item.emailtemplateKey == emailTemplateKey && item.cultureKey == cultureKey));
  // }

  getEmailTemplate(emailTemplateKey) {
    if (!this.config || !this.config.emailTemplateLanguages) return [];

    const cultureKey = this.config.languages.filter((item) => item.code == AppState.currentLanguage).map((item) => item.cultureKey);

    return this.config.emailTemplateLanguages.find((item) => item.emailtemplateKey == emailTemplateKey && item.cultureKey == cultureKey);
  }

  @computed get pages() {
    if (!this.config || !this.config) return [];
    const options = this.config.page.map((entry) => {
      return { key: entry.pageKey, value: entry.pageKey, text: entry.pageName };
    });
    //options.unshift({ key: "0", value: "", text: "" });
    return options;
  }

  getPageContentOptions(pageKey) {
    if (!this.config || !this.config) return [];

    const cultureKey = this.config.languages.filter((item) => item.code == AppState.currentLanguage).map((item) => item.cultureKey);

    const options = this.config.pageContentLanguages
      .filter((item) => item.pageKey == pageKey && item.cultureKey == cultureKey)
      .map((entry) => {
        let pageContent = this.config.pageContent.find((item) => item.pageKey == entry.pageKey && item.pageContentKey == entry.pageContentKey);
        let text = (pageContent.isPublished ? "(" + (pageKey == "GroupUserNotice" ? "P" : pageContent.order) + ") " : "") + entry.subject;

        let order = (pageContent.isPublished ? "0" : "1") + text;
        return { key: entry.pageContentKey, value: entry.pageContentKey, text: text, order: order };
      });

    return options.sort((a, b) => a.order.localeCompare(b.order));
    /*return options.sort((a, b) => {
      var textA = a.text.toUpperCase();
      var textB = b.text.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });*/
  }

  getPageContent(pageKey, pageContentKey) {
    if (!this.config || !this.config) return [];
    return this.config.pageContent.find((item) => item.pageKey == pageKey && item.pageContentKey == pageContentKey);
  }

  getPageContentLanguages(pageKey, pageContentKey) {
    if (!this.config || !this.config) return [];
    return this.config.pageContentLanguages.filter((item) => item.pageKey == pageKey && item.pageContentKey == pageContentKey);
  }

  getPublishedPageContentWithCurrentLanguage(pageKey) {
    if (!this.config || !this.config) return [];

    const cultureKey = this.config.languages.filter((item) => item.code == AppState.currentLanguage).map((item) => item.cultureKey);

    let pageContent = this.config.pageContent
      .filter((item) => item.pageKey == pageKey && item.isPublished == true)
      .map((entry) => {
        let data = this.config.pageContentLanguages.find((item) => item.pageKey == entry.pageKey && item.pageContentKey == entry.pageContentKey && item.cultureKey == cultureKey);

        if (pageKey == "GroupUserNotice") {
          return {
            pageKey: entry.pageKey,
            pageContentKey: entry.pageContentKey,
            mids: entry.mids,
            beaconTypes: entry.beaconTypes,
            roleIds: entry.roleIds,
            order: entry.order,
            isPublished: entry.isPublished,
            cultureKey: data.cultureKey,
            subject: data.subject,
            content: data.content,
          };
        } else {
          return {
            pageKey: entry.pageKey,
            pageContentKey: entry.pageContentKey,
            order: entry.order,
            isPublished: entry.isPublished,
            cultureKey: data.cultureKey,
            subject: data.subject,
            content: data.content,
          };
        }
      });

    //console.log("pageContent", JSON.stringify(pageContent))

    return pageContent.sort((a, b) => a.order - b.order);
  }
}

const singleton = new AppCOnfigState(); //export class as singleton to be used through the application.
export default singleton;
