import i18n from "i18next";
import React, { Component } from "react";
import RegistrationState from "../../state/registrationState";
import * as Yup from "yup";
import { Form, Button, Message, Popup, Grid } from "semantic-ui-react";
import withSemanticUIFormik from "../../utils/formHelper";
import RouteState from "../../state/routeState";
import UserState from "../../state/userState";
import AppState from "../../state/appState"

const InnerLoginForm = props => {
  const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, status, isValid } = props;

  let usernameEror = touched.username && errors.username && errors.username.length > 0;
  let passwordError = touched.password && errors.password && errors.password.length > 0;
  const t = AppState.t;
  return (
    <Form id="loginForm" onSubmit={handleSubmit} error={usernameEror || passwordError || status}>
      <Form.Field>
        <label>
          {t('username')}
          <Popup content={t('you-can-use-your-username-email-address-or-beacon-hex-id')} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
        </label>
        <Form.Input icon="user" name="username" iconPosition="left" placeholder={t('username')} onChange={handleChange} onBlur={handleBlur} error={usernameEror} />
      </Form.Field>

      <Form.Input icon="lock" name="password" iconPosition="left" label={t('password')}  placeholder={t('password')} type="password" onChange={handleChange} onBlur={handleBlur} error={passwordError} />

      <Grid centered columns={1}>
       <Grid.Row centered columns={2}>
          <Grid.Column>
            <Button type="submit" content={t('login')} primary loading={isSubmitting} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={2}>
          <Grid.Column>
            <Message className="newBeacon-errorMessage animated fadeIn" compact error content={errors.username|| errors.password  || status} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

//Higher order function wrapping the form to handle initial input using mapPropsToValues or submit using handleSubmit
const LoginForm = withSemanticUIFormik({
  mapPropsToValues: () => ({
    username: "",
    password: ""
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().required(i18n.t("username-is-required")),
    password: Yup.string().required(i18n.t("password-is-required"))
  }),

  handleSubmit: (values, { setSubmitting, isValid, setStatus }) => {
    console.log("handleSubmit: values", values);
    setSubmitting(true);
    UserState.login(values.username, values.password)
      .then(response => {
      console.log('log ~ file: loginForm.js ~ line 61 ~ response', response);
        setSubmitting(false);
        if(response.error){
          setStatus(response.message);
        }
        else{
          /*
          if(response.displayDisclaimer){
            switch(response.roleId){
              case "2":
                RouteState.setRoute("/ship-disclaimer");
                break;
              default:
                RouteState.setRoute("/sar-disclaimer");
                break;
            }
          }
          else{
            RouteState.setRoute("/dashboard");
          }*/
          switch(response.roleId){
            case "4":
              RouteState.setRoute("/sar-disclaimer");
              break;
            default:
              RouteState.setRoute("/user-disclaimer");
              break;
          }

        }
      })
      .catch(error => {
      console.log('log ~ file: loginForm.js ~ line 93 ~ error', error);
        // check legacy user first!
        if(error.isLegacyUser && error.isLegacyUser == true){
          UserState.setLegacyUserProfile(error.legacyUserProfile);
          RouteState.setRoute("/forgot-password");
        }

        UserState.incrementLoginTries(values);
        console.log("handleSubmit: error", error);
        setSubmitting(false);
        // let errorReason = error.reason == 'Name or password is incorrect.' ? 'Username or password is incorrect' : "The credentials you entered do not match our records. Please try again or click on 'Forgot your password?' to reset your password. After 4 more attempts, the system will temporarily disable your account.";
        const t = AppState.t;
        let errorReason = t('the-credentials-you-entered-do-not-match-our-records-please-try-again-or-click-on-forgot-your-password-to-reset-your-password-after-5-attempts-the-system-will-temporarily-disable-your-account');

        setStatus(errorReason);
      });
  }
})(InnerLoginForm);

export default LoginForm;
