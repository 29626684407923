import { getTACDb } from './dbService'
import { insertOrUpdateDocument } from './serviceUtils';
import TacState from '../state/tacState';

export async function searchTAC(criteria) {
	// console.log('>>>>>> TAC criteria: ', criteria)

	let tacDb = await getTACDb();
	if(!tacDb) return;
	let selector = {
        type: "tac",
        // type: "manufacturers",
		// type: "beacon_types",
	};
	if (criteria.tac_number) {
		selector["tac_number"] = {$regex: "(?i)" + criteria.tac_number[0] + "(?i)" };
	}
	if (criteria.name) {
		selector["name"] = { $regex: "(?i)" + criteria.name[0] + "(?i)" };
	}
	if (criteria.manufacturer) {
		// text in db
		selector["manufacturer"] = { $regex: "(?i)" + criteria.manufacturer[0] + "(?i)" };
	}
	if (criteria.database_id) {
		selector["database_id"] = { $regex: "(?i)" + criteria.database_id[0] + "(?i)" };
	}

	if (criteria.beacon_type_id) {
		// text in db
		// search beacon for beacon_type_id first
		let beacons = await this.getBeaconTypes({value: criteria.beacon_type_id});
		if(beacons){
			let ids = beacons.docs.map(entry => {
				return entry.id;
			})
			selector["beacon_type_id"] = { $in: ids };
		}
	}
	if (criteria.homer_frequency) {
		let items = TacState.homerFrequencyOption.filter(entry => {
			let entryLow = entry.text.toLowerCase();
			let searchLow = criteria.homer_frequency[0].toLowerCase();
			if(entryLow.includes(searchLow)){
				return entry;
			}
		});
		if(items){
			let ids = items.map(entry => {
				return entry.value;
			})
			selector["homer_frequency"] = { $in: ids };
		}
	}
	if (criteria.id) {
		selector["id"] = { $regex: "(?i)" + criteria.id[0] + "(?i)" };
	}

	// for public page
	if (criteria.manufacturer_id) {
		// selector["manufacturer_id"] = { $in: criteria.manufacturer_id };
		selector["manufacturer_id"] = criteria.manufacturer_id
	}
	if (criteria.beacon_id) {
		selector["beacon_type_id"] = criteria.beacon_id
	}

	try {
		var result = await tacDb.find({
			selector: selector,
			use_index: "byType",
			// use_index: "tacSearchIndex",
			// sort: ['tac_number']
		});
		if(result.docs){
			let tacSorted = result.docs.sort((a,b) => a.tac_number.localeCompare(b.tac_number))
			result.docs = tacSorted
		}
		return result;
	} catch (err) {
		console.error("tacService:searchPOC - error", err);
		throw err;
	}
}

export async function getManufacturers(criteria) {
	let tacDb = await getTACDb();
	if(!tacDb) return;
	let selector = {
		type: "manufacturers",		
	};

	try {
		var result = await tacDb.find({
			selector: selector,
            use_index: "byType"
		});
		return result;
	} catch (err) {
		console.error("tacService:searchPOC - error", err);
		throw err;
	}
}

export async function getBeaconTypes(criteria) {
	let tacDb = await getTACDb();
	if(!tacDb) return;
	let selector = {
		type: "beacon_types",
		state: "1"
	};

	if(criteria.value){
		selector["value"] = { $regex: "(?i)" + criteria.value[0] + "(?i)" };
	}

	try {
		var result = await tacDb.find({
			selector: selector,
            use_index: "byType"
		});
		return result;
	} catch (err) {
		console.error("tacService:searchPOC - error", err);
		throw err;
	}
}

export async function saveTAC(item){
    let db = await getTACDb()
    try{
        let result = await insertOrUpdateDocument(item, db);
		return result;
    } catch(error) {
        console.log('tacService -> savePoc -> error: ' + error)
    }
}

export async function deleteTAC(tacId){
    let db = await getTACDb()
    try{
        let data = {};
        let doc = await db.get(tacId);
        console.log("log: deleteTAC -> doc", doc);
        if (doc) {
            let deleteFromTacDB = await db.remove(doc);
            data.deleteFromTacDB = deleteFromTacDB;
        }
        return data;
    } catch(error) {
        console.log('tacService -> deleteTAC -> error: ' + error)
    }
}

export async function searchManufacturer(criteria) {
	let tacDb = await getTACDb();
	if(!tacDb) return;
	let selector = {
        type: "manufacturers",
	};

	if (criteria.name) {
		selector["name"] = { $regex: "(?i)" + criteria.name[0] + "(?i)" };
	}
	if (criteria.business_status) {
		selector["business_status"] = { $regex: "(?i)" + criteria.business_status[0] + "(?i)" };
	}
	if (criteria.address1) {
		selector["address1"] = { $regex: "(?i)" + criteria.address1[0] + "(?i)" };
	}
	if (criteria.city) {
		selector["city"] = { $regex: "(?i)" + criteria.city[0] + "(?i)" };
	}
	if (criteria.postal_code) {
		selector["postal_code"] = { $regex: "(?i)" + criteria.postal_code[0] + "(?i)" };
	}
	if (criteria.telephone1) {
		selector["telephone1"] = { $regex: "(?i)" + criteria.telephone1[0] + "(?i)" };
	}
	if (criteria.fax) {
		selector["fax"] = { $regex: "(?i)" + criteria.fax[0] + "(?i)" };
	}
	if (criteria.email) {
		selector["email"] = { $regex: "(?i)" + criteria.email[0] + "(?i)" };
	}
	if (criteria.website) {
		selector["website"] = { $regex: "(?i)" + criteria.website[0] + "(?i)" };
	}
	if (criteria.id) {
		selector["id"] = { $regex: "(?i)" + criteria.id[0] + "(?i)" };
	}
	if (criteria.countryText) {
		// selector["country"] = { $regex: "(?i)" + criteria.country[0] + "(?i)" };
		let countrys = TacState.countryOption.filter(entry => {
			let entryLow = entry.text.toLowerCase();
			let countryLow = criteria.countryText[0].toLowerCase();
			if(entryLow.includes(countryLow)){
				return entry;
			}
		});
		if(countrys){
			let ids = countrys.map(entry => {
				return entry.value;
			})
			selector["country"] = { $in: ids };
		}
	}

	try {
		var result = await tacDb.find({
			selector: selector,
            use_index: "byType"
		});
		return result;
	} catch (err) {
		console.error("tacService:searchPOC - error", err);
		throw err;
    }
}

export async function getLastTacId() {
    let tacDb = await getTACDb();
    if(!tacDb) return;
    let selector = {
        type: "tac",
    };
    try {
        var result = await tacDb.find({
            selector: selector,
            use_index: "byType",
        });
        return result;
    } catch (err) {
        console.error("tacService:searchPOC - error", err);
        throw err;
    }
}

export async function getLastManufacturerId() {
    let tacDb = await getTACDb();
    if(!tacDb) return;
    let selector = {
        type: "manufacturers",
    };
    try {
        var result = await tacDb.find({
            selector: selector,
            use_index: "byType",
        });
        return result;
    } catch (err) {
        console.error("tacService:searchPOC - error", err);
        throw err;
    }
}

export async function isExistingId(tacId) {
    let tacDb = await getTACDb();
    if(!tacDb) return;
    try {
        var result = await tacDb.get();
        if(result){
            return true;
        }
        else{
            return false;
        }
    } catch (err) {
        console.error("tacService:searchPOC - error", err);
        throw err;
    }
}