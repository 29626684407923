import React, { Component } from "react"
import { List, Accordion, Icon, Header, Transition } from "semantic-ui-react"
import RouteState from "../state/routeState"
import './sideMenu.css'
import { observer } from "mobx-react"
import DashboardState from '../state/dashboardState'

const animationTime = 300;

let listsMenu = [
    {text: 'Activation Method', path: '/activation-method'},
    {text: 'Beacon Homing Device', path: '/beacon-homing-device'},
    {text: 'Phone Type', path: '/phone-type'},
    {text: 'Beacon Manufacturer', path: '/beacon-manufacturer'},
    {text: 'Radio Call Sign', path: '/radio-call-sign'},
    {text: 'Radio Equipment', path: '/radio-equipment'},
    {text: 'Vehicle Type - ELT', path: '/vehicle-type-elt'},
    {text: 'Vehicle Type - EPIRB', path: '/vehicle-type-epirb'},
    {text: 'Vehicle Type - PLB', path: '/vehicle-type-plb'},
    {text: 'Password Challenge - English', path: '/password-challenge-english'},
    {text: 'Password Challenge - French', path: '/password-challenge-french'},
    {text: 'Password Challenge - Russian', path: '/password-challenge-russian'},
    {text: 'Password Challenge - Spanish', path: '/password-challenge-spanish'},
]

let emailTemplateList = [
    {text: 'User Registration', path: '/email-template/user-registration'},
    {text: 'User Registration Completion', path: '/email-template/user-registration-completion'},
    {text: 'Beacon Registration', path: '/email-template/beacon-registration'},
    {text: 'Beacon Modification', path: '/email-template/beacon-modification'},
    {text: 'Delegate Beacon Registration For New User', path: '/email-template/delegate-beacon-registration-newuser'},
    {text: 'Delegate Beacon Registration', path: '/email-template/delegate-beacon-registration'},
    {text: 'Delegate Beacon Registration Completion', path: '/email-template/delegate-beacon-registration-completion'},
    {text: 'Password Reset', path: '/email-template/reset-password'},
    {text: 'Questionnaire', path: '/email-template/questionnaire'},
    {text: 'Reminder Email', path: '/email-template/reminder-email'},
    {text: 'Reminder SMS', path: '/email-template/reminder-sms'},
    {text: 'Contact Us', path: '/email-template/contact-us'},
    {text: 'Contact Us Not Supported', path: '/email-template/contact-us-not-supported'},
    {text: 'Contact Up Supported', path: '/email-template/contact-up-supported'},
    {text: 'Contact Us Auto', path: '/email-template/contact-us-auto'},
    {text: 'Beacon Status', path: '/email-template/beacon-status'},
    {text: "Admin Updated User's Email", path: "/email-template/admin-updated-user-email"},
    {text: 'Admin Created New User', path: '/email-template/admin-created-new-user'},
    {text: 'Legacy User Reset Password', path: '/email-template/legacy-user-reset-password'},
    {text: 'Attempt Register Existed Beacon', path: '/email-template/attempt-register-existed-beacon'},
    {text: 'Beacon Status Set Sold Via Email', path: '/email-template/beacon-status-set-sold-via-email'},
    {text: 'Beacon Status Set Sold Notify User', path: '/email-template/beacon-status-set-sold-notify-user'},
]


@observer
export default class SideMenu extends Component {

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const activeIndexs = DashboardState.activeIndexs;
        const newIndex = activeIndexs;

        const currentIndexPosition = activeIndexs.indexOf(index);
        if (currentIndexPosition > -1) {
          newIndex.splice(currentIndexPosition, 1);
        } else {
          newIndex.push(index);
        }

        DashboardState.setActiveIndexs(newIndex)
    }

    onClickGoToPage = (path) => {
        RouteState.setRoute(path)
    }

    render(){
        let activeIndexs = DashboardState.activeIndexs

        return(
            <div className='side-menu'>
                <List>
                    <List.Item onClick={() => this.onClickGoToPage("/dashboard")} active={JSON.stringify(RouteState.getState()).includes('/dashboard')}>
                        <List.Content as='h3'>Search</List.Content>
                    </List.Item>
                </List>

                <Accordion>
                    {/* item1: Statistics */}
                    <Accordion.Title
                    active={activeIndexs.includes(0)}
                    index={0}
                    onClick={this.handleClick}
                    >
                        <List>
                            <List.Item className='no-click'>
                                <Header as='h3'>Statistics <Icon name='dropdown' /></Header>
                            </List.Item>
                        </List>
                    </Accordion.Title>
                    <Transition visible={activeIndexs.includes(0)} animation='fade down' duration={animationTime}>
                        <Accordion.Content active={activeIndexs.includes(0)}>
                            <List className="sub-category">
                                <List.Item onClick={() => RouteState.setRoute("/report/beacon-manufacturers")} active={RouteState.getState() === '/report/beacon-manufacturers'}>
                                    <List.Content>Beacon Manufacturers</List.Content>
                                </List.Item>
                                <List.Item onClick={() => RouteState.setRoute("/report/communication-language")} active={RouteState.getState() === '/report/communication-language'}>
                                    <List.Content>Communication Language</List.Content>
                                </List.Item>
                                <List.Item onClick={() => RouteState.setRoute("/report/country-code")} active={RouteState.getState() === '/report/country-code'}>
                                    <List.Content>Country Code</List.Content>
                                </List.Item>
                                <List.Item onClick={() => RouteState.setRoute("/report/new-registrations")} active={RouteState.getState() === '/report/new-registrations'}>
                                    <List.Content>New Registrations</List.Content>
                                </List.Item>
                                <List.Item onClick={() => RouteState.setRoute("/report/sar-login")} active={RouteState.getState() === '/report/sar-login'}>
                                    <List.Content>SAR Login</List.Content>
                                </List.Item>
                                <List.Item onClick={() => RouteState.setRoute("/report/special-status")} active={RouteState.getState() === '/report/special-status'}>
                                    <List.Content>Special Status</List.Content>
                                </List.Item>
                            </List>
                        </Accordion.Content>
                    </Transition>

                    {/* item2: Admin */}
                    <Accordion.Title
                    active={activeIndexs.includes(1)}
                    index={1}
                    onClick={this.handleClick}
                    >
                        <List>
                            <List.Item className='no-click'>
                                <List.Content as='h3'>Admin <Icon name='dropdown' /></List.Content>
                            </List.Item>
                        </List>
                    </Accordion.Title>
                    <Transition visible={activeIndexs.includes(1)} animation='fade down' duration={animationTime}>
                        <Accordion.Content active={activeIndexs.includes(1)}>
                            <List className="sub-category">
                                {/* email templates */}
                                <List.Item className='no-click'>
                                    <SubCategory index={2} activeIndexs={activeIndexs} handleClick={this.handleClick} subMenuItems={emailTemplateList} mainTitle='Email Templates' />
                                </List.Item>

                                <List.Item onClick={() => RouteState.setRoute("/import-instructions")} active={RouteState.getState() === '/import-instructions'}>
                                    <List.Content>Import Instructions</List.Content>
                                </List.Item>

                                {/* Lists */}
                                <List.Item className='no-click'>
                                    <SubCategory index={3} activeIndexs={activeIndexs} handleClick={this.handleClick} subMenuItems={listsMenu} mainTitle='Lists' />
                                </List.Item>

                                <List.Item onClick={() => RouteState.setRoute("/mid-info")} active={RouteState.getState() === '/mid-info'}>
                                    <List.Content>MID Info</List.Content>
                                </List.Item>

                                <List.Item onClick={() => RouteState.setRoute("/page-content")} active={RouteState.getState() === '/page-content'}>
                                    <List.Content>Pages</List.Content>
                                </List.Item>

                                <List.Item onClick={() => RouteState.setRoute("/point-of-contacts")} active={RouteState.getState() === '/point-of-contacts'}>
                                    <List.Content>Point of Contacts</List.Content>
                                </List.Item>

                                <List.Item onClick={() => RouteState.setRoute("/tac-management")} active={RouteState.getState() === '/tac-management'}>
                                    <List.Content>TAC Management</List.Content>
                                </List.Item>

                                <List.Item onClick={() => RouteState.setRoute("/users")} active={RouteState.getState() === '/users'}>
                                    <List.Content>Users</List.Content>
                                </List.Item>

                            </List>
                        </Accordion.Content>
                    </Transition>

                </Accordion>

            </div>
        )
    }
}

const SubCategory = ({index, activeIndexs, handleClick, subMenuItems, mainTitle}) => {

    subMenuItems.sort((a,b) => a.text.localeCompare(b.text));
    let menuList = subMenuItems.map((row, i) => {
        return <List.Item key={i} onClick={() => RouteState.setRoute(row.path)} active={RouteState.getState() === row.path}>
            <List.Content>{row.text}</List.Content>
        </List.Item>
    });

    return(
        <Accordion>
            <Accordion.Title
            active={activeIndexs.includes(index)}
            index={index}
            onClick={handleClick}
            >
                <List>
                    <List.Item className='no-click'>
                        <List.Content>{mainTitle} <Icon name='dropdown' className='adjustMargin' /></List.Content>
                    </List.Item>
                </List>
            </Accordion.Title>
            <Transition visible={activeIndexs.includes(index)} animation='fade down' duration={animationTime}>
                <Accordion.Content active={activeIndexs.includes(index)}>
                    <List>
                        {menuList}
                    </List>
                </Accordion.Content>
            </Transition>
        </Accordion>
    );
}