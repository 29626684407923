import i18n from "i18next";
import React, { Component, createRef } from "react";
import { Grid, Header, Icon, Input, Table, Message } from "semantic-ui-react";
import "./beaconDecode.css";
import beaconDecode from "../../utils/beaconDecode/BeaconDecode";
import ImageUploader from "../../components/imageUpload";
import TopMenu from "../../components/topMenu";
import Footer from "../../components/footer";
import QRInput from "../../components/QRInput"
import { indexOf } from "pouchdb-find";
import HexUtils from "../../utils/beaconDecode/hexUtils";

export default class BeaconDecodePage extends Component {
  state = {
    decodedBeacon: {},
    checkSum: null,
    error: null,
  };
  inputRef = createRef();

  onDecodeClick = async () => {
    let beaconHex = this.inputRef.current.inputRef.value;
    if ([15, 23].includes(beaconHex.length)) {
      let checkSum = HexUtils.getFiveCharChecksum(beaconHex);
      try {
        let result = await beaconDecode(beaconHex);
        this.setState({
          decodedBeacon: result,
          checkSum: checkSum,
          error: null,
        });
      } catch (error) {
        this.setState({
          decodedBeacon: {},
          checkSum: checkSum,
          error: { title: "Decoder error", desc: error.message },
        });
      }

      // console.log("BeaconDecodePage -> onDecodeClick", beaconHex, result);
    } else {
      let text = "Hex length of " + beaconHex.length + ". Valid Lengths: FGB: 15. SGB: 15 or 23";
      this.setState({
        decodedBeacon: {},
        checkSum: null,
        error: { title: "Length Error", desc: text },
      });
    }
  };

  render() {
    const { decodedBeacon, checkSum, error } = this.state;
    let onDecodeClick = this.onDecodeClick;
    let tableRows = [];
    for (let [key, value] of Object.entries(decodedBeacon)) {
      let row = (
        <Table.Row key={key}>
          <Table.Cell>{key}</Table.Cell>
          <Table.Cell>{value}</Table.Cell>
        </Table.Row>
      );
      tableRows.push(row);
    }

    let content;
    if (error) {
      content = <HexError error={error} />;
    } else if (tableRows.length) {
      content = <DecocedTable tableRows={tableRows} decodedBeacon={decodedBeacon} checkSum={checkSum} />;
    } else {
      content = <DecodeDisclaimer />;
    }

    return (
      <>
        <TopMenu />
        <Grid className="beaconDecode-container" centered>
          <Grid.Row centered>
            <Grid.Column className="beaconDecode-header" width="16">
              <Header as="h2" icon>
                <Icon name="settings" />
              {i18n.t('beacon-decode')}
                <Header.Subheader>{i18n.t('decode-a-beacon-and-validate')}</Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="16">
              <Input
                label="Hex"
                fluid
                ref={this.inputRef}
                placeholder={i18n.t('enter-hexadecimal-code')}
                // defaultValue="19B30CA49A3FDFF"
                action={{
                  color: "red",
                  icon: "search",
                  // content: i18n.t(k.DECODE),
                  onClick: () => {
                    this.onDecodeClick();
                  },
                }}
                className="decode-input"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="16">{content}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Footer />
      </>
    );
  }
}

const HexError = ({ error }) => {
  return (
    <Message warning className="decoded-message">
      <Message.Header>{error.title}</Message.Header>
      <p>{error.desc}</p>
    </Message>
  );
};

class DecocedTable extends Component {
  render() {
    let { tableRows, decodedBeacon, checkSum } = this.props;

    let lengthMsg;
    if (decodedBeacon.hexId.length === 15) {
      lengthMsg = i18n.t('hex-data-entered-is-a-15-hex-id-unique-identifier-based-on-fgb-specifications-as-per-t-001-issue-4-rev-5');
    } else if (decodedBeacon.hexId.length === 23) {
      lengthMsg = i18n.t('hex-data-length-of-23-consistent-with-hex-unique-identifier-based-on-sgb-specifications-as-per-t-018-issue-1-rev-4');
    }

    return (
      <div>
        <div className="decoded-message">
          <h2>Decoded Beacon Message</h2>
          <div>
           {i18n.t('the-hexadecimal-code-entered-was')}: <strong>{decodedBeacon.hexId || ""}</strong>
          </div>
          <div>{lengthMsg}</div>
          <div>{i18n.t('computed-checksum')}: {checkSum || ""}</div>
          <div>{i18n.t('beacon-unique-identifier')}:</div>
          <div>{i18n.t('message-is-a-uin')}</div>
        </div>
        <Table celled striped className="beaconDecode-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{i18n.t('name')}</Table.HeaderCell>
              <Table.HeaderCell>{i18n.t('description')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{tableRows}</Table.Body>
        </Table>
      </div>
    );
  }
}

class DecodeDisclaimer extends Component {
  render() {
    return (
      <>
        <div id="decode-disclaimer">
          <h3>Disclaimer</h3>
          <div style={{ color: "red" }}>
            <strong>
              {i18n.t('this-decoder-is-provided-by-the-secretariat')}
            </strong>
          </div>

          <h3>{i18n.t('instructions-for-use')}:</h3>
          <p>
            {i18n.t('enter-beacon-hexadecimal-information-then-click-process')}
          </p>
          <p>
            {i18n.t('if-the-program-complains-that-a-character-is-out-of-range')}
          </p>

          <h3>{i18n.t('this-decode-program-tool-is-not-a-means-of-registering-your-beacon')}</h3>
          <p>
           {i18n.t('this-tool-tells-you-what-information')}{" "}
            <a href="http://www.406registration.com" target="_blank">
              www.406registration.com
            </a>{" "}
            {i18n.t('or-otherwise-with-the-proper-authorities')}
          </p>
        </div>
      </>
    );
  }
}
