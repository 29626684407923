import i18n from 'i18next'
import React, { Component } from 'react'
import * as Yup from 'yup'
import { Form, Button, Header } from 'semantic-ui-react'
import withSemanticUIFormik from '../../utils/formHelper'
import PocState from '../../state/pocState'

class InnerForm extends Component {

	render() {

		const {
			values,
			touched,
			errors,
			dirty,
			isSubmitting,
			handleChange,
			handleBlur,
			handleSubmit,
			handleReset,
			isValid,
            onClose
        } =	this.props;

		return (
			<Form className="poc-form animated fadeIn faster">
				<Header as='h2' icon textAlign='center'>Edit point of contact.</Header>

                {/* <Form.Group >
                    <Form.Field width='4'>
                        POCindex:
                    </Form.Field>
                    <Form.Input width='8' fluid name='pOCIndex' placeholder='' value={values.pOCIndex} onBlur={handleBlur} onChange={handleChange} disabled={true} />
                </Form.Group> */}
                <Form.Group >
                    <Form.Field width='4'>
                        name:
                    </Form.Field>
                    <Form.Input width='8' fluid name='name' placeholder='' value={values.name} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        telex:
                    </Form.Field>
                    <Form.Input width='8' fluid name='telex' placeholder='' value={values.telex} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        fax1:
                    </Form.Field>
                    <Form.Input width='8' fluid name='fax1' placeholder='' value={values.fax1} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        telephone1:
                    </Form.Field>
                    <Form.Input width='8' fluid name='telephone1' placeholder='' value={values.telephone1} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>          
                <Form.Group >
                    <Form.Field width='4'>
                        address:
                    </Form.Field>
                    <Form.Input width='8' fluid name='address' placeholder='' value={values.address} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        email:
                    </Form.Field>
                    <Form.Input width='8' fluid name='email' placeholder='' value={values.email} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        registration:
                    </Form.Field>
                    <Form.Input width='8' fluid name='registration' placeholder='' value={values.registration} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        poc:
                    </Form.Field>
                    <Form.Input width='8' fluid name='poc' placeholder='' value={values.poc} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        mcc:
                    </Form.Field>
                    <Form.Input width='8' fluid name='mcc' placeholder='' value={values.mcc} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>

                <Form.Group >
                    <Form.Field width='4'>
                        aftn:
                    </Form.Field>
                    <Form.Input width='8' fluid name='aftn' placeholder='' value={values.aftn} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        city:
                    </Form.Field>
                    <Form.Input width='8' fluid name='city' placeholder='' value={values.city} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        zipcode:
                    </Form.Field>
                    <Form.Input width='8' fluid name='zipcode' placeholder='' value={values.zipcode} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        email2:
                    </Form.Field>
                    <Form.Input width='8' fluid name='email2' placeholder='' value={values.email2} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        website_url:
                    </Form.Field>
                    <Form.Input width='8' fluid name='website_url' placeholder='' value={values.website_url} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
           
                <Form.Group >
                    <Form.Field width='4'>
                        ci_webpage_1:
                    </Form.Field>
                    <Form.Input width='8' fluid name='ci_webpage_1' placeholder='' value={values.ci_webpage_1} onBlur={handleBlur} onChange={handleChange} />
                </Form.Group>
                <Form.Group >
                    <Form.Field width='4'>
                        comments:
                    </Form.Field>
                    <Form.TextArea width='8' fluid name='comments' placeholder='' value={values.comments} onBlur={handleBlur} onChange={handleChange} rows={5} />
                </Form.Group>

 				<div className="activityDetail-submitContainer">
					<Button className="registerBeacon-submitButton" onClick={onClose}>Close</Button>
					<Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit} loading={isSubmitting} >Save</Button>
				</div>
			</Form>
		);
	}

};

const PocForm = withSemanticUIFormik({
	mapPropsToValues: ({ item }) => {
		if(item){
            // console.log('>>>>>> poc save: ' + JSON.stringify(item))
			return {
                _id:                item._id,
                _rev:               item._rev,
                type:               item.type, // or static text = 'POCinfo'
				pOCIndex:           item.pOCIndex,
                name:      item.name,
                telex:     item.telex,
                fax1:          item.fax1,
                telephone1:     item.telephone1,
                address: item.address,
                email:        item.email,
                registration:       item.registration,
                poc:                item.poc,
                mcc:                item.mcc,

                aftn:         item.aftn,
                city:         item.city,
                zipcode:      item.zipcode,
                email2:       item.email2,
                website_url:  item.website_url,
                ci_webpage_1:      item.ci_webpage_1,
                comments:     item.comments,
			}
		}else{
			return {
                type: 'POCinfo',
				pOCIndex: '',
                name: '',
                telex: '',
                fax1: '',
                telephone1: '',
                address: '',
                email: '',
                registration: '',
                poc: '',
                mcc: '',

                aftn: '',
                city: '',
                zipcode: '',
                email2: '',
                website_url: '',
                ci_webpage_1: '',
                comments: '',
			}
		}
	},
	validationSchema: Yup.object().shape({

	}),

	handleSubmit: (values, { setSubmitting, isValid, props }) => {
        // console.log('log: values=====================@comment', values)

        // should ask user to save edit again
        PocState.savePoc(values).then(res => {
            props.onClose();
            PocState.searchPOC({});
        })
    }

})(InnerForm);

export default PocForm;