import * as CONST from "./beaconConstants";
import decodeBaudot from "./baudot";

export default async function decodeUsingLocationProtocol(beaconBinary, tacDb_remote, countryCode) {
  console.log("log: decodeUsingLocationProtocol -> beaconBinary", beaconBinary);
  let locationProtocolBinary = beaconBinary.substring(CONST.LP_BITS.start, CONST.LP_BITS.end);
  console.log("Home: decodeUsingLocationProtocol -> locationProtocol", locationProtocolBinary);
  let decodedData;
  if (CONST.LP_NATIONAL_LOCATIONS.includes(locationProtocolBinary)) {
    decodedData = decodeNationalLocation(beaconBinary, locationProtocolBinary);
  } else if (locationProtocolBinary === CONST.LP_MMSI || locationProtocolBinary === CONST.LP_MMSI_FIXED) {
    decodedData = decodeMMSILocation(beaconBinary, locationProtocolBinary, countryCode);
  } else if (locationProtocolBinary === CONST.LP_AIRCRAFT_24BIT) {
    decodedData = decodeAircraft24bit(beaconBinary, locationProtocolBinary);
  } else if (locationProtocolBinary === CONST.LP_ELT_AIRCRAFT) {
    decodedData = decodeEltAircraft(beaconBinary, locationProtocolBinary);
  } else if (CONST.LP_SERIAL_IDENTIFICATION.includes(locationProtocolBinary)) {
    decodedData = decodeSerialIdentification(beaconBinary, locationProtocolBinary);
  } else if (locationProtocolBinary === CONST.LP_RLS) {
    decodedData = await decodeRLS(beaconBinary, locationProtocolBinary, tacDb_remote);
  } else if ((locationProtocolBinary = CONST.LP_ELT_DT)) {
    decodedData = await decodeEltDt(beaconBinary, locationProtocolBinary);
  }
  return {
    protocol: "Location",
    ...decodedData,
  };

  //console.log("Home: decodeUsingLocationProtocol -> decodedData", decodedData);
}

function decodeNationalLocation(beaconBinary, locationProtocolBinary) {
  let locationProtocol = CONST.LP_NATIONAL_LOCATIONS_VALUES[locationProtocolBinary];
  let beaconType = CONST.LP_NATIONAL_LOCATIONS_VALUES[locationProtocolBinary];
  let identificationDataBinary = beaconBinary.substring(CONST.LP_NATIONAL_LOCATIONS_IDDATA_BITS.start, CONST.LP_NATIONAL_LOCATIONS_IDDATA_BITS.end);
  let identificationData = parseInt(identificationDataBinary, 2);
  let defaultData = beaconBinary.substring(CONST.LP_NATIONAL_LOCATIONS_DEFAULTDATA_BITS.start, CONST.LP_NATIONAL_LOCATIONS_DEFAULTDATA_BITS.end);

  if (defaultData !== CONST.LP_NATIONAL_LOCATIONS_DEFAULT_DATA) {
    throw new Error("Location protocol error, wrong default data, got " + defaultData + " , expected " + CONST.LP_NATIONAL_LOCATIONS_DEFAULT_DATA);
  }
  return {
    beaconType,
    locationProtocol,
    identificationData,
    defaultData,
  };
}

function decodeMMSILocation(beaconBinary, locationProtocolBinary, countryCode) {
  let locationProtocol = CONST.LP_MMSI_VALUE;
  let beaconType;
  if (locationProtocolBinary === CONST.LP_MMSI) {
    locationProtocol = CONST.LP_MMSI_VALUE;
    beaconType = "EPIRB";
  } else if (locationProtocolBinary === CONST.LP_MMSI_FIXED) {
    locationProtocol = CONST.LP_MMSI_FIXED_VALUE;
    beaconType = "EPIRB (SSAS)";
  }
  let mmsiIdBinary = beaconBinary.substring(CONST.LP_MMSI_ID_BITS.start, CONST.LP_MMSI_ID_BITS.end);
  let MMSIDecoded =
    countryCode +
    parseInt(mmsiIdBinary, 2)
      .toString()
      .padStart(6, "0");
  console.log("log: decodeMMSILocation -> countryCode", countryCode, MMSIDecoded);
  let beaconNumberBinary = beaconBinary.substring(CONST.LP_BEACON_NUMBER_BITS.start, CONST.LP_BEACON_NUMBER_BITS.end);
  let beaconNumber = parseInt(beaconNumberBinary, 2);
  let defaultData = beaconBinary.substring(CONST.LP_BEACON_DEFAULT_DATA_BITS.start, CONST.LP_BEACON_DEFAULT_DATA_BITS.end);

  if (defaultData !== CONST.LP_MMSI_DEFAULT_DATA) {
    throw new Error("Location protocol error, wrong default data, got " + defaultData + " , expected " + CONST.LP_MMSI_DEFAULT_DATA);
  }

  return {
    beaconType,
    locationProtocol,
    MMSIDecoded,
    MMSI: MMSIDecoded,
    beaconNumber,
    defaultData,
  };
}

function decodeAircraft24bit(beaconBinary, locationProtocolBinary) {
  let locationProtocol = CONST.LP_AIRCRAFT_24BIT_VALUE;
  let beaconType = CONST.LP_AIRCRAFT_24BIT_VALUE;
  let airCraft24bitAddressBinary = beaconBinary.substring(CONST.LP_AIRCRAFT_24BIT_ADDRESS_BITS.start, CONST.LP_AIRCRAFT_24BIT_ADDRESS_BITS.end);
  let address24bits = parseInt(airCraft24bitAddressBinary, 2)
    .toString(16)
    .toUpperCase()
    .padStart(6, "0");
  let defaultData = beaconBinary.substring(CONST.LP_AIRCRAFT_24BIT_DEFAULT_DATA_BITS.start, CONST.LP_AIRCRAFT_24BIT_DEFAULT_DATA_BITS.end);
  if (defaultData !== CONST.LP_AIRCRAFT_24BIT_DEFAULT_DATA) {
    throw new Error("Location protocol error, wrong default data, got " + defaultData + " , expected " + CONST.LP_MMSI_DEFAULT_DATA);
  }
  return {
    locationProtocol,
    aircraft24BitAddress: address24bits,
    aircraft24BitAddressDecoded: address24bits,
    defaultData,
    beaconType,
  };
}

//TODO DECODE BEDOT ENCODED BINARY
function decodeEltAircraft(beaconBinary, locationProtocolBinary) {
  let locationProtocol = CONST.LP_ELT_AIRCRAFT_value;
  let beaconType = CONST.LP_ELT_AIRCRAFT_value;
  let aircraftOperator = beaconBinary.substring(CONST.LP_ELT_AIRCRAFT_OPERATOR_BITS.start, CONST.LP_ELT_AIRCRAFT_OPERATOR_BITS.end);
  let aircraftSerialNumber = beaconBinary.substring(CONST.LP_ELT_AIRCRAFT_SN_BITS.start, CONST.LP_ELT_AIRCRAFT_SN_BITS.end);
  let defaultData = beaconBinary.substring(CONST.LP_ELT_AIRCRAFT_DEFAULT_DATA_BITS.start, CONST.LP_ELT_AIRCRAFT_DEFAULT_DATA_BITS.end);

  if (defaultData !== CONST.LP_MMSI_DEFAULT_DATA) {
    throw new Error("Location protocol error, wrong default data, got " + defaultData + " , expected " + CONST.LP_MMSI_DEFAULT_DATA);
  }
  return {
    locationProtocol,
    beaconType,
    aircraftOperator,
    aircraftSerialNumber,
    defaultData,
  };
}

function decodeSerialIdentification(beaconBinary, locationProtocolBinary) {
  let locationProtocol = CONST.LP_SERIAL_IDENTIFICATION_VALUES[locationProtocolBinary];
  let beaconType = CONST.LP_SERIAL_IDENTIFICATION_VALUES[locationProtocolBinary];
  let typeApprovalCertificateBinary = beaconBinary.substring(CONST.LP_SERIAL_IDENTIFICATION_TYPE_APPROVAL_BITS.start, CONST.LP_SERIAL_IDENTIFICATION_TYPE_APPROVAL_BITS.end);
  let typeApprovalCertificate = parseInt(typeApprovalCertificateBinary, 2);
  let serialNumberBinary = beaconBinary.substring(CONST.LP_SERIAL_IDENTIFICATION_SN_BITS.start, CONST.LP_SERIAL_IDENTIFICATION_SN_BITS.end);
  let serialNumber = parseInt(serialNumberBinary, 2);
  let defaultData = beaconBinary.substring(CONST.LP_SERIAL_IDENTIFICATION_DEFAULT_DATA_BITS.start, CONST.LP_SERIAL_IDENTIFICATION_DEFAULT_DATA_BITS.end);

  if (defaultData !== CONST.LP_MMSI_DEFAULT_DATA) {
    throw new Error("Location protocol error, wrong default data, got " + defaultData + " , expected " + CONST.LP_MMSI_DEFAULT_DATA);
  }
  return {
    locationProtocol,
    beaconType,
    typeApprovalCertificate,
    serialNumber,
    defaultData,
  };
}

async function decodeRLS(beaconBinary, locationProtocolBinary, tacDb_remote) {
  let locationProtocol = CONST.LP_RLS_VALUE;
  let beaconTypeBinary = beaconBinary.substring(CONST.LP_RLS_BEACONTYPE_BITS.start, CONST.LP_RLS_BEACONTYPE_BITS.end);
  let beaconType = CONST.LP_RLS_BEACONTYPE_VALUES[beaconTypeBinary];
  let tacRlsNoBinary = beaconBinary.substring(CONST.LP_RLS_TAC_TRL_NO_BITS.start, CONST.LP_RLS_TAC_TRL_NO_BITS.end);
  let tacRslLastDigit = parseInt(tacRlsNoBinary, 2);
  let tacRslNo = tacRslLastDigit.toString().padStart(3, "0");

  let tacNoPrefix;
  let addedFields = {};
  if (beaconType === "EPIRB") {
    tacNoPrefix = "1";
  } else if (beaconType === "ELT") {
    tacNoPrefix = "2";
  } else if (beaconType === "PLB") {
    tacNoPrefix = "3";
  }
  let actualTacNo = tacNoPrefix + tacRslNo;
  try {
    let tacResult = await tacDb_remote.get(actualTacNo);
    addedFields.beaconManufacturer = tacResult.manufacturer_name;
  } catch (error) {
    addedFields.tacError = "Tac not found";
  }

  let serialNumberBinary = beaconBinary.substring(CONST.LP_RLS__SN_BITS.start, CONST.LP_RLS__SN_BITS.end);
  let serialNumber = parseInt(serialNumberBinary, 2);
  let defaultData = beaconBinary.substring(CONST.LP_RLS_DEFAULT_DATA_BITS.start, CONST.LP_RLS_DEFAULT_DATA_BITS.end);

  if (defaultData !== CONST.LP_RLS_DEFAULT_DATA) {
    throw new Error("Location protocol error, wrong default data, got " + defaultData + " , expected " + CONST.LP_RLS_DEFAULT_DATA);
  }

  return {
    locationProtocol,
    beaconType,
    typeApprovalCertificate: actualTacNo,
    tacRlsNoBinary,
    serialNumber,
    defaultData,
    ...addedFields,
  };
}

async function decodeEltDt(beaconBinary, locationProtocolBinary) {
  let locationProtocol = CONST.LP_ELT_DT_VALUE;
  let beaconTypeBinary = beaconBinary.substring(CONST.LP_ELT_DT_BEACON_TYPE_BITS.start, CONST.LP_ELT_DT_BEACON_TYPE_BITS.end);
  console.log("log: decodeEltDt -> beaconTypeBinary", beaconTypeBinary);
  let beaconType = CONST.LP_ELT_DT_BEACON_TYPES[beaconTypeBinary];
  console.log("log: decodeEltDt -> beaconType", beaconType);

  let decodedData = {};
  if (beaconTypeBinary === CONST.LP_ELT_DT_AIRCRAFT_24BIT_VALUE) {
    let identificationData = beaconBinary.substring(CONST.LP_ELT_DT_AIRCRAFT_24BITS_ID_BITS.start, CONST.LP_ELT_DT_AIRCRAFT_24BITS_ID_BITS.end);
    let hexaIdentification = parseInt(identificationData, 2).toString(16);
    let decIdentification = parseInt(identificationData, 2);
    decodedData = {
      identificationData: "hex : " + hexaIdentification + " (decimal: " + decIdentification + ")",
    };
  } else if (beaconTypeBinary === CONST.LP_ELT_DT_AIRCRAFT_OPERATOR_VALUE) {
    let serialNumberBinary = beaconBinary.substring(CONST.LP_ELT_DT_AIRCRAFT_OPERATOR_SN.start, CONST.LP_ELT_DT_AIRCRAFT_OPERATOR_SN.end);
    let identificationData = beaconBinary.substring(CONST.LP_ELT_DT_AIRCRAFT_OPERATOR_24_BIT_ID_BITS.start, CONST.LP_ELT_DT_AIRCRAFT_OPERATOR_24_BIT_ID_BITS.end);
    decodedData = {
      identificationData: decodeBaudot(identificationData),
      serialNumber: parseInt(serialNumberBinary, 2),
    };
  } else if (beaconTypeBinary === CONST.LP_ELT_DT_TAC_WITH_SN_VALUE) {
    let serialNumberBinary = beaconBinary.substring(CONST.LP_ELT_DT_TAC_SN_NB_BITS.start, CONST.LP_ELT_DT_TAC_SN_NB_BITS.end);
    console.log("log: decodeEltDt -> serialNumberBinary", serialNumberBinary);
    let tacLastDigit = parseInt(beaconBinary.substring(CONST.LP_ELT_DT_TAC_SN_TYPE_APPROVAL_NB_BITS.start, CONST.LP_ELT_DT_TAC_SN_TYPE_APPROVAL_NB_BITS.end), 2);
    let tacRslNo = tacLastDigit.toString().padStart(3, "0");

    let tacNoPrefix = "2";

    let actualTacNo = tacNoPrefix + tacRslNo;
    try {
      let tacResult = await tacDb_remote.get(actualTacNo);
      addedFields.beaconManufacturer = tacResult.manufacturer_name;
    } catch (error) {
      addedFields.tacError = "Tac not found";
    }
    decodedData = {
      typeApprovalCertificate: actualTacNo,
      serialNumber: parseInt(serialNumberBinary, 2),
      ...addedFields,
    };
  } else if (beaconTypeBinary === CONST.LP_ELT_DT_ELT_TEST_VALUE) {
    decodedData = {
      identificationData: beaconBinary.substring(CONST.LP_ELT_DT_TEST_PROTOCOL_24BIT_ID.start, CONST.LP_ELT_DT_TEST_PROTOCOL_24BIT_ID.end),
    };
  }
  let defaultData = beaconBinary.substring(CONST.LP_RLS_DEFAULT_DATA_BITS.start, CONST.LP_RLS_DEFAULT_DATA_BITS.end);
  if (defaultData !== CONST.LP_RLS_DEFAULT_DATA) {
    throw new Error("Location protocol error, wrong default data, got " + defaultData + " , expected " + CONST.LP_RLS_DEFAULT_DATA);
  }

  return {
    locationProtocol,
    beaconType,
    defaultData,
    ...decodedData,
  };
}
