import * as CONST from "./beaconConstants";
import hexUtils from "./hexUtils";
import decodeUsingLocationProtocol from "./gen1LocationProtocol";
import decodeUsingUserProtocol from "./gen1UserProtocol";
import getCountryByCode from "./countryCode";
export default async function decodeGen1Hex(beaconBinary, tacDb_remote) {
  let protocolToDecode = getProtocolToDecode(beaconBinary);
  console.log("log: defaultfunctiondecodeGen1Hex -> protocolToDecode", protocolToDecode);
  let { countryCode, countryName } = hexUtils.getCountryCodeAndName(beaconBinary);
  let beaconInfo;

  if (protocolToDecode === CONST.LP_VALUE) {
    beaconInfo = await decodeUsingLocationProtocol(beaconBinary, tacDb_remote, countryCode);
  } else if (protocolToDecode === CONST.UP_VALUE) {
    beaconInfo = decodeUsingUserProtocol(beaconBinary, countryCode);
  }

  return {
    ...beaconInfo,
    countryName,
    beaconCountryCode: countryCode.toString(),
    protocolToDecode,
  };
}

function getProtocolToDecode(beaconBinary) {
  return parseInt(beaconBinary.substring(CONST.PROTOCOL_BITS.start, CONST.PROTOCOL_BITS.end), 10);
}
