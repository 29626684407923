import { toJS } from "mobx";
import AppConfigState from "../state/appConfigState";
import TacState from "../state/tacState";
import generateHash from 'random-hash';
import * as FileSaver from "file-saver";

export function sortBy(array,field){
	return array.slice().sort((a,b)=>{
		let timeStampFields = ['lastEditDate'];
		let dateTextFields = ['durationSpecificStartDate', 'durationSpecificEndDate'];

		if(timeStampFields.includes(field)) {
			var valueA = a[field] || 0
			var valueB = b[field] || 0
			return valueA-valueB;
		}
		else if(dateTextFields.includes(field)) {
			var valueA = new Date((a[field] || 0))
			var valueB = new Date((b[field] || 0))
			return valueA.getTime() - valueB.getTime();
		}
		else if(field == 'typeApprovalCertificate' || field == 'serialNumber') {
			var valueA = a[field] || 0
			var valueB = b[field] || 0
			return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
		}
		else if (field == 'arrivalLocation'){
			var valueA = formatLocation(a['arrivalLocation'], a['arrivalNearestTown'], a['arrivalState'], a['arrivalCountry']) || ''
			var valueB = formatLocation(b['arrivalLocation'], b['arrivalNearestTown'], b['arrivalState'], b['arrivalCountry']) || ''
			return valueA.localeCompare(valueB);
		}
		else if (field == 'beaconHolder'){
			var rawA = toJS(a['holderInfo']);
			var rawB = toJS(b['holderInfo']);
			var valueA = holderNameFormat(rawA) || '';
			var valueB = holderNameFormat(rawB) || '';
			return valueA.localeCompare(valueB);
		}
		else if (field == 'beaconHolderName'){
			var valueA = (a['firstName'] + ' ' + a['lastName']) || '';
			var valueB = (b['firstName'] + ' ' + b['lastName']) || '';
			return valueA.localeCompare(valueB);
		}
		else if (field == 'registrationCountry'){
			var valueA = countryCodeToText(a['registrationCountry']) || '';
			var valueB = countryCodeToText(b['registrationCountry']) || '';
			return valueA.localeCompare(valueB);
		}
		else if (field == 'roleId'){
			var valueA = roleIdToText(a['roleId']) || '';
			var valueB = roleIdToText(b['roleId']) || '';
			return valueA.localeCompare(valueB);
		}
		else if (field == 'countryNumber'){
			var valueA = countryNumberToText(a['countryNumber']) || '';
			var valueB = countryNumberToText(b['countryNumber']) || '';
			return valueA.localeCompare(valueB);
		}
		else if (['pOCIndex', 'poc', 'mcc', 'Mid', 'POCCrossRef', 'POCELT', 'POCEPIRB', 'POCPLB', 'POCSSAS', 'REGCrossRef', 'REGELT', 'REGEPIRB', 'REGPLB', 'REGSSAS', 'id'].includes(field)){ // convert to int then compare
			var valueA = parseInt(a[field]) || 0;
			var valueB = parseInt(b[field]) || 0;
			return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
		}
		else if(field == 'beacon_type_id'){
			var valueA = beaconTypeText(a['beacon_type_id']) || '';
			var valueB = beaconTypeText(b['beacon_type_id']) || '';
			return valueA.localeCompare(valueB);
		}
		else if(field == 'homer_frequency'){
			var valueA = homerFrequencyText(a['homer_frequency']) || '';
			var valueB = homerFrequencyText(b['homer_frequency']) || '';
			return valueA.localeCompare(valueB);
		}
		else if(field == 'country'){
			var valueA = countryText(a['country']) || '';
			var valueB = countryText(b['country']) || '';
			return valueA.localeCompare(valueB);
		}
		else {
			var valueA = a[field] || ''
			var valueB = b[field] || ''
			return valueA.localeCompare(valueB);
		}
	})
}

export function formatLocation(nearestLocation, city, province, country){
	var locationString = nearestLocation;
	if(city != ""){
		if(locationString != "") { locationString += ", "; }
		locationString += city;
	}
	if(province != ""){
		if(locationString != "") { locationString += ", "; }
		locationString += province;
	}
	if(country != ""){
		if(locationString != "") { locationString += ", "; }
		locationString += country;
	}
	return locationString;
}

function holderNameFormat(holderInfo){

	if(!holderInfo) return
	let fullName = (holderInfo.firstName + ' ' || '') + (holderInfo.lastName || '')
	return fullName;
}

function countryCodeToText(countryCode){
	if(!countryCode) return
	let countryInfo = AppConfigState.countryNamesWithCode.find(row => row.key == countryCode)
	return countryInfo.text
}

function roleIdToText(roleId){
	if(!roleId) return
	let roleInfo = AppConfigState.roleId.find(row => row.key == roleId)
	return roleInfo.text
}

function countryNumberToText(countryArray){
    if(!countryArray) return
    if(countryArray.length <= 0) return
    let intersection = AppConfigState.countryNamesWithCode.filter(x => countryArray.includes(x.key));
    let newFormat = intersection.map(row => row.text)
    return newFormat.join(', ')
}

function beaconTypeText(id){
	if(!id) return
	let data = TacState.beaconTypeOption.find(row => row.value == id)
	return data ? data.text : ''
}

function homerFrequencyText(id){
	if(!id) return
	let data = TacState.homerFrequencyOption.find(row => row.value == id)
	return data ? data.text : ''
}
function countryText(id){
	if(!id) return
	let data = TacState.countryOption.find(row => row.value == id)
	return data ? data.text : ''
}

export function dateFormatWithTime(timestamp, displayTime=false){
    if (!timestamp) return;
    let newDate = new Date(timestamp);
    let year = newDate.getFullYear();
	let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
	let date = ("0" + newDate.getDate()).slice(-2);
	let hours = ("0" + newDate.getHours()).slice(-2);
	let minutes = ("0" + newDate.getMinutes()).slice(-2);
    let seconds = ("0" + newDate.getSeconds()).slice(-2);

    if(displayTime){
        return (year + "/" + month + "/" + date + " " + hours + ":" + minutes + ":" + seconds);
    }
    else{
        return (year + "/" + month + "/" + date);
    }
}

// ===============
// TAC Export
// ===============
export function exportTAC(itemsToExport) {

	let allItems = itemsToExport.map((row) => {
	  let newFormat = {
		fullReport: "report",
		tac_number: row.tac_number || "",
		name: row.name || "",
		manufacturer: row.manufacturer || "",
		beacon_type_id: convertBeaconIdToText(row.beacon_type_id) || "",
		production_status: convertInProd(row.production_status) || "",
		tac_last_revision_date: row.tac_last_revision_date || "",
		original_tac_issue_date: row.original_tac_issue_date || "",
		classes: row.classes || "",
		self_test_duration: row.self_test_duration || "",
		beacon_frequency_channel: convertIdToText(row.beacon_frequency_channel, TacState.beaconFreqencyOption) || "",
		nav_device: row.nav_device || "",
	  };

	  return newFormat;
	});

	let exportDate = new Date();
	let year = exportDate.getFullYear();
	let month = ("0" + (exportDate.getMonth() + 1)).slice(-2);
	let date = ("0" + exportDate.getDate()).slice(-2);

	let csv = convertToCSV(allItems, ",");
	let blob = new Blob([csv], { type: "text/csv;charset=ANSI" });
	FileSaver.saveAs(blob, "export_" + year + '' + month + '' + date + '_' + generateHash({ length: 16 }) + ".csv");
}

function convertToCSV(objArray, delemiter = ",") {
	//================ Add header
	var headersText = {
		fullReport: "Full report",
		tac_number: "C/S TAC No.",
		name: "Beacon Model Name",
		manufacturer: "Manufacturer",
		beacon_type_id: "Beacon type",
		production_status: "In Prod.",
		tac_last_revision_date: "Last Rev. Date",
		original_tac_issue_date: "Issue date",
		classes: "class",
		self_test_duration: "duration",
		beacon_frequency_channel: "transmit frequency",
		nav_device: "nav int/ext",
	};
	var headers = {};
	for ( const [key, value] of Object.entries(headersText) ) {
		var item = { [key]: value };
		headers = { ...headers, ...item };
	}
	objArray.unshift(headers);
	//================

	var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
	var str = "";
	for (var i = 0; i < array.length; i++) {
	  var line = "";
	  for (var index in array[i]) {
		if (line != "") line += delemiter;
		if (delemiter == ",") {
		  line += array[i][index] === undefined ? "" : '"=""' + array[i][index] + '"""';
		} else {
		  line += array[i][index] === undefined ? '""' : '"' + array[i][index] + '"';
		}
	  }
	  str += line + "\r\n";
	}
	return str;
}

function convertBeaconIdToText(id){
    if(!id || id == undefined) return;
    let item = TacState.beaconTypeOption.find(entry => entry.value == id);
    return item ? item.text : '';
}

function convertInProd(id){
    if(!id) return

    switch(id){
        case '0': return 'no';
        case '1': return 'yes';
        default: 'no'
    }
}

export function convertIdToText(id, dataSet){
	if(!id || id == undefined) return;
    let item = dataSet.find(entry => entry.value == id);
    return item ? item.text : '';
}