import * as CONST from './beaconConstants';
import decodeBaudot from './baudot';

export default function decodeUsingUserProtocol(beaconBinary, countryCode) { 
  let userProtocolBinary = beaconBinary.substring(CONST.UP_USER_PROTOCOL_BITS.start, CONST.UP_USER_PROTOCOL_BITS.end);
  let decodedData;

  if (userProtocolBinary === CONST.UP_SERIAL_USER_PROTOCOL) {
    decodedData = decodeSerialUserProtocol(beaconBinary);
  } else if (userProtocolBinary === CONST.UP_MMSI_OR_RADIO_CALL_SIGN) {
    decodedData = decodeMmsiOrRadioCallSign(beaconBinary, countryCode);
  } else if (userProtocolBinary === CONST.UP_RADIO_CALL_SIGN) {
    decodedData = decodeRadioCallSign(beaconBinary);
  } else if (userProtocolBinary === CONST.UP_AVIATION_USER_AIRCRAFT) {
    decodedData = decodeAvbiationUserAircraft(beaconBinary);
  } else if (userProtocolBinary === CONST.UP_ORBITOGRAPHY_PROTOCOL){
    decodedData = {beaconType:"Test : Orbitography Protocol"}
  }

  return {
    protocol: 'User',
    userProtocolBinary,
    ...decodedData };

}

function decodeSerialUserProtocol(beaconBinary) {
 
  let beaconTypeBinary = beaconBinary.substring(CONST.UP_BEACON_TYPE_BITS.start, CONST.UP_BEACON_TYPE_BITS.end);
  let beaconType, serialNumberBinary, serialNumber, nationalUserBinary, typeApprovalCert, typeApprovalCertBinary;
  let eltNumber, operatorDesignatorBinary, operatorDesignator, address24bits;

  let typeApprovalFlag = beaconBinary.substring(CONST.UP_FLAG_FOR_TYPE_APPROVAL_BITS.start, CONST.UP_FLAG_FOR_TYPE_APPROVAL_BITS.end);

  if (typeApprovalFlag === CONST.UP_FLAG_VALUE_TA_CERT) {
    typeApprovalCertBinary = beaconBinary.substring(CONST.UP_TYPE_APPROVAL_BITS.start, CONST.UP_TYPE_APPROVAL_BITS.end);
    typeApprovalCert = parseInt(typeApprovalCertBinary, 2);
  } else {
    nationalUserBinary = beaconBinary.substring(CONST.UP_TYPE_APPROVAL_BITS.start, CONST.UP_TYPE_APPROVAL_BITS.end);
  }

  let auxDeviceBinary = beaconBinary.substring(CONST.UP_AUX_DEVICE_BITS.start, CONST.UP_AUX_DEVICE_BITS.end);
  let auxDevice = CONST.UP_AUX_DEVICE_VALUE[auxDeviceBinary];

  if (CONST.UP_BEACON_TYPE.hasOwnProperty(beaconTypeBinary)) {
    beaconType = CONST.UP_BEACON_TYPE[beaconTypeBinary];
    serialNumberBinary = beaconBinary.substring(CONST.UP_SN_BITS.start, CONST.UP_SN_BITS.end);
    serialNumber = parseInt(serialNumberBinary, 2).toString();
    if (typeApprovalFlag === CONST.UP_FLAG_VALUE_TA_CERT) {
      nationalUserBinary = beaconBinary.substring(CONST.UP_NATIONAL_USER_BITS.start, CONST.UP_NATIONAL_USER_BITS.end);
    }
  } else if (beaconTypeBinary === CONST.UP_BEACON_TYPE_ELT_24bit_AIRCRAFT) {
    beaconType = CONST.UP_BEACON_TYPE_AIRCRAFT_ELT_24BITS;
    let address24bitsBinary = beaconBinary.substring(CONST.UP_AIRCRAFT_24BITS_ADDRESS_BITS.start, CONST.UP_AIRCRAFT_24BITS_ADDRESS_BITS.end);
    address24bits = parseInt(address24bitsBinary, 2).toString(16).toUpperCase().padStart(6, '0');
    eltNumber = beaconBinary.substring(CONST.UP_AIRCRAFT_ELT_NUMBER_BITS.start, CONST.UP_AIRCRAFT_ELT_NUMBER_BITS.end);
  } else if (beaconTypeBinary === CONST.UP_BEACON_TYPE_ELT_WITH_OPERATOR) {
    beaconType = CONST.UP_BEACON_ELT_AIRCRAFT_WITH_OPERATOR;
    operatorDesignatorBinary = beaconBinary.substring(CONST.UP_ELT_AIRCRAFT_OPERATOR_DESIGNATOR_BITS.start, CONST.UP_ELT_AIRCRAFT_OPERATOR_DESIGNATOR_BITS.end);
    operatorDesignator = decodeBaudot(operatorDesignatorBinary);
    serialNumberBinary = beaconBinary.substring(CONST.UP_ELT_AIRCRAFT_OPERATOR_DESIGNATOR_BITS.start, CONST.UP_ELT_AIRCRAFT_OPERATOR_DESIGNATOR_BITS.end);
    serialNumber = parseInt(serialNumberBinary, 2).toString();
  }

  typeApprovalFlag = beaconBinary.substring(CONST.UP_FLAG_FOR_TYPE_APPROVAL_BITS.start, CONST.UP_FLAG_FOR_TYPE_APPROVAL_BITS.end);

  return {
    beaconType,
    typeApprovalFlag,
    serialNumber,
    typeApprovalCert,
    auxDevice,
    eltNumber, //TODO find out if this one should stay binary. 
    aircraft24BitAddress:address24bits,
    aircraft24BitAddressDecoded:address24bits,
    operatorDesignator };

}

function decodeMmsiOrRadioCallSign(beaconBinary, countryCode) {
  let beaconTypeBinary = beaconBinary.substring(CONST.UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_TYPE_BITS.start, CONST.UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_TYPE_BITS.end);
  let MMSIOrRadiocalSign = decodeBaudot(beaconTypeBinary);
  
  let MMSIDecoded = ""
  let callSign = "";
  let isnum = /^\d+$/.test(MMSIOrRadiocalSign);
  if(isnum){
    MMSIDecoded = countryCode + MMSIOrRadiocalSign.padStart(6, '0');
  } else {
    callSign = MMSIOrRadiocalSign.toUpperCase();
  }
  let beaconType = CONST.UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_TYPE;
  let beaconNumberBinary = beaconBinary.substring(CONST.UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_NUMBER.start, CONST.UP_MMSI_OR_RADIO_CALL_SIGN_BEACON_NUMBER.end);
  let beaconNumber = decodeBaudot(beaconNumberBinary);
  let beaconSpare = beaconBinary.substring(CONST.UP_MMSI_OR_RADIO_CALL_SIGN_SPARE.start, CONST.UP_MMSI_OR_RADIO_CALL_SIGN_SPARE.end);
  let auxDeviceBinary = beaconBinary.substring(CONST.UP_AUX_DEVICE_BITS.start, CONST.UP_AUX_DEVICE_BITS.end);
  let auxDevice = CONST.UP_AUX_DEVICE_VALUE[auxDeviceBinary];

  return {
    beaconType,
    beaconNumber,
    beaconSpare,
    MMSIDecoded,
    MMSI:MMSIDecoded,
    callSign,
    callSignDecoded:callSign,
    auxDevice };

}

function decodeRadioCallSign(beaconBinary) {//TODO UPDATE LAMBDA
  let beaconType = CONST.UP_RADIO_CALL_BEACON_TYPE;
  let firstCharBinary = beaconBinary.substring(CONST.UP_RADIO_CALL_FIRST_CHARACTERS_BITS.start, CONST.UP_RADIO_CALL_FIRST_CHARACTERS_BITS.end);
  let firstChar = decodeBaudot(firstCharBinary).toUpperCase();
  let lastCharbinary = beaconBinary.substring(CONST.UP_RADIO_CALL_LAST_CHARACTERS_BITS.start, CONST.UP_RADIO_CALL_LAST_CHARACTERS_BITS.end);
  let lastChar = parseInt(lastCharbinary, 2).toString(16).toUpperCase();
  let beaconNumberBinary = beaconBinary.substring(CONST.UP_RADIO_CALL_BEACON_NUMBER_BITS.start, CONST.UP_RADIO_CALL_BEACON_NUMBER_BITS.end);
  let beaconNumber = decodeBaudot(beaconNumberBinary);

  return {
    beaconType,
    callSign:firstChar + lastChar,
    callSignDecoded:firstChar + lastChar,
    beaconNumber 
    };

}

function decodeAvbiationUserAircraft(beaconBinary) {
  let beaconType = CONST.UP_AVIATION_USER_AIRCRAFT_VALUE;
  let registrationMarkingBinary = beaconBinary.substring(CONST.UP_AVIATION_USER_AIRCRAFT_MARKING_BITS.start, CONST.UP_AVIATION_USER_AIRCRAFT_MARKING_BITS.end);
  let registrationMarking = decodeBaudot(registrationMarkingBinary);
  let beaconNumberBinary = beaconBinary.substring(CONST.UP_AVIATION_USER_AIRCRAFT_BEACON_NUMBER_BITS.start, CONST.UP_AVIATION_USER_AIRCRAFT_BEACON_NUMBER_BITS.end);
  let beaconNumber = decodeBaudot(beaconNumberBinary);
  let auxDeviceBinary = beaconBinary.substring(CONST.UP_AUX_DEVICE_BITS.start, CONST.UP_AUX_DEVICE_BITS.end);
  let auxDevice = CONST.UP_AUX_DEVICE_VALUE[auxDeviceBinary];

  return {
    beaconType,
    registrationMarking,
    beaconNumber,
    auxDeviceBinary,
    auxDevice };



}