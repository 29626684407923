import React, { Component } from 'react';
import { Container, Icon, Step, Divider } from 'semantic-ui-react'
import RegistrationState from '../../../state/registrationState';

import OwnerEmergencyContactForm from './ownerEmergencyContactForm';
import OwnerDetailForm from './ownerDetailForm';

import VehiculeELTDetailForm from './vehiculeELTDetailForm';
import VehiculeELTPhysicalDescriptionForm from './vehiculeELTPhysicalDescriptionForm';
import VehiculeELTCommentsForm from './vehiculeELTCommentsForm';

import VehiculePLBDetailForm from './vehiculePLBdetailForm';

import VehiculeEPIRBDefailForm from './vehiculeEPIRBDetailForm';
import VehiculeEPIRBPhysicalDescriptionForm from './vehiculeEPIRBPhysicalDescriptionForm';
import VehiculeEPIRBCommentsForm from './vehiculeEPIRBCommentsForm';

import { observer } from 'mobx-react';

@observer
export default class BeaconRegistrationStep extends Component {

	onSelectStep = (event, data) => {
		//console.log("Home: BeaconRegistrationStep -> onSelectStep -> data", data);
		//RegistrationState.setVehiculeStep(data.stepnumber)
	}

	render() {
		let {beacon, onClose} = this.props
		let currentStep = RegistrationState.ownerCurrentStep;

		return (
			<Container className='registerBeacon-subContainer animated fadeIn fast'>
				<VehiculeFormSelector beaconType={beacon.beaconType} beacon={beacon} onClose={onClose} />
			</Container>
		)
	}
}


const VehiculeFormSelector = ({ beaconType, beacon, onClose }) => {

	if(!beaconType) return null;

	if(beaconType.includes('ELT')){
		return <ELTVehiculeStep  beacon={beacon} onClose={onClose} />
	}
	else if(beaconType.includes('PLB')){
		return <PLBVehiculeStep  beacon={beacon} onClose={onClose} />
	}
	else if(beaconType.includes('EPIRB')){
		return <EPIRBVehiculeStep  beacon={beacon} onClose={onClose} />
	}
}

@observer
class ELTVehiculeStep extends Component {

	onSelectStep = (event, data) => {
		//console.log("Home: ELTVehiculeStep -> onSelectStep -> data", data);
		//RegistrationState.setVehiculeStep(data.stepnumber)
	}

	render() {
		let {beacon, onClose} = this.props
		let currentStep = RegistrationState.vehiculeCurrentStep;

		return (
			<>
				<CurrentEltStep currentStep={currentStep} beacon={beacon} onClose={onClose} />
			</>
		)
	}
}


class PLBVehiculeStep extends Component {

	render() {
		let {beacon, onClose} = this.props
		return (
			<>
				<VehiculePLBDetailForm  beacon={beacon} onClose={onClose} />
			</>
		)
	}
}

@observer
class EPIRBVehiculeStep extends Component {

	onSelectStep = (event, data) => {
		//console.log("Home: BeaconRegistrationStep -> EPIRBVehiculeStep -> data", data);
		//RegistrationState.setVehiculeStep(data.stepnumber)
	}

	render() {
		let {beacon, onClose} = this.props
		let currentStep = RegistrationState.vehiculeCurrentStep;

		return (
			<>
				{/* <Divider /> */}			
				<CurrentEpirbStep currentStep={currentStep} beacon={beacon} onClose={onClose} />
			</>
		)
	}
}


const EltEpirbVehiculeRegistrationStep = ({ currentStep, onSelectStep }) => (
	<Step.Group widths={3}>
		<Step active={currentStep === 0} stepnumber={0} >
			<Step.Content>
				<Step.Title>Vehicle Details</Step.Title>
			</Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={0} />
		</Step>

		{/* <Step active={currentStep === 1} stepnumber={1} >
			<Step.Content>
				<Step.Title>Physical Description</Step.Title>
			</Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={1} />
		</Step>

		<Step active={currentStep === 2} stepnumber={2} >
			<Step.Content>
				<Step.Title>Comments and Images</Step.Title>
			</Step.Content>
			<StepComplete currentStep={currentStep} stepnumber={2} />
		</Step> */}

	</Step.Group>
)

const CurrentEltStep = ({ currentStep,beacon, onClose }) => {
	switch (currentStep) {
		case 0:
			return <VehiculeELTDetailForm decodedBeacon={beacon} onClose={onClose} />
			break;
		case 1:
			return <VehiculeELTPhysicalDescriptionForm decodedBeacon={beacon} onClose={onClose} />
			break;
		case 2:
			return <VehiculeELTCommentsForm decodedBeacon={beacon} onClose={onClose} />
			break;
		default:
			break;
	}
}

const CurrentEpirbStep = ({ currentStep, beacon, onClose }) => {
	switch (currentStep) {
		case 0:
			return <VehiculeEPIRBDefailForm decodedBeacon={beacon} onClose={onClose} />
			break;
		case 1:
			return <VehiculeEPIRBPhysicalDescriptionForm  decodedBeacon={beacon} onClose={onClose} />
			break;
		case 2:
			return <VehiculeEPIRBCommentsForm decodedBeacon={beacon} onClose={onClose} />
			break;
		default:
			break;
	}
}

const StepComplete = ({ currentStep, stepnumber }) => {
  if (currentStep > stepnumber && RegistrationState.isNewRegisterBeacon) {
    return <Icon name="check" color="green" className="registerBeacon-stepComplete" />;
  } else {
    return null;
  }
};

