import i18n from "i18next";
import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Input, Button, Popup, Select, Header, Grid, Message, Transition, Icon } from "semantic-ui-react";
import RegistrationState from "../../../state/registrationState";
import AppConfigState from "../../../state/appConfigState";
import withSemanticUIFormik from "../../../utils/formHelper";
import UserState from "../../../state/userState";
import { FormErrorDisplay } from "../../../components/formErrorDisplay";
import ImageUploader from "../../../components/imageUpload";

// const radioEquipmentOption = [
//   { key: "HF", text: "HF", value: "HF" },
//   { key: "MF", text: "MF", value: "MF" },
//   { key: "SSB", text: "SSB", value: "SSB" },
//   { key: "VHF", text: "VHF", value: "VHF" },
//   { key: "Other", text: "Other", value: "Other" },
// ];

class InnerForm extends Component {
  componentDidMount() {
    if (!RegistrationState.isNewRegisterBeacon) {
      var topPosition = document.getElementById("editBeaconModal");
      topPosition.scrollIntoView();
    } else {
      window.scrollTo({ top: 0 });
    }
  }

  componentWillUnmount() {
    if (!RegistrationState.isNewRegisterBeacon) {
      console.log("log: InnerForm -> componentWillUnmount -> RegistrationState.isNewRegisterBeacon", RegistrationState.isNewRegisterBeacon);
      //RegistrationState.updateBeaconFields(this.props.values);
    }
  }

  saveAndClose = () => {
    if (!RegistrationState.isNewRegisterBeacon) {
      RegistrationState.setIsSaving(true);
      RegistrationState.updateBeaconFields(this.props.values);
      RegistrationState.updateBeacon().then(() => {
        RegistrationState.setIsBeaconSaved(true);
        this.props.onClose();
        RegistrationState.setIsSaving(false);
      });
    }
  };

  // handle aircraft24BitAddress
  handleChange24BitAddress = (e, data) => {
    if (data && data.name && data.value.length <= 6) {
      this.props.setFieldValue(data.name, data.value);
    }
  };

  render() {
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, isValid } = this.props;

    let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
    let vehicleRegistrationNumberError = touched.vehicleRegistrationNumber && errors.vehicleRegistrationNumber && errors.vehicleRegistrationNumber.length > 0;
    let vehicleTypeError = touched.vehicleType && errors.vehicleType && errors.vehicleType.length > 0;

    let lengthHasError = touched.length && errors.length && errors.length.length > 0;
    let peopleCapacityHasError = touched.peopleCapacity && errors.peopleCapacity && errors.peopleCapacity.length > 0;
    let maxEnduranceHasError = touched.maxEndurance && errors.maxEndurance && errors.maxEndurance.length > 0;
    let cruiseAirSpeedHasError = touched.cruiseAirSpeed && errors.cruiseAirSpeed && errors.cruiseAirSpeed.length > 0;
    let wingSpanHasError = touched.wingSpan && errors.wingSpan && errors.wingSpan.length > 0;
    let aircraft24BitAddressHasError = touched.aircraft24BitAddress && errors.aircraft24BitAddress && errors.aircraft24BitAddress.length > 0;

    let radioEquipmentOption = AppConfigState.radioEquipmentOption;
    let countryCodeOptions = AppConfigState.countryNamesWithCode.sort((a, b) => a.text.localeCompare(b.text));
    const vehicleTypeOption = AppConfigState.vehiculeTypeELT;
    let canEditByStatus =
      this.props.decodedBeacon.specialStatus == "ACTIVE" || this.props.decodedBeacon.specialStatus == undefined || this.props.decodedBeacon.specialStatus == "RELEASETOMAINTENANCE";

    if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId)) {
      canEditByStatus = false;
    }

    let showDecodedMismatchWarning = false;
    let inputWarning = null;
    if (values.aircraft24BitAddress !== values.aircraft24BitAddressDecoded) {
      showDecodedMismatchWarning = true;
      inputWarning = (
        <Popup
          content={i18n.t('information-entered-does-not-match-the-information-decoded-from-your-uin-please-contact-your-beacon-manufacturer-or-place-of-purchase-to-resolve')}
          trigger={<Icon name="warning" color="yellow" inverted circular link />}
        />
      );
    }

    // scroll to error
    if (isSubmitting && Object.keys(errors).length > 0) {
      var errorBox = document.getElementById("scrollToError");
      errorBox.scrollIntoView();
    }

    const saveToStateBeforeHandleChange = function(e, data) {
      console.log("log: InnerForm -> saveToStateBeforeHandleChange -> name, value", data.name, data.value);
      RegistrationState.saveOnFieldChange(data.name, data.value);
      handleChange(e, data);
    };

    return (
      <Form id="mainForm" className="beaconLocation-form animated fadeIn faster">
        <Header as="h2" icon textAlign="center">
          <Header.Content>{i18n.t('please-supply-additional-information-about-your-vehicle-if-applicable')}</Header.Content>
        </Header>
        <div id="scrollToError" />
        <FormErrorDisplay errors={errors} touched={touched} status={status} />
        {/* <DecodedMismatchWarning show={showDecodedMismatchWarning} /> */}
        <div className="required-field-notation">* required fields</div>

        <Form.Group widths="equal">
          <Form.Field>
            <Form.Select
              fluid
              label="Vehicle Type"
              options={vehicleTypeOption}
              placeholder="Select"
              name="vehicleType"
              value={values.vehicleType}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              required
              error={vehicleTypeError}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
            />

            {values.vehicleType == "Other" ? (
              <Form.Input
                fluid
                placeholder=""
                name="vehicleTypeOther"
                value={values.vehicleTypeOther}
                onChange={saveToStateBeforeHandleChange}
                onBlur={handleBlur}
                disabled={!canEditByStatus}
                className={canEditByStatus ? "editing-input" : ""}
              />
            ) : (
              (values.vehicleTypeOther = "")
            )}
          </Form.Field>

          <Form.Field>
            <label>
              {i18n.t('vehicle-registration-number')} <span style={{ color: "red" }}>*</span>
            </label>
            <Form.Input
              fluid
              placeholder=""
              name="vehicleRegistrationNumber"
              value={values.vehicleRegistrationNumber}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              required
              error={vehicleRegistrationNumberError}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-input" : ""}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={i18n.t('capacity-crew-and-passengers')}
            placeholder=""
            type="number"
            min="0"
            name="peopleCapacity"
            value={values.peopleCapacity}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
            error={peopleCapacityHasError}
          />

          <Form.Field>
            <label>
              {i18n.t('aircraft-24-bit-address')}
              <Popup
                content={i18n.t('24-bit-address-of-the-aircraft-expressed-as-6-hexadecimal-0-9-a-f-characters')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Input
              icon={inputWarning}
              placeholder={i18n.t('aircraft-24-bit-address')}
              name="aircraft24BitAddress"
              value={values.aircraft24BitAddress}
              onChange={this.handleChange24BitAddress}
              onBlur={handleBlur}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-input" : ""}
              error={aircraft24BitAddressHasError}
            />
          </Form.Field>

          <Form.Input
            fluid
            label={i18n.t('aircraft-24-bit-address-decoded')}
            placeholder=""
            name="aircraft24BitAddressDecoded"
            value={values.aircraft24BitAddressDecoded}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={true}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label="Home ICAO Code"
            placeholder=""
            name="homeICAOCode"
            value={values.homeICAOCode}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />

          <Form.Field>
            <label>
              Aircraft Manufacturer
              <Popup
                content={i18n.t('name-of-the-manufacturer-for-the-aircraft')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
              <Form.Input
                fluid
                label=""
                placeholder=""
                name="aircraftManufacturer"
                value={values.aircraftManufacturer}
                onChange={saveToStateBeforeHandleChange}
                onBlur={handleBlur}
                disabled={!canEditByStatus}
                className={canEditByStatus ? "editing-input" : ""}
              />
            </label>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={i18n.t('aircraft-model')}
            placeholder=""
            name="aircraftModel"
            value={values.aircraftModel}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
          <Form.Input
            fluid
            label={i18n.t('aircraft-colour')}
            placeholder=""
            name="aircraftColor"
            value={values.aircraftColor}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={i18n.t('aircraft-operating-agency')}
            placeholder=""
            name="aircraftOperatingAgency"
            value={values.aircraftOperatingAgency}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
          <Form.Input
            fluid
            label={i18n.t('aircraft-operating-agency-phone')}
            placeholder=""
            name="aircraftOperatingAgencyPhone"
            value={values.aircraftOperatingAgencyPhone}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {i18n.t('radio-equipment')}
              <Popup
                content={i18n.t('type-s-of-radio-equipment-present-on-the-vessel-check-any-and-all-that-apply-if-other-is-selected-enter-a-description-in-the-box-below')}
                trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
              />
            </label>
            <Form.Select
              multiple
              name="radioEquipment"
              fluid
              options={radioEquipmentOption}
              placeholder={i18n.t('select')}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              value={values.radioEquipment}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-select" : ""}
            />

            {values.radioEquipment && values.radioEquipment.includes("Other") ? (
              <Form.Input
                fluid
                placeholder=""
                name="radioEquipmentOther"
                value={values.radioEquipmentOther}
                onChange={saveToStateBeforeHandleChange}
                onBlur={handleBlur}
                disabled={!canEditByStatus}
                className={canEditByStatus ? "editing-input" : ""}
              />
            ) : (
              (values.radioEquipmentOther = "")
            )}
          </Form.Field>
        </Form.Group>

        {/* 2nd tab */}
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={i18n.t('deployable-survival-crafts-equipment')}
            placeholder=""
            name="deployableSurvivalCrafts"
            value={values.deployableSurvivalCrafts}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={i18n.t('fixed-survival-crafts-equipment')}
            placeholder=""
            name="fixedSurvivalCrafts"
            value={values.fixedSurvivalCrafts}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={i18n.t('max-endurance-h')}
            placeholder=""
            name="maxEndurance"
            value={values.maxEndurance}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            type="number"
            min="0"
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
            error={maxEnduranceHasError}
          />
          <Form.Input
            fluid
            label={i18n.t('cruise-air-speed-kt')}
            placeholder=""
            name="cruiseAirSpeed"
            value={values.cruiseAirSpeed}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            type="number"
            min="0"
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
            error={cruiseAirSpeedHasError}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>
              Length Overall (m)
              <Popup content={i18n.t('overall-length-of-vessel-in-metres')} trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />} />
            </label>
            <Form.Input
              fluid
              placeholder=""
              type="number"
              min="0"
              name="length"
              value={values.length}
              onChange={saveToStateBeforeHandleChange}
              onBlur={handleBlur}
              disabled={!canEditByStatus}
              className={canEditByStatus ? "editing-input" : ""}
              error={lengthHasError}
            />
          </Form.Field>

          <Form.Input
            fluid
            label={i18n.t('wing-span-m')}
            placeholder=""
            type="number"
            min="0"
            name="wingSpan"
            value={values.wingSpan}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
            error={wingSpanHasError}
          />
        </Form.Group>

        <Form.Field>
          <label>
            {i18n.t('aircraft-nationality')}
            <Popup
              content={i18n.t('mid-country-code-for-flag-state-or-aircraft-nationality-of-registration')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.Select
            search
            fluid
            options={countryCodeOptions}
            placeholder="Select"
            name="vehicleNationality"
            value={values.vehicleNationality}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-select" : ""}
          />
        </Form.Field>

        {/* 3rd tab */}
        <div>
          <label>
            {i18n.t('additional-vehicle-usage-information')}
            <Popup
              content={i18n.t('additional-information-deemed-appropriate-or-helpful-to-search-and-rescue-personnel-regarding-general-beacon-usage')}
              trigger={<Button type="button" circular icon="question" primary size="mini" className="home-helpButton" />}
            />
          </label>
          <Form.TextArea
            placeholder=""
            name="additionalComment"
            value={values.additionalComment}
            onChange={saveToStateBeforeHandleChange}
            onBlur={handleBlur}
            disabled={!canEditByStatus}
            className={canEditByStatus ? "editing-input" : ""}
          />
        </div>

        <Form.Group widths="equal" style={{ "margin-top": "1em" }} >
          <Form.Field>
            <ImageUploader name="picture1" disabled={!canEditByStatus} value={values.picture1} onChange={saveToStateBeforeHandleChange} />
          </Form.Field>
          <Form.Field>
            <ImageUploader name="picture2" disabled={!canEditByStatus} value={values.picture2} onChange={saveToStateBeforeHandleChange} />
          </Form.Field>
        </Form.Group>

        {/* <Grid centered columns={3} stackable>
          <Grid.Column>
            <Button className="registerBeacon-submitButton" onClick={onBackClick}>
              Back
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
              Next
            </Button>
          </Grid.Column>
        </Grid> */}
        <div className="activityDetail-submitContainer">
          <Button className="registerBeacon-submitButton" onClick={onBackClick}>
            {i18n.t('back')}
          </Button>
          <Button className="registerBeacon-submitButton" type="submit" primary onClick={handleSubmit}>
            {i18n.t('next')}
          </Button>
        </div>
      </Form>
    );
  }
}

function onBackClick() {
  RegistrationState.setVehiculePreviousStep();
}

const DecodedMismatchWarning = ({ show }) => {
  if (show) {
    return (
      <Transition.Group animation="fade down" duration={500}>
        <Message warning>
          <Message.Header>{i18n.t('warning-0')}</Message.Header>
          <Message.List>
            {i18n.t('information-entered-does-not-match-the-information-decoded-from-your-uin-please-contact-your-beacon-manufacturer-or-place-of-purchase-to-resolve')}
          </Message.List>
        </Message>
      </Transition.Group>
    );
  } else {
    return null;
  }
};

const beaconLocationForm = withSemanticUIFormik({
  mapPropsToValues: ({ decodedBeacon }) => {
    if (!decodedBeacon) {
      decodedBeacon = {};
    }
    console.log("ELT: decodedBeacon", decodedBeacon);

    let radioEquipment;
    if (decodedBeacon.radioEquipment) {
      radioEquipment = typeof decodedBeacon.radioEquipment == "string" ? decodedBeacon.radioEquipment.split(",") : decodedBeacon.radioEquipment;
    } else {
      radioEquipment = [];
    }

    return {
      vehicleType: decodedBeacon.vehicleType || "",
      vehicleRegistrationNumber: decodedBeacon.vehicleRegistrationNumber || "",
      homeICAOCode: decodedBeacon.homeICAOCode || "",
      aircraftManufacturer: decodedBeacon.aircraftManufacturer || "",
      aircraftModel: decodedBeacon.aircraftModel || "",
      aircraftColor: decodedBeacon.aircraftColor || "",
      aircraftOperatingAgency: decodedBeacon.aircraftOperatingAgency || "",
      aircraftOperatingAgencyPhone: decodedBeacon.aircraftOperatingAgencyPhone || "",
      radioEquipment: radioEquipment || "",
      vehicleTypeOther: decodedBeacon.vehicleTypeOther || "",
      radioEquipmentOther: decodedBeacon.radioEquipmentOther || "",

      deployableSurvivalCrafts: decodedBeacon.deployableSurvivalCrafts || "",
      fixedSurvivalCrafts: decodedBeacon.fixedSurvivalCrafts || "",
      maxEndurance: decodedBeacon.maxEndurance || "",
      cruiseAirSpeed: decodedBeacon.cruiseAirSpeed || "",
      length: decodedBeacon.length || "",
      wingSpan: decodedBeacon.wingSpan || "",
      peopleCapacity: decodedBeacon.peopleCapacity || "",
      aircraft24BitAddress: decodedBeacon.aircraft24BitAddress || "",
      aircraft24BitAddressDecoded: decodedBeacon.aircraft24BitAddressDecoded || "",
      vehicleNationality: decodedBeacon.vehicleNationality || "",

      additionalComment: decodedBeacon.additionalComment || "",
      picture1: decodedBeacon.picture1 || "",
      picture2: decodedBeacon.picture2 || "",
    };
  },
  validationSchema: Yup.object().shape({
    vehicleType: Yup.string().required(i18n.t('vehicle-type-is-required')),
    vehicleRegistrationNumber: Yup.string().required(i18n.t('vehicle-registration-number-is-required')),

    maxEndurance: Yup.number().min(0, i18n.t('length-overall-m-must-be-greater-than-or-equal-to-0')),
    cruiseAirSpeed: Yup.number().min(0, i18n.t('length-overall-m-must-be-greater-than-or-equal-to-0')),
    length: Yup.number().min(0, i18n.t('length-overall-m-must-be-greater-than-or-equal-to-0')),
    wingSpan: Yup.number().min(0, i18n.t('length-overall-m-must-be-greater-than-or-equal-to-0')),
    peopleCapacity: Yup.number().min(0, i18n.t('capacity-crew-and-passengers-must-be-greater-than-or-equal-to-0')),

    aircraft24BitAddressDecoded: Yup.string().notRequired(),
    aircraft24BitAddress: Yup.string()
      .notRequired()
      .matches(/^[0-9A-Fa-f]{1,64}$/, {
        message: i18n.t('aircraft-24-bit-address-must-be-valid-6-hexadecimal-characters-a-f-0-9'),
      })
      .test("len", i18n.t('aircraft-24-bit-address-must-be-valid-6-hexadecimal-characters-a-f-0-9'), function(val) {
        if ((val && val.length === 6) || !val) {
          return true;
        } else {
          return false;
        }
      }),
  }),

  handleSubmit: (values, { setSubmitting, isValid }) => {
    console.log("log: values", values, isValid);

    let newEquipmentFormat;
    if (values.radioEquipment && typeof values.radioEquipment == "object") {
      newEquipmentFormat = values.radioEquipment.join();
    }
    values.radioEquipment = newEquipmentFormat;

    RegistrationState.updateBeaconFields(values);
    RegistrationState.setVehiculeNextStep();
  },
})(InnerForm);

export default beaconLocationForm;
