import React, { Component } from "react";
import { Segment, Header, Icon, Button, Image } from "semantic-ui-react";
import AppConfigState from "../state/appConfigState";
import { observer } from "mobx-react";
import { action, observable } from "mobx";

@observer
export default class SpecialMessage extends Component {
  @observable content = "";

  componentDidMount() {
    setTimeout(() => {
      this.getSpecialMessage(this.props.pageKey);
    }, 2000);
  }

  @action getSpecialMessage(pageKey) {
    let data = AppConfigState.getPublishedPageContentWithCurrentLanguage(pageKey);
    let content = "";
    for (let i = 0; i < data.length; i++) {
      content += data[i].content;
    }
    this.content = content;
  }

  render() {
    const { pageKey, showPlaceholder } = this.props;
    console.log('log ~ file: specialMessage.js ~ line 28 ~ SpecialMessage ~ render ~ showPlaceholder', showPlaceholder);

    let content = (
      <Segment placeholder className={"special-message " + pageKey}>
        <div dangerouslySetInnerHTML={{ __html: this.content }} />
      </Segment>
    );

    if (!this.content && showPlaceholder) {
      content = (
        <Segment loading>
         <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
        </Segment>
      );
    } else if (!this.content && !showPlaceholder) {
      content = null;
    }

    return (
      <>
      {content}
      </>
    );
  }
}
