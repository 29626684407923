import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Header, Grid, Divider, Button, Icon, Table, GridRow, Image, Modal } from 'semantic-ui-react';
import AppConfigState from '../../../state/appConfigState';
import UserState from '../../../state/userState';
import DashboardState from '../../../state/dashboardState';
import * as Utils from "../../../utils/utils";
import i18n from "i18next";
@observer
export default class OwnerHistoryWidget extends Component {

    render() {
        const {title, ownerHistory} = this.props
        console.log('log: OwnerHistoryWidget -> render -> ownerHistory', ownerHistory);

        return(
            <>
                { (ownerHistory && ownerHistory.length > 0) && (<><Divider/><Header>{title}</Header></>) }
                {
                    (ownerHistory && ownerHistory.length > 0) &&
                    (
                        ownerHistory.map((row, index) => {

                            // let html =
                            // <Grid key={index} columns={2} className="beaconReviewDetails-grid" stackable >
                            //     <div><strong>#{index+1}</strong>: {Utils.dateFormatWithTime(row.lastEditDate, true) || ""}</div>
                            //     <Grid.Row columns={2}>
                            //         <Grid.Column>
                            //             <div>Account Owner Name: <strong>{row.ownerName || ''}</strong></div>
                            //             <div>Address: <strong>{row.address || ''}</strong></div>
                            //             <div>Zip/Postal Code: <strong>{row.mailCode || ''}</strong></div>
                            //             <div>City: <strong>{row.city || ''}</strong></div>
                            //             <div>State/Province: <strong>{row.province || ''}</strong></div>
                            //             <div>Country: <strong>{row.mailCountry || ''}</strong></div>
                            //             <div>Email: <strong>{row.emailAddress}</strong></div>
                            //             <div>Medical Information: <strong>{row.medicalInfo || ''}</strong></div>
                            //             <div>Communication Language: <strong>{row.operatorLanguage || ''}</strong></div>
                            //             {
                            //                 row.phone1Num && row.phone1Num != '' && (<div>Phone Number 1: <strong>{row.phone1Num || ''} {row.phone1Type ? '(' + phoneTypeToText(row.phone1Type, row.phone1TypeOther) + ')' : ''}</strong></div>)
                            //             }
                            //             {
                            //                 row.phone2Num && row.phone2Num != '' && (<div>Phone Number 2: <strong>{row.phone2Num || ''} {row.phone2Type ? '(' + phoneTypeToText(row.phone2Type, row.phone2TypeOther) + ')' : ''}</strong></div>)
                            //             }
                            //             {
                            //                 row.phone3Num && row.phone3Num != '' && (<div>Phone Number 3: <strong>{row.phone3Num || ''} {row.phone3Type ? '(' + phoneTypeToText(row.phone3Type, row.phone3TypeOther) + ')' : ''}</strong></div>)
                            //             }
                            //             {
                            //                 row.phone4Num && row.phone4Num != '' && (<div>Phone Number 4: <strong>{row.phone4Num || ''} {row.phone4Type ? '(' + phoneTypeToText(row.phone4Type, row.phone4TypeOther) + ')' : ''}</strong></div>)
                            //             }
                            //         </Grid.Column>

                            //         <Grid.Column>
                            //             <div>24-Hour Emergency Contact Name: <strong>{row.primaryContactName || ''}</strong></div>
                            //             <div>Emergency Contact Address Line 1: <strong>{row.primaryContactAddressLine1 || ''}</strong></div>
                            //             <div>Emergency Contact Address Line 2: <strong>{row.primaryContactAddressLine2 || ''}</strong></div>
                            //             {
                            //                 row.primaryPhone1Num && row.primaryPhone1Num != '' ? <div>Emergency Contact Phone Number 1: <strong>{row.primaryPhone1Num || ''} {row.primaryPhone1Type ? '(' + phoneTypeToText(row.primaryPhone1Type, row.primaryPhone1TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }
                            //             {
                            //                 row.primaryPhone2Num && row.primaryPhone2Num != '' ? <div>Emergency Contact Phone Number 2: <strong>{row.primaryPhone2Num || ''} {row.primaryPhone2Type ? '(' + phoneTypeToText(row.primaryPhone2Type, row.primaryPhone2TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }
                            //             {
                            //                 row.primaryPhone3Num && row.primaryPhone3Num != '' ? <div>Emergency Contact Phone Number 3: <strong>{row.primaryPhone3Num || ''} {row.primaryPhone3Type ? '(' + phoneTypeToText(row.primaryPhone3Type, row.primaryPhone3TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }
                            //             {
                            //                 row.primaryPhone4Num && row.primaryPhone4Num != '' ? <div>Emergency Contact Phone Number 4: <strong>{row.primaryPhone4Num || ''} {row.primaryPhone4Type ? '(' + phoneTypeToText(row.primaryPhone4Type, row.primaryPhone4TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }

                            //             <div>Alternate 24-Hour Emergency Contact Name: <strong>{row.alternateContactName || ''}</strong></div>
                            //             <div>Alternate Contact Address Line 1: <strong>{row.alternateContactAddressLine1 || ''}</strong></div>
                            //             <div>Alternate Contact Address Line 2: <strong>{row.alternateContactAddressLine2 || ''}</strong></div>
                            //             {
                            //                 row.alternatePhone1Num && row.alternatePhone1Num != '' ? <div>Alternate Contact Phone Number 1: <strong>{row.alternatePhone1Num || ''} {row.alternatePhone1Type ? '(' + phoneTypeToText(row.alternatePhone1Type, row.alternatePhone1TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }
                            //             {
                            //                 row.alternatePhone2Num && row.alternatePhone2Num != '' ? <div>Alternate Contact Phone Number 2: <strong>{row.alternatePhone2Num || ''} {row.alternatePhone2Type ? '(' + phoneTypeToText(row.alternatePhone2Type, row.alternatePhone2TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }
                            //             {
                            //                 row.alternatePhone3Num && row.alternatePhone3Num != '' ? <div>Alternate Contact Phone Number 3: <strong>{row.alternatePhone3Num || ''} {row.alternatePhone3Type ? '(' + phoneTypeToText(row.alternatePhone3Type, row.alternatePhone3TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }
                            //             {
                            //                 row.alternatePhone4Num && row.alternatePhone4Num != '' ? <div>Alternate Contact Phone Number 4: <strong>{row.alternatePhone4Num || ''} {row.alternatePhone4Type ? '(' + phoneTypeToText(row.alternatePhone4Type, row.alternatePhone4TypeOther) + ')' : ''}</strong></div>
                            //                 : ''
                            //             }

                            //             <div>Last Edit Date: <strong>{Utils.dateFormatWithTime(row.lastEditDate, true) || ""}</strong></div>
                            //         </Grid.Column>

                            //     </Grid.Row>
                            //     <Divider/>
                            // </Grid>;

                            let html =
                            <Grid key={index} columns={2} className="beaconReviewDetails-grid" stackable >
                                <div>
                                    {/* <strong>#{index+1}</strong>: {Utils.dateFormatWithTime(row.lastEditDate, true) || ""} */}
                                    <strong>#{index+1}</strong>: {Utils.dateFormatWithTime(row.ownerChangeDate, true) || ""}
                                </div>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <div>{i18n.t('account-owner-name')}: <strong>{row.ownerName || ''}</strong></div>
                                        <div>
                                            {i18n.t('username-0')}: <strong>{(row._id.includes('org.couchdb.user:') ? row._id.splii18n.t(':')[1] : row._id) || ""}</strong>
                                        </div>
                                        <div>{i18n.t('email')}: <strong>{row.emailAddress}</strong></div>
                                        <div>{i18n.t('address')}: <strong>{row.address || ''}</strong></div>
                                        <div>{i18n.t('zip-postal-code-2')}: <strong>{row.mailCode || ''}</strong></div>
                                        <div>{i18n.t('city')}: <strong>{row.city || ''}</strong></div>
                                        <div>{i18n.t('state-province')}: <strong>{row.province || ''}</strong></div>
                                        <div>{i18n.t('country')}: <strong>{row.mailCountry || ''}</strong></div>

                                        {
                                            row.phone1Num && row.phone1Num != '' && (<div>{i18n.t('telephone-number-1')}: <strong>{row.phone1Num || ''} {row.phone1Type ? '(' + phoneTypeToText(row.phone1Type, row.phone1TypeOther) + ')' : ''}</strong></div>)
                                        }
                                        {
                                            row.phone2Num && row.phone2Num != '' && (<div>{i18n.t('telephone-number-2')}: <strong>{row.phone2Num || ''} {row.phone2Type ? '(' + phoneTypeToText(row.phone2Type, row.phone2TypeOther) + ')' : ''}</strong></div>)
                                        }
                                        {
                                            row.phone3Num && row.phone3Num != '' && (<div>{i18n.t('telephone-number-3')}: <strong>{row.phone3Num || ''} {row.phone3Type ? '(' + phoneTypeToText(row.phone3Type, row.phone3TypeOther) + ')' : ''}</strong></div>)
                                        }
                                        {
                                            row.phone4Num && row.phone4Num != '' && (<div>{i18n.t('telephone-number-4')}: <strong>{row.phone4Num || ''} {row.phone4Type ? '(' + phoneTypeToText(row.phone4Type, row.phone4TypeOther) + ')' : ''}</strong></div>)
                                        }

                                        <div>{i18n.t('last-edit-date')}: <strong>{Utils.dateFormatWithTime(row.ownerChangeDate, true) || ""}</strong></div>
                                    </Grid.Column>

                                </Grid.Row>
                                <Divider/>
                            </Grid>;

                            return html
                        })
                    )
                }
            </>
        )
    }

}

function countryCodeToText(id){
	let countryNamesWithCode = AppConfigState.countryNamesWithCode
	let item = countryNamesWithCode.find(row => row.value == id)
	if(!item) return ''
	return item.text
}

function phoneTypeToText(id, otherType = ""){
	let phoneTypes = AppConfigState.phoneTypes
	let item = phoneTypes.find(row => row.value == id)
	if(!item) return ''
	if(item.value == 'OTHR'){
		return item.text + ' - ' + otherType
	}
	return item.text
}