import i18n from "i18next";
import React, { Component } from "react";
import { Button, Image, Segment, Grid, Form, Divider, Card, Header } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import NewAccountForm from "./newAccountForm";
import LoginForm from "./loginForm";
import TopMenu from "../../components/topMenu";
import AppState from "../../state/appState";
import RouteState from "../../state/routeState";
import UserState from "../../state/userState";
import Footer from "../../components/footer";
import SpecialMessage from "../specialMessage";
import AppConfigState from "../../state/appConfigState";
import satellite from "../../images/satellite.png"
import tower from "../../images/homepage_tower.png"
import "./home.css";

//Default exported component, this usually contain only top level component representing the structure of the page.
@observer
export default class Home extends Component {
  onForgotPasswordClick = () => {
    RouteState.setRoute("/forgot-password");
  };

  onLogout = (event) => {
    console.log("log: Home -> onLogout -> event", event);
    UserState.logOut().then((result) => {
      console.log("log: Home -> onLogout -> result", result);
      RouteState.setRoute("/");
    });
  };

  onRequestAccessClick = () => {
    window.open("https://www.cospas-sarsat.int/en/beacons-pro/beacon-regulations-pro/ibrd-user-information-for-professionals", "_blank");
  };

  render() {
    let appLoaded = AppState.appLoaded;
    let currentUser = UserState.currentUser;
    if (appLoaded && currentUser) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div>
        <TopMenu onLogout={this.onLogout} hasTopBanner={true} />
        <SpecialMessage pageKey="Homepage" />
        <HomeBody onForgotPasswordClick={this.onForgotPasswordClick} />
        <HomeFaq />
        <HomeNDP onRequestAccessClick={this.onRequestAccessClick} />
        <Footer />
      </div>
    );
  }
}

const HomeBody = ({ onForgotPasswordClick }) => {
  const t = AppState.t;
  return (
    <Segment placeholder basic className="home-body">
      <Grid columns={2} relaxed="very" stackable>
        <Grid.Column>
          <Header as="h2" textAlign="center">
            {t("registering-a-beacon-for-the-first-time")}
            <div>Sign up for an account</div>
            <Image src={satellite} className="home-satellite" size="small" />
            <Header.Subheader>{t("to-register-a-beacon-in-the-ibrd-you-must-first-create-an-ibrd-profile-account")}</Header.Subheader>
          </Header>
          <NewAccountForm />
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <Header as="h2" textAlign="center">
            {t("already-registered-a-beacon-in-the-ibrd")} <br />
            {t("enter-your-credentials-below-to-login")}
            <Image src={tower} className="home-satellite" size="small" />
            <Header.Subheader>{i18n.t("login-to-add-a-new-beacon-or-update-an-existing-ibrd-account")}</Header.Subheader>
          </Header>

          <LoginForm t={t} />

          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <a style={{ color: "red", cursor: "pointer" }} onClick={onForgotPasswordClick}>
              {i18n.t("forgot-your-password")}
            </a>
          </div>
        </Grid.Column>
      </Grid>
      <Divider vertical className="home-body-divider">
        {t("or")}
      </Divider>
    </Segment>
  );
};

@observer
class HomeFaq extends Component {
  @observable faqItems = null;

  componentDidMount() {
    setTimeout(() => {
      this.getFaqItems();
    }, 2000);
  }

  @action getFaqItems() {
    let data = AppConfigState.getPublishedPageContentWithCurrentLanguage("FaqQ&A");
    if (data.length > 0) {
      data = data.slice(0, 4); //get 4 items
    }
    let faqItems = [];
    for (let i = 0; i < data.length; i++) {
      faqItems.push({
        href: "/faq/" + data[i].pageContentKey,
        description: data[i].subject,
      });
    }
    this.faqItems = faqItems;
  }

  onViewAllFAQClick = () => {
    RouteState.setRoute("/faq");
  };
  
  render() {
    const t = AppState.t;
    const currentLocale = AppState.currentLocale;
    return (
      <Segment placeholder className="home-faq-segment">
        <Segment.Inline>
          <Header as="h2" icon>
            {t("looking-for-help")}
            <Header.Subheader>
              <a style={{ cursor: "pointer" }} onClick={this.onViewAllFAQClick}>
                {t("view-all-faqs")}
              </a>
            </Header.Subheader>
          </Header>
        </Segment.Inline>

        {this.faqItems != null && <Card.Group centered className="home-faq-segment-cardGroup" items={this.faqItems} />}
      </Segment>
    );
  }
}

const HomeNDP = ({ onRequestAccessClick }) => {
  const t = AppState.t;
  return (
    <Segment basic textAlign="center" size="massive" padded="very">
      <Header as="h2">{t("do-you-represent-a-national-data-provider-search-and-rescue-authority-authorized-ship-and-aircraft-inspector-or-maintenance-facility")}</Header>
      <Button primary onClick={onRequestAccessClick}>
        {t('request-access-to-the-irbd')}
      </Button>
    </Segment>
  );
};
