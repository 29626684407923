export const countryCode = [
{
  "code": 608,
  "country": "Ascension Island" },

{
  "code": 618,
  "country": "Crozet Archipelago" },

{
  "code": 635,
  "country": "Kerguelen Islands" },

{
  "code": 401,
  "country": "Afghanistan" },

{
  "code": 255,
  "country": "Madeira" },

{
  "code": 204,
  "country": "Azores" },

{
  "code": 607,
  "country": "St. Paul and Amsterdam Islands" },

{
  "code": 201,
  "country": "Albania" },

{
  "code": 605,
  "country": "Algeria" },

{
  "code": 559,
  "country": "American Samoa" },

{
  "code": 202,
  "country": "Andorra" },

{
  "code": 603,
  "country": "Angola" },

{
  "code": 304,
  "country": "Antigua and Barbuda" },

{
  "code": 305,
  "country": "Antigua and Barbuda" },

{
  "code": 423,
  "country": "Azerbaijan" },

{
  "code": 701,
  "country": "Argentina" },

{
  "code": 503,
  "country": "Australia" },

{
  "code": 203,
  "country": "Austria" },

{
  "code": 308,
  "country": "Bahamas" },

{
  "code": 309,
  "country": "Bahamas" },

{
  "code": 311,
  "country": "Bahamas" },

{
  "code": 408,
  "country": "Bahrain" },

{
  "code": 405,
  "country": "Bangladesh" },

{
  "code": 216,
  "country": "Armenia" },

{
  "code": 314,
  "country": "Barbados" },

{
  "code": 205,
  "country": "Belgium" },

{
  "code": 310,
  "country": "Bermuda" },

{
  "code": 410,
  "country": "Bhutan" },

{
  "code": 720,
  "country": "Bolivia" },

{
  "code": 478,
  "country": "Bosnia and Herzegovina" },

{
  "code": 611,
  "country": "Botswana" },

{
  "code": 710,
  "country": "Brazil" },

{
  "code": 312,
  "country": "Belize" },

{
  "code": 557,
  "country": "Solomon Islands" },

{
  "code": 378,
  "country": "British Virgin Islands" },

{
  "code": 508,
  "country": "Brunei Darussalam" },

{
  "code": 207,
  "country": "Bulgaria" },

{
  "code": 506,
  "country": "Myanmar" },

{
  "code": 609,
  "country": "Burundi" },

{
  "code": 206,
  "country": "Belarus" },

{
  "code": 514,
  "country": "Cambodia" },

{
  "code": 515,
  "country": "Cambodia" },

{
  "code": 613,
  "country": "Cameroon" },

{
  "code": 316,
  "country": "Canada" },

{
  "code": 617,
  "country": "Cape Verde" },

{
  "code": 319,
  "country": "Cayman Islands" },

{
  "code": 612,
  "country": "Central African Republic" },

{
  "code": 417,
  "country": "Sri Lanka" },

{
  "code": 670,
  "country": "Chad" },

{
  "code": 725,
  "country": "Chile" },

{
  "code": 412,
  "country": "China" },

{
  "code": 413,
  "country": "China" },

{
  "code": 416,
  "country": "Taiwan ( Province of China)" },

{
  "code": 516,
  "country": "Christmas Island" },

{
  "code": 523,
  "country": "Cocos (Keeling) Islands" },

{
  "code": 730,
  "country": "Colombia" },

{
  "code": 616,
  "country": "Comoros" },

{
  "code": 615,
  "country": "Congo (People's Republic of)" },

{
  "code": 676,
  "country": "Congo ( Democratic Republic of)" },

{
  "code": 518,
  "country": "Cook Islands" },

{
  "code": 321,
  "country": "Costa Rica" },

{
  "code": 238,
  "country": "Croatia" },

{
  "code": 323,
  "country": "Cuba" },

{
  "code": 209,
  "country": "Cyprus" },

{
  "code": 210,
  "country": "Cyprus" },

{
  "code": 212,
  "country": "Cyprus" },

{
  "code": 270,
  "country": "Czech Republic" },

{
  "code": 610,
  "country": "Benin" },

{
  "code": 219,
  "country": "Denmark" },

{
  "code": 220,
  "country": "Denmark" },

{
  "code": 325,
  "country": "Dominica" },

{
  "code": 327,
  "country": "Dominican Republic" },

{
  "code": 735,
  "country": "Ecuador" },

{
  "code": 359,
  "country": "El Salvador" },

{
  "code": 631,
  "country": "Equatorial Guinea" },

{
  "code": 624,
  "country": "Ethiopia" },

{
  "code": 625,
  "country": "Eritrea" },

{
  "code": 276,
  "country": "Estonia" },

{
  "code": 231,
  "country": "Faroe Islands" },

{
  "code": 740,
  "country": "Falkland Islands (Malvinas)" },

{
  "code": 520,
  "country": "Fiji" },

{
  "code": 230,
  "country": "Finland" },

{
  "code": 226,
  "country": "France" },

{
  "code": 227,
  "country": "France" },

{
  "code": 228,
  "country": "France" },

{
  "code": 745,
  "country": "French Guiana" },

{
  "code": 546,
  "country": "French Polynesia" },

{
  "code": 501,
  "country": "French Southern Territories (Adelie Land)" },

{
  "code": 621,
  "country": "Djibouti" },

{
  "code": 626,
  "country": "Gabon" },

{
  "code": 213,
  "country": "Georgia" },

{
  "code": 629,
  "country": "Gambia" },

{
  "code": 443,
  "country": "Palestinian Territory ( Occupied)" },

{
  "code": 211,
  "country": "Germany" },

{
  "code": 218,
  "country": "Germany" },

{
  "code": 627,
  "country": "Ghana" },

{
  "code": 236,
  "country": "Gibraltar" },

{
  "code": 529,
  "country": "Kiribati" },

{
  "code": 237,
  "country": "Greece" },

{
  "code": 239,
  "country": "Greece" },

{
  "code": 240,
  "country": "Greece" },

{
  "code": 331,
  "country": "Greenland" },

{
  "code": 330,
  "country": "Grenada" },

{
  "code": 329,
  "country": "Guadaloupe" },

{
  "code": 332,
  "country": "Guatemala" },

{
  "code": 632,
  "country": "Guinea" },

{
  "code": 750,
  "country": "Guyana" },

{
  "code": 336,
  "country": "Haiti" },

{
  "code": 208,
  "country": "Vatican City State" },

{
  "code": 334,
  "country": "Honduras" },

{
  "code": 477,
  "country": "Hong Kong" },

{
  "code": 243,
  "country": "Hungary" },

{
  "code": 251,
  "country": "Iceland" },

{
  "code": 419,
  "country": "India" },

{
  "code": 525,
  "country": "Indonesia" },

{
  "code": 422,
  "country": "Iran ( Islamic Republic of)" },

{
  "code": 425,
  "country": "Iraq" },

{
  "code": 250,
  "country": "Ireland" },

{
  "code": 428,
  "country": "Israel" },

{
  "code": 247,
  "country": "Italy" },

{
  "code": 619,
  "country": "Cote D'Ivoire" },

{
  "code": 339,
  "country": "Jamaica" },

{
  "code": 431,
  "country": "Japan" },

{
  "code": 432,
  "country": "Japan" },

{
  "code": 436,
  "country": "Kazakhstan" },

{
  "code": 438,
  "country": "Jordan" },

{
  "code": 634,
  "country": "Kenya" },

{
  "code": 445,
  "country": "Korea (Democratic People's Republic of)" },

{
  "code": 440,
  "country": "Korea (Republic of)" },

{
  "code": 441,
  "country": "Korea (Republic of)" },

{
  "code": 447,
  "country": "Kuwait" },

{
  "code": 451,
  "country": "Kyrgyz Republic" },

{
  "code": 531,
  "country": "Lao People's Democratic Republic" },

{
  "code": 450,
  "country": "Lebanon" },

{
  "code": 644,
  "country": "Lesotho" },

{
  "code": 275,
  "country": "Latvia" },

{
  "code": 636,
  "country": "Liberia" },

{
  "code": 637,
  "country": "Liberia" },

{
  "code": 642,
  "country": "Libyan Arab Jamahiriya" },

{
  "code": 252,
  "country": "Liechtenstein" },

{
  "code": 277,
  "country": "Lithuania" },

{
  "code": 253,
  "country": "Luxembourg" },

{
  "code": 453,
  "country": "Macao (Special Administrative Region of China)" },

{
  "code": 647,
  "country": "Madagascar" },

{
  "code": 655,
  "country": "Malawi" },

{
  "code": 533,
  "country": "Malaysia" },

{
  "code": 455,
  "country": "Maldives" },

{
  "code": 649,
  "country": "Mali" },

{
  "code": 215,
  "country": "Malta" },

{
  "code": 248,
  "country": "Malta" },

{
  "code": 249,
  "country": "Malta" },

{
  "code": 256,
  "country": "Malta" },

{
  "code": 347,
  "country": "Martinique" },

{
  "code": 654,
  "country": "Mauritania" },

{
  "code": 645,
  "country": "Mauritius" },

{
  "code": 345,
  "country": "Mexico" },

{
  "code": 254,
  "country": "Monaco" },

{
  "code": 457,
  "country": "Mongolia" },

{
  "code": 214,
  "country": "Moldova" },

{
  "code": 262,
  "country": "Montenegro" },

{
  "code": 348,
  "country": "Montserrat" },

{
  "code": 242,
  "country": "Morocco" },

{
  "code": 650,
  "country": "Mozambique" },

{
  "code": 461,
  "country": "Oman" },

{
  "code": 659,
  "country": "Namibia" },

{
  "code": 544,
  "country": "Nauru" },

{
  "code": 459,
  "country": "Nepal" },

{
  "code": 244,
  "country": "Netherlands" },

{
  "code": 245,
  "country": "Netherlands" },

{
  "code": 246,
  "country": "Netherlands" },

{
  "code": 306,
  "country": "Netherlands Antilles" },

{
  "code": 307,
  "country": "Aruba" },

{
  "code": 540,
  "country": "New Caledonia" },

{
  "code": 576,
  "country": "Vanuatu" },

{
  "code": 512,
  "country": "New Zealand" },

{
  "code": 350,
  "country": "Nicaragua" },

{
  "code": 656,
  "country": "Niger" },

{
  "code": 657,
  "country": "Nigeria" },

{
  "code": 542,
  "country": "Niue" },

{
  "code": 257,
  "country": "Norway" },

{
  "code": 258,
  "country": "Norway" },

{
  "code": 259,
  "country": "Norway" },

{
  "code": 536,
  "country": "Northern Mariana Islands" },

{
  "code": 510,
  "country": "Micronesia ( Federated States of)" },

{
  "code": 538,
  "country": "Marshall Islands" },

{
  "code": 511,
  "country": "Palau" },

{
  "code": 463,
  "country": "Pakistan" },

{
  "code": 351,
  "country": "Panama" },

{
  "code": 352,
  "country": "Panama" },

{
  "code": 353,
  "country": "Panama" },

{
  "code": 354,
  "country": "Panama" },

{
  "code": 355,
  "country": "Panama" },

{
  "code": 356,
  "country": "Panama" },

{
  "code": 357,
  "country": "Panama" },

{
  "code": 370,
  "country": "Panama" },

{
  "code": 371,
  "country": "Panama" },

{
  "code": 372,
  "country": "Panama" },

{
  "code": 373,
  "country": "Panama" },

{
  "code": 553,
  "country": "Papua New Guinea" },

{
  "code": 755,
  "country": "Paraguay" },

{
  "code": 760,
  "country": "Peru" },

{
  "code": 548,
  "country": "Philippines" },

{
  "code": 555,
  "country": "Pitcairn Island" },

{
  "code": 261,
  "country": "Poland" },

{
  "code": 263,
  "country": "Portugal" },

{
  "code": 630,
  "country": "Guinea-Bissau" },

{
  "code": 358,
  "country": "Puerto Rico" },

{
  "code": 466,
  "country": "Qatar" },

{
  "code": 660,
  "country": "Reunion / Mayotte" },

{
  "code": 264,
  "country": "Romania" },

{
  "code": 273,
  "country": "Russian Federation" },

{
  "code": 661,
  "country": "Rwanda" },

{
  "code": 665,
  "country": "St. Helena" },

{
  "code": 341,
  "country": "St. Kitts and Nevis" },

{
  "code": 301,
  "country": "Anguilla" },

{
  "code": 343,
  "country": "St. Lucia" },

{
  "code": 361,
  "country": "St. Pierre and Miquelon" },

{
  "code": 375,
  "country": "St. Vincent and the Grenadines" },

{
  "code": 376,
  "country": "St. Vincent and the Grenadines" },

{
  "code": 377,
  "country": "St. Vincent and the Grenadines" },

{
  "code": 268,
  "country": "San Marino" },

{
  "code": 668,
  "country": "Sao Tome and Principe" },

{
  "code": 403,
  "country": "Saudi Arabia" },

{
  "code": 663,
  "country": "Senegal" },

{
  "code": 279,
  "country": "Serbia" },

{
  "code": 664,
  "country": "Seychelles" },

{
  "code": 667,
  "country": "Sierra Leone" },

{
  "code": 563,
  "country": "Singapore" },

{
  "code": 564,
  "country": "Singapore" },

{
  "code": 565,
  "country": "Singapore" },

{
  "code": 267,
  "country": "Slovakia" },

{
  "code": 574,
  "country": "Vietnam" },

{
  "code": 278,
  "country": "Slovenia" },

{
  "code": 666,
  "country": "Somalia" },

{
  "code": 601,
  "country": "South Africa" },

{
  "code": 679,
  "country": "Zimbabwe" },

{
  "code": 224,
  "country": "Spain" },

{
  "code": 225,
  "country": "Spain" },

{
  "code": 662,
  "country": "Sudan" },

{
  "code": 765,
  "country": "Suriname" },

{
  "code": 669,
  "country": "Swaziland" },

{
  "code": 265,
  "country": "Sweden" },

{
  "code": 266,
  "country": "Sweden" },

{
  "code": 269,
  "country": "Switzerland" },

{
  "code": 468,
  "country": "Syrian Arab Republic" },

{
  "code": 567,
  "country": "Thailand" },

{
  "code": 671,
  "country": "Togo" },

{
  "code": 570,
  "country": "Tonga" },

{
  "code": 362,
  "country": "Trinidad and Tobago" },

{
  "code": 470,
  "country": "United Arab Emirates" },

{
  "code": 672,
  "country": "Tunisia" },

{
  "code": 271,
  "country": "Turkey" },

{
  "code": 434,
  "country": "Turkmenistan" },

{
  "code": 364,
  "country": "Turks and Caicos Islands" },

{
  "code": 572,
  "country": "Tuvalu" },

{
  "code": 675,
  "country": "Uganda" },

{
  "code": 272,
  "country": "Ukraine" },

{
  "code": 274,
  "country": "Macedonia ( the former Yugoslav Republic of)" },

{
  "code": 622,
  "country": "Egypt" },

{
  "code": 232,
  "country": "United Kingdom" },

{
  "code": 233,
  "country": "United Kingdom" },

{
  "code": 234,
  "country": "United Kingdom" },

{
  "code": 235,
  "country": "United Kingdom" },

{
  "code": 674,
  "country": "Tanzania (United Republic of)" },

{
  "code": 677,
  "country": "Tanzania (United Republic of)" },

{
  "code": 303,
  "country": "United States of America" },

{
  "code": 338,
  "country": "United States of America" },

{
  "code": 366,
  "country": "United States of America" },

{
  "code": 367,
  "country": "United States of America" },

{
  "code": 368,
  "country": "United States of America" },

{
  "code": 369,
  "country": "United States of America" },

{
  "code": 379,
  "country": "US Virgin Islands" },

{
  "code": 633,
  "country": "Burkina Faso" },

{
  "code": 770,
  "country": "Uruguay" },

{
  "code": 437,
  "country": "Uzbekistan" },

{
  "code": 775,
  "country": "Venezuela" },

{
  "code": 578,
  "country": "Wallis and Futuna Islands" },

{
  "code": 561,
  "country": "Samoa" },

{
  "code": 473,
  "country": "Yemen" },

{
  "code": 475,
  "country": "Yemen" },

{
  "code": 678,
  "country": "Zambia" }];



export default function getCountryByCode(code) {
  let countryObject = countryCode.find(obj => obj.code.toString() === code);
  if (countryObject) {
    return countryObject.country;
  } else {
    return 'Unknown MID';
    //return 'Unknown MID (' + code + ')';
  }
}