import i18n from "i18next";
import React, { Component } from "react";
import RegistrationState from "../../state/registrationState";
import * as Yup from "yup";
import { Form, Button, Message, Popup, Label, List, Transition, Dimmer, Loader } from "semantic-ui-react";
import withSemanticUIFormik from "../../utils/formHelper";
import RouteState from "../../state/routeState";
import { countryCode } from "../../utils/beaconDecode/countryCode";
import ContactUsState from "../../state/contactUsState";
import Dashboard from "../dashboard/dashboard";
import AppConfigState from "../../state/appConfigState";
import { observer } from "mobx-react";
import { FormErrorDisplay } from "../../components/formErrorDisplay";
import ReCAPTCHA from "react-google-recaptcha";

let countryCodeOptions = countryCode.map((country) => {
  return {
    text: country.code + " - " + country.country,
    value: "" + country.code,
    key: "" + country.code,
  };
});
countryCodeOptions.unshift({ text: "N/A", value: "N/A", key: "N/A" });

const RECAPCHA_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

// let countryCodeOptions = []
// if(AppState.appLoaded){
//   countryCodeOptions = AppConfigState.countryNamesWithCode.unshift( {text: "N/A", value: "N/A", key: "N/A"} );
// }

const beaconTypeOptions = [
  { text: "N/A", value: "N/A", key: "N/A" },
  { key: "ELT", text: "ELT", value: "ELT" },
  { key: "EPIRB", text: "EPIRB", value: "EPIRB" },
  { key: "PLB", text: "PLB", value: "PLB" },
];

// const InnerContactFrom = props => {
@observer
class InnerContactFrom extends Component {
  state = {
    country: "",
    beaconType: "",
    // countryCodeOptions: []
  };

  componentDidMount = () => {
    ContactUsState.resetState();
  };

  onHexChange = (e, data) => {
    //console.log("Contact Us -> handleChange -> data", data);
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);

      // call hex id to get data
      let beacon = ContactUsState.getBeaconByHexId(data.value)
        .then((data) => {
          this.props.values.country = "";
          if (data) {
            let beaconType;
            let _type = data.beaconType;
            if (!_type) return;

            if (_type.includes("ELT")) {
              beaconType = "ELT";
            } else if (_type.includes("EPIRB")) {
              beaconType = "EPIRB";
            } else if (_type.includes("PLB")) {
              beaconType = "PLB";
            }

            this.setState({
              country: data.beaconCountryCode,
              beaconType: beaconType,
            });
            ContactUsState.setMid(data.beaconCountryCode);
            ContactUsState.setbeaconType(beaconType);
          } else {
            this.setState({
              country: "N/A",
              beaconType: "N/A",
            });
          }
          this.props.setFieldValue("country", this.state.country);
          this.props.setFieldValue("beaconType", this.state.beaconType);
        })
        .catch((err) => {
          console.log("Contact Us -> handleChange hexID -> err", err);
        });
    }
  };

  onCountryChange = (e, data) => {
    console.log("log ~ file: contactForm.js ~ line 97 ~ InnerContactFrom ~ data", data);
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);
      this.setState({
        country: data.value,
      });
      ContactUsState.setMid(data.value);
    }
  };

  onBeaconTypeChange = (e, data) => {
    if (data && data.name) {
      this.props.setFieldValue(data.name, data.value);
      this.setState({
        beaconType: data.value,
      });
      ContactUsState.setbeaconType(data.value);
    }
  };

  onRecapchaChange = (value) => {
    console.log(">>>>>>>>>>>> Captcha value:", value);
    if (value) {
      this.props.values.recapchaPass = true;
      this.props.setStatus("");
    }

    // this.setState({ value });
    // // if value is null recaptcha expired
    // if (value === null) this.setState({ expired: "true" });
  };

  render() {
    let { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, status, isValid, setStatus } = this.props;

    let { country, beaconType } = this.state;

    // values.country = country;
    // values.beaconType = beaconType;

    let nameHasError = touched.name && errors.name && errors.name.length > 0;
    let emailHasError = touched.email && errors.email && errors.email.length > 0;
    let confirmEmailHasError = touched.confirmEmail && errors.confirmEmail && errors.confirmEmail.length > 0;
    let subjectHasError = touched.subject && errors.subject && errors.subject.length > 0;
    let hexHasError = touched.hex && errors.hex && errors.hex.length > 0;
    let countryHasError = touched.country && errors.country && errors.country.length > 0;
    let beaconTypeHasError = touched.beaconType && errors.beaconType && errors.beaconType.length > 0;
    let messageHasError = touched.message && errors.message && errors.message.length > 0;
    let hasErrors =
      nameHasError || emailHasError || confirmEmailHasError || subjectHasError || hexHasError || countryHasError || beaconTypeHasError || messageHasError || status !== undefined;

    if (AppConfigState.config) {
      countryCodeOptions = AppConfigState.countryNamesWithCode.filter((entry) => !entry.text.includes("UNALLOCATED"));
      countryCodeOptions.unshift({ text: "N/A", value: "N/A", key: "N/A" });
    } else {
      return (
        <>
          <Dimmer active={true} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        </>
      );
    }

    // scroll to error
    // if(isSubmitting && Object.keys(errors).length>0){
    if (isSubmitting && hasErrors) {
      var errorBox = document.getElementById("scrollToError");
      errorBox.scrollIntoView();
    }

    return (
      <Form id="contactForm" className="contactForm" onSubmit={handleSubmit} error={hasErrors}>
        {/* <div className='home-regHex-label'>
        <span>{i18n.t("HOME.ENTER_YOUR_BEACON_HEX_ID")} </span><Popup content='Help!' trigger={<Button type="button" circular icon='question' primary size="mini" className='home-helpButton' />} />
      </div>
			<div classname="contactForm-Additionnal-content">here is the new content</div> */}

        <div id="scrollToError" />
        <FormErrorDisplay errors={errors} touched={touched} status={status} />
        <div className="required-field-notation">* required fields</div>

        <Form.Group widths="equal">
          <Form.Input label="Name" placeholder="Name" name="name" rows="2" onChange={handleChange} onBlur={handleBlur} error={nameHasError} required className="field-width" />
          <Form.Field />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            label={i18n.t('e-mail')}
            placeholder={i18n.t('e-mail')}
            name="email"
            rows="2"
            onChange={handleChange}
            onBlur={handleBlur}
            error={emailHasError}
            required
            className="field-width"
            type="email"
          />
          <Form.Input
            label={i18n.t('confirm-e-mail')}
            placeholder={i18n.t('confirm-e-mail')}
            name="confirmEmail"
            rows="2"
            onChange={handleChange}
            onBlur={handleBlur}
            error={confirmEmailHasError}
            required
            className="field-width"
            type="email"
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            label={i18n.t('beacon-hex-id')}
            placeholder={i18n.t('beacon-hex-id')}
            name="hex"
            rows="2"
            onChange={this.onHexChange}
            onBlur={this.onHexChange}
            error={hexHasError}
            className="field-width"
          />
          <Form.Select
            label={i18n.t('beacon-type')}
            placeholder={i18n.t('beacon-type')}
            name="beaconType"
            options={beaconTypeOptions}
            onChange={this.onBeaconTypeChange}
            onBlur={handleBlur}
            value={beaconType}
            error={beaconTypeHasError}
            required
            className="field-width"
          />
        </Form.Group>

        <CountryDropdown
          options={countryCodeOptions}
          onChange={this.onCountryChange}
          onBlur={handleBlur}
          error={countryHasError}
          value={country}
          label="Country"
          placeholder="Country"
          name="country"
        />

        <Form.Group widths="equal">
          <Form.Input
            label="Subject"
            placeholder="Subject"
            name="subject"
            rows="2"
            onChange={handleChange}
            onBlur={handleBlur}
            error={subjectHasError}
            required
            className="field-width"
          />
          <Form.Field />
        </Form.Group>

        {/* <Form.Group widths='equal'>
        <Form.TextArea rows={8} label='Message' placeholder='Tell us more...' name='message' onChange={handleChange} onBlur={handleBlur} error={messageHasError} required className='field-width' />
      </Form.Group> */}
        <Form.TextArea
          rows={8}
          width="16"
          label={i18n.t('message')}
          placeholder={i18n.t('tell-us-more')}
          name="message"
          onChange={handleChange}
          onBlur={handleBlur}
          error={messageHasError}
          required
          className="field-width"
        />

        <p>
          <ReCAPTCHA sitekey={RECAPCHA_KEY} onChange={this.onRecapchaChange} />
        </p>

        <Button type="submit" content="Submit" primary loading={isSubmitting} onClick={handleSubmit} />
      </Form>
    );
  }
}

const CountryDropdown = React.memo(
  function MyComponent(props) {
    let { options, onChange, onBlur, error, value, label, placeholder, name } = props;

    return (
      <Form.Group widths="equal" autocomplete="off">
        <Form.Select
          label={label}
          placeholder={placeholder}
          autocomplete="off"
          name={name}
          search
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          value={value}
          required
          className="field-width"
        />
        <Form.Field />
      </Form.Group>
    );
    /* render using props */
  },
  function comparator(prevProps, nextProps) {
    return prevProps.value === nextProps.value;
  }
);

//Higher order function wrapping the form to handle initial input using mapPropsToValues or submit using handleSubmit
const ContactForm = withSemanticUIFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    confirmEmail: "",
    subject: "",
    hex: "",
    country: "",
    beaconType: "",
    message: "",
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required(i18n.t('name-is-required')),
    email: Yup.string().required(i18n.t('e-mail-is-required')),
    confirmEmail: Yup.string()
      .required(i18n.t('confirm-e-mail-is-required'))
      .oneOf([Yup.ref("email"), null], i18n.t('emails-must-match-exactly')),
    subject: Yup.string().required(i18n.t('subject-is-required')),
    country: Yup.string().required(i18n.t('please-choose-your-country')),
    beaconType: Yup.string().required(i18n.t('please-choose-a-beacon-type')),
    message: Yup.string().required(i18n.t('message-is-required')),
  }),

  handleSubmit: (values, { setSubmitting, isValid, setStatus }) => {
    console.log("ContactForm :: handleSubmit: values: ", values);
    if (!values.recapchaPass) {
      setSubmitting(false);
      setStatus("Please do the reCAPTCHA action.");
      ScrollToError();
      return;
    }

    setSubmitting(true);
    // test send email
    ContactUsState.SubmitCRMTicket(values)
      .then((response) => {
        console.log("------------------------> send email response: " + JSON.stringify(response));
        setSubmitting(false);
        if (response.err) {
          setStatus(response.message);
          ScrollToError();
        } else {
          RouteState.setRoute("/contact/complete");
        }
        // RouteState.setRoute('/contact/complete');
      })
      .catch((error) => {
        console.log("handleSubmit: error =>", error);
        setSubmitting(false);
        setStatus(error);
      });
  },
})(InnerContactFrom);

export default ContactForm;

function ScrollToError() {
  var errorBox = document.getElementById("scrollToError");
  errorBox.scrollIntoView();
}
