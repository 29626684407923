import i18n from "i18next";
import React, { Component } from "react";
import { Segment, Header, Icon, List, Button, Container } from "semantic-ui-react";
import DefaultPageLayout from "../../components/defaultPageLayout";
import RouteState from "../../state/routeState";
import AppState from "../../state/appState";
import ContactForm from "./contactForm";
import "./contact.css";
import { observer } from "mobx-react";
import ContactUsState from "../../state/contactUsState";
import SpecialMessage from '../specialMessage';

//Default exported component, this usually contain only top level component representing the structure of the page.
@observer
export default class Contact extends Component {
  onLogin = () => {
    RouteState.setRoute("/dashboard");
  };

  render() {
    // let contactPageContent = <ContactMainPage />
    // if(ContactUsState.isSubmitted){
    //   contactPageContent = <ContactSubmitComplete />
    // }

    return (
      <DefaultPageLayout>
        <ContactMainPage />
        {/* {contactPageContent} */}
      </DefaultPageLayout>
    );
  }
}

const ContactMainPage = () => {
  //switch (cultureKey) for http://www.406registration.com/countriessupported.aspx?CultureCode=en-US
   const t = AppState.t;
  return (
    <div className="contact-us-container">
      <Header as="h2" icon>
        {/* <Icon name="mail" /> */}
        {i18n.t('contact-us')}
        {/* <Header.Subheader>Please let us know what you think</Header.Subheader> */}
      </Header>
      <SpecialMessage pageKey="ContactUs" showPlaceholder={true} />
      <Segment style={{ margin: "0 0 20px" }}>
        <ContactForm />
      </Segment>
    </div>
  );
};

const ContactSubmitComplete = () => {
   const t = AppState.t;
  return (
    <div className="contact-us-container">
      <Header as="h2" icon>
        {/* <Icon name="mail" /> */}
        {i18n.t('contact-us')}
        <Header.Subheader>{i18n.t('thank-you')}</Header.Subheader>
        <Button content="Go To ?" primary fluid />
      </Header>
    </div>
  );
};
