import { fetchDatafromAPI } from './serviceUtils'
import AppConfigState from '../state/appConfigState'
import * as Utils from '../utils/utils';



export async function testMail(){
    console.log('log: testMail -> testMail');
    return await sendMail("william.thivierge@ofitechnology.com","test","test")
}


export async function sendMail(toAddress, subject, content) {
    try {
        let data = {
            toAddress: [toAddress],
            fromAddress: IBRD_CONFIG.adminEmail,
            subject: subject,
            body: content
        }
        return await fetchDatafromAPI(IBRD_CONFIG.sendMailEndpoint, data);
    } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
    }
}

export async function sendMailContactUs(toAddress, subject, content, formData, isSupported, pocEmail) {
    try {
        let data = {
            toAddress: [toAddress],
            fromAddress: IBRD_CONFIG.adminEmail,
            subject: subject,
            body: content,
            isContactUs: true,
            beaconType: formData.beaconType,
            country : formData.country,
            hexId: formData.hexId,
            data:formData,
            isSupported: isSupported,
            pocEmail: pocEmail,
        }
        return await fetchDatafromAPI(IBRD_CONFIG.sendMailEndpoint, data);
    } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
    }
}

export async function sendMailWithAttachment(toAddress, subject, content, attachment) {
    try {
        let data = {
            toAddress: [toAddress],
            fromAddress: IBRD_CONFIG.adminEmail,
            subject: subject,
            body: content,
            attachment: attachment
        }
        return await fetchDatafromAPI(IBRD_CONFIG.sendMailEndpoint, data);
    } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
    }
}

export function prepareEmailData(emailTemplate, beacon, user, token, poc) {
    if(!emailTemplate) {
        throw new Error("prepareEmailData - emailTemplate is required");
    }

    let protocol = location.protocol;
    let hostname = location.hostname;
    let baseUrl = protocol + "//" + hostname;
    if (hostname === "localhost") {
        baseUrl = protocol + "//" + hostname + ":" + location.port;
    }

    let emailData = { ...emailTemplate }
    if(beacon) {
        //beacon
        let beaconInfo = "<strong>Beacon</strong><br/>";
        beaconInfo = beaconInfo.concat("Beacon Type: ", beacon.beaconType || "", "<br/>");
        beaconInfo = beaconInfo.concat("Hex ID: ", beacon.hexId || "", "<br/>");
        let country = beacon.beaconCountryCode + (beacon.countryName ? " - " + beacon.countryName : '');
        beaconInfo = beaconInfo.concat("Country: ", country || "", "<br/>");
        beaconInfo = beaconInfo.concat("Beacon Manufacturer: ", manufacturerToText(beacon.beaconManufacturer), beacon.beaconManufacturerOther ? ' (' + beacon.beaconManufacturerOther + ')' : '', "<br/>");
        beaconInfo = beaconInfo.concat("Serial Number: ", beacon.serialNumber || "", "<br/>");
        beaconInfo = beaconInfo.concat("Model Name: ", beacon.beaconModel || "", "<br/>");
        beaconInfo = beaconInfo.concat("C-S Type Approval Number: ", beacon.typeApprovalCertificate || "", "<br/>");
        beaconInfo = beaconInfo.concat("Beacon Status: ", specialStatusToText(beacon.specialStatus) || "", "<br/>");
        beaconInfo = beaconInfo.concat("Beacon Homing Device: ", beacon.beaconHomingDevice, beacon.beaconHomingDeviceOther ? ' (' + beacon.beaconHomingDeviceOther + ')' : '', "<br/>");
        beaconInfo = beaconInfo.concat("Activation Method: ", activationMedthodToText(beacon.beaconActivationMethod) || "", "<br/>");
        beaconInfo = beaconInfo.concat("Additional Beacon Information: ", beacon.additionalBeaconData || "", "<br/>");

        //vehicle
        beaconInfo = beaconInfo.concat("<br/><strong>Vehicle</strong><br/>");
        beaconInfo = beaconInfo.concat("Vehicle Type: ", beacon.vehicleType || "", "<br/>");
        if(beacon.beaconType) {
            if(beacon.beaconType.includes('ELT')){
                beaconInfo = beaconInfo.concat("Vehicle Registration Number: ", beacon.vehicleRegistrationNumber || "", "<br/>");
                beaconInfo = beaconInfo.concat("Home ICAO Code: ", beacon.homeICAOCode || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft Manufacturer: ", beacon.aircraftManufacturer || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft Model: ", beacon.aircraftModel || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft Colour: ", beacon.aircraftColor || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft Agency: ", beacon.aircraftOperatingAgency || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft Agency Phone: ", beacon.aircraftOperatingAgencyPhone || "", "<br/>");
                beaconInfo = beaconInfo.concat("Radio Equipment: ", beacon.radioEquipment || "", "<br/>");
                beaconInfo = beaconInfo.concat("Radio Equipment (Other): ", beacon.radioEquipmentOther || "", "<br/>");
                beaconInfo = beaconInfo.concat("Deployable Survival Crafts: ", beacon.deployableSurvivalCrafts || "", "<br/>");
                beaconInfo = beaconInfo.concat("Fixed Survival Crafts: ", beacon.fixedSurvivalCrafts || "", "<br/>");
                beaconInfo = beaconInfo.concat("Max. Endurance: ", beacon.maxEndurance || "", "<br/>");
                beaconInfo = beaconInfo.concat("Cruise Air Speed: ", beacon.cruiseAirSpeed || "", "<br/>");
                beaconInfo = beaconInfo.concat("Length: ", beacon.length || "", "<br/>");
                beaconInfo = beaconInfo.concat("WingSpan: ", beacon.wingSpan || "", "<br/>");
                beaconInfo = beaconInfo.concat("People Capacity: ", beacon.peopleCapacity || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft 24 Bit Address: ", beacon.aircraft24BitAddress || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft 24 Bit Address (Decoded): ", beacon.aircraft24BitAddressDecoded || "", "<br/>");
                beaconInfo = beaconInfo.concat("Aircraft Nationality: ", beacon.vehicleNationality || "", "<br/>");
                beaconInfo = beaconInfo.concat("Additional Vehicle / Usage Information: ", beacon.additionalComment || "", "<br/>");
            }
            else if(beacon.beaconType.includes('EPIRB')){
                beaconInfo = beaconInfo.concat("Vehicle Registration Number: ", beacon.vehicleRegistrationNumber || "", "<br/>");
                beaconInfo = beaconInfo.concat("Vessel Name: ", beacon.vehicleName || "", "<br/>");
                beaconInfo = beaconInfo.concat("Vessel Model: ", beacon.vehicleModel || "", "<br/>");
                beaconInfo = beaconInfo.concat("Home Port: ", beacon.homePort || "", "<br/>");
                beaconInfo = beaconInfo.concat("Vessel Colour: ", beacon.vehicleColor || "", "<br/>");
                beaconInfo = beaconInfo.concat("Number of Life Boats: ", beacon.nbLifeBoat || "", "<br/>");
                beaconInfo = beaconInfo.concat("Number of Life Rafts: ", beacon.nbLifeRaft || "", "<br/>");
                beaconInfo = beaconInfo.concat("Radio Equipment: ", beacon.radioEquipment || "", (beacon.radioEquipment=='Other' ? ' (' + beacon.radioEquipmentOther + ')': ''), "<br/>");
                beaconInfo = beaconInfo.concat("Radio Call Sign: ", beacon.callSign || "", "<br/>");
                beaconInfo = beaconInfo.concat("Radio Call Sign (Decoded): ", beacon.callSignDecoded || "", "<br/>");
                beaconInfo = beaconInfo.concat("AIS Number: ", beacon.aisNumber || "", "<br/>");
                beaconInfo = beaconInfo.concat("INMARSAT: ", beacon.Imarsat || "", "<br/>");
                beaconInfo = beaconInfo.concat("Vessel Cellular: ", beacon.vehicleCellularNum || "", "<br/>");
                beaconInfo = beaconInfo.concat("Vessel Satellite Phone: ", beacon.vehicleSatellitePhone || "", "<br/>");
                beaconInfo = beaconInfo.concat("MMSI Number: ", beacon.MMSI || "", "<br/>");
                beaconInfo = beaconInfo.concat("MMSI Number (Decoded): ", beacon.MMSIDecoded || "", "<br/>");
                beaconInfo = beaconInfo.concat("Length: ", beacon.length || "", "<br/>");
                beaconInfo = beaconInfo.concat("Capacity (Crew and Passengers): ", beacon.peopleCapacity || "", "<br/>");
                beaconInfo = beaconInfo.concat("Vehicle Nationality: ", beacon.vehicleNationality || "", "<br/>");
                beaconInfo = beaconInfo.concat("Equipped with Simplified Voyage Data Recorder: ", beacon.equippedWithDataRecord || "", "<br/>");
                beaconInfo = beaconInfo.concat("Additional Vehicle / Usage Information: ", beacon.additionalComment || "", "<br/>");
            }
            else if(beacon.beaconType.includes('PLB')) {
                beaconInfo = beaconInfo.concat("Specific Usage: ", beacon.usageMoreInfo || "", (beacon.usageMoreInfo=='Other') ? ' (' + beacon.usageMoreInfoOther + ')': '', "<br/>");
                beaconInfo = beaconInfo.concat("Additional Vehicle / Usage Information: ", beacon.additionalComment || "", "<br/>");
            }
        }

        //owner
        beaconInfo = beaconInfo.concat("<br/><strong>Owner</strong><br/>");
        beaconInfo = beaconInfo.concat("Name: ", beacon.ownerName, "<br/>");
        beaconInfo = beaconInfo.concat("Email: ", beacon.emailAddress || "", "<br/>");
        beaconInfo = beaconInfo.concat("Address: ", beacon.address, "<br/>");
        beaconInfo = beaconInfo.concat("Zip/Postal Code: ", beacon.mailCode, "<br/>");
        beaconInfo = beaconInfo.concat("City: ", beacon.city, "<br/>");
        beaconInfo = beaconInfo.concat("State/Province: ", beacon.province, "<br/>");
        beaconInfo = beaconInfo.concat("Country: ", beacon.mailCountry, "<br/>");
        beaconInfo = beaconInfo.concat("Medical Information: ", beacon.medicalInfo, "<br/>");
        beaconInfo = beaconInfo.concat("Communication Language: ", beacon.operatorLanguage, "<br/>");
        if(beacon.phone1Num){
            beaconInfo = beaconInfo.concat("Beacon Owner Telephone Number 1: ", beacon.phone1Num, beacon.phone1Type ? ' (' + phoneTypeToText(beacon.phone1Type) + ')' : "", "<br/>");
        }
        if(beacon.phone2Num){
            beaconInfo = beaconInfo.concat("Beacon Owner Telephone Number 2: ", beacon.phone2Num, beacon.phone2Type ? ' (' + phoneTypeToText(beacon.phone2Type) + ')' : "", "<br/>");
        }
        if(beacon.phone3Num){
            beaconInfo = beaconInfo.concat("Beacon Owner Telephone Number 3: ", beacon.phone3Num, beacon.phone3Type ? ' (' + phoneTypeToText(beacon.phone3Type) + ')' : "", "<br/>");
        }
        if(beacon.phone4Num){
            beaconInfo = beaconInfo.concat("Beacon Owner Telephone Number 4: ", beacon.phone4Num, beacon.phone4Type ? ' (' + phoneTypeToText(beacon.phone4Type) + ')' : "", "<br/>");
        }

        beaconInfo = beaconInfo.concat("24-Hour Emergency Contact Name: ", beacon.primaryContactName, "<br/>");
        beaconInfo = beaconInfo.concat("Emergency Contact Address Line 1: ", beacon.primaryContactAddressLine1, "<br/>");
        beaconInfo = beaconInfo.concat("Emergency Contact Address Line 2: ", beacon.primaryContactAddressLine2, "<br/>");
        if(beacon.primaryPhone1Num){
            beaconInfo = beaconInfo.concat("Emergency Contact Phone Number 1: ", beacon.primaryPhone1Num, beacon.primaryPhone1Type ? ' (' + phoneTypeToText(beacon.primaryPhone1Type) + ')' : "", "<br/>");
        }
        if(beacon.primaryPhone2Num){
            beaconInfo = beaconInfo.concat("Emergency Contact Phone Number 2: ", beacon.primaryPhone2Num, beacon.primaryPhone2Type ? ' (' + phoneTypeToText(beacon.primaryPhone2Type) + ')' : "", "<br/>");
        }
        if(beacon.primaryPhone3Num){
            beaconInfo = beaconInfo.concat("Emergency Contact Phone Number 3: ", beacon.primaryPhone3Num, beacon.primaryPhone3Type ? ' (' + phoneTypeToText(beacon.primaryPhone3Type) + ')' : "", "<br/>");
        }
        if(beacon.primaryPhone4Num){
            beaconInfo = beaconInfo.concat("Emergency Contact Phone Number 4: ", beacon.primaryPhone4Num, beacon.primaryPhone4Type ? ' (' + phoneTypeToText(beacon.primaryPhone4Type) + ')' : "", "<br/>");
        }

        beaconInfo = beaconInfo.concat("Alternate 24-Hour Emergency Contact Name: ", beacon.alternateContactName, "<br/>");
        beaconInfo = beaconInfo.concat("Alternate Contact Address Line 1: ", beacon.alternateContactAddressLine1, "<br/>");
        beaconInfo = beaconInfo.concat("Alternate Contact Address Line 2: ", beacon.alternateContactAddressLine2, "<br/>");
        if(beacon.alternatePhone1Num){
            beaconInfo = beaconInfo.concat("Alternate Contact Phone Number 1: ", beacon.alternatePhone1Num, beacon.alternatePhone1Type ? ' (' + phoneTypeToText(beacon.alternatePhone1Type) + ')' : "", "<br/>");
        }
        if(beacon.alternatePhone2Num){
            beaconInfo = beaconInfo.concat("Alternate Contact Phone Number 2: ", beacon.alternatePhone2Num, beacon.alternatePhone2Type ? ' (' + phoneTypeToText(beacon.alternatePhone2Type) + ')' : "", "<br/>");
        }
        if(beacon.alternatePhone3Num){
            beaconInfo = beaconInfo.concat("Alternate Contact Phone Number 3: ", beacon.alternatePhone3Num, beacon.alternatePhone3Type ? ' (' + phoneTypeToText(beacon.alternatePhone3Type) + ')' : "", "<br/>");
        }
        if(beacon.alternatePhone4Num){
            beaconInfo = beaconInfo.concat("Alternate Contact Phone Number 4: ", beacon.alternatePhone4Num, beacon.alternatePhone4Type ? ' (' + phoneTypeToText(beacon.alternatePhone4Type) + ')' : "", "<br/>");
        }

        //replace template keys in email's subject
        emailData.subject = emailData.subject.replace(/\[BCNID15\]/g, beacon.hexId);

        //replace template keys in email's content
        emailData.content = emailData.content.replace(/\[BCNID15\]/g, beacon.hexId);
        emailData.content = emailData.content.replace(/\[OWNERNAME\]/g, beacon.ownerName);
        emailData.content = emailData.content.replace(/\[BEACONINFO\]/g, beaconInfo);
        emailData.content = emailData.content.replace(/\[COUNTRY\]/g, beacon.countryName || country);
        emailData.content = emailData.content.replace(/\[CURRENTDATE\]/g, Utils.dateFormatWithTime(Date.now()));

        // for attaching to email
        let firstSection = '<Image src="' + baseUrl + '/images/topmenu_406logo.png" /><br/>';
        firstSection = firstSection.concat("<h2>Certificate of 406 MHz beacon registration</h2>");
        let editBeaconInfo = beaconInfo.slice(0, 0) + firstSection + beaconInfo.slice(0);
        editBeaconInfo = editBeaconInfo.concat("Initial Registration Date: ", dateFormat(beacon.initialDate), "<br/>");
        editBeaconInfo = editBeaconInfo.concat("Last Edit Date: ", dateFormat(beacon.lastEditDate), "<br/>");
        let status = beacon.specialStatus || 'ACTIVE';
        editBeaconInfo = editBeaconInfo.concat("Special Status: ", status, "<br/>");
        editBeaconInfo = editBeaconInfo.concat("Special Status Date: ", dateFormat(beacon.specialStatusDate), "<br/>");
        emailData.beaconInfo = editBeaconInfo
    }

    if(user) {
        //replace template keys in email's content
        emailData.content = emailData.content.replace(/\[OWNERNAME\]/g, user.ownerName);

        // replace date_time
        let editDate = dateFormat(user.lastEditDate) || "N/A";
        emailData.content = emailData.content.replace(/\[Date_Time\]/g, editDate);
    }

    if(token) {
        //replace template keys in email's content
        let confirmationUrl = baseUrl + "/verification/" + token;
        emailData.content = emailData.content.replace(/\[CONFIRMATIONURL\]/g, confirmationUrl);
    }

    if(poc) {
        emailData.content = emailData.content.replace(/\[CONTACTNAME\]/g, poc.name);
    }

    console.log('---> email data: ', baseUrl, emailData.content, hostname)

    return emailData;
}

export function prepareResetPasswordEmail(emailTemplate, userId, token, ownerName, ipAddress='n/a') {
    let emailData = { ...emailTemplate };
    let username = userId.substring(userId.indexOf(":") + 1);
    let protocol = location.protocol;
    let hostname = location.hostname;
    let baseUrl = protocol + "//" + hostname;
    if (hostname === "localhost") {
        baseUrl = protocol + "//" + hostname + ":" + location.port;
    }
    let resetUrl = baseUrl + "/forgot-password/" + token;

    emailData.content = emailData.content.replace(/\[USERNAME\]/g, username);
    emailData.content = emailData.content.replace(/\[RESET_URL\]/g, resetUrl);
    emailData.content = emailData.content.replace(/\[OWNERNAME\]/g, ownerName);
    emailData.content = emailData.content.replace(/\[IPADDRESS\]/g, ipAddress);

  return emailData;
}

function manufacturerToText(id){
	let manufacturerOption = AppConfigState.manufacturerOption
	let item = manufacturerOption.find(row => row.value == id)
	if(!item) return ''
	return item.text
}

function phoneTypeToText(id){
	let phoneTypes = AppConfigState.phoneTypes
	let item = phoneTypes.find(row => row.value == id)
	if(!item) return ''
	return item.text
}

function activationMedthodToText(id){
	const activationMethodOptions = AppConfigState.beaconActivationMethod
	let item = activationMethodOptions.find(row => row.value == id)
	if(!item) return ''
	return item.text
}

function specialStatusToText(id){
	let beaconStatusOption = AppConfigState.beaconStatusOption
	let item = beaconStatusOption.find(row => row.value == id)
	if(!item) return 'Active'
	return item.text
}

function dateFormat(oldDate){
	if(!oldDate) return
	let createDate = new Date(oldDate)
	let year = createDate.getFullYear()
	let month = ("0" + (createDate.getMonth() + 1)).slice(-2)
	let date = ("0" + createDate.getDate()).slice(-2)
	let hours = ("0" + createDate.getHours()).slice(-2)
	let minutes = ("0" + createDate.getMinutes()).slice(-2)
	let seconds = ("0" + createDate.getSeconds()).slice(-2)

	return (year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds)
}
