import React, { Component, useState, useEffect } from "react";
import Footer from "../../components/footer";
import TopMenu from "../../components/topMenu";
import RegistrationState from "../../state/registrationState";
import UserState from "../../state/userState";
import DashboardState from "../../state/dashboardState";
import AppConfigState from "../../state/appConfigState";
import RouteState from "../../state/routeState";
import i18n from "i18next";
import {
  Header,
  Table,
  Grid,
  Input,
  Icon,
  Button,
  Dropdown,
  Divider,
  Pagination,
  Modal,
  Segment,
  Dimmer,
  Loader,
  Select,
  Form,
  Label,
  Checkbox,
  Transition,
  Message,
  Flag,
} from "semantic-ui-react";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import RegisterBeacon from "../registerBeacon/registerBeacon";
import Fuse from "fuse.js";
import { insertOrUpdateDocument } from "../../services/serviceUtils";
import dbService from "../../services/dbService";
import { DateInput } from "semantic-ui-calendar-react";
import * as FileSaver from "file-saver";
import { bool } from "yup";
import * as Utils from "../../utils/utils";
import * as BeaconUtils from "../../utils/beaconDecode/beaconUtils";
import { Formik, Field } from "formik";
import "./beacons.css";
import ReactResizeDetector from "react-resize-detector";
import OwnerDetail from "../../components/ownerDetail";
import NdpDetail from "../../components/ndpDetail";
import AccountDetail from "../../components/accountDetail";

const delegationStatusOptions = [{ key: "ndpManaged", value: false, text: "NDP Managed" }, { key: "ndpDelegated", value: true, text: "NDP Delegated" }];

const paginationOptions = [{ key: "25", text: "25", value: 25 }, { key: "50", text: "50", value: 50 }, { key: "100", text: "100", value: 100 }];

let searchFields = [
  {
    key: "hexId",
    value: "hexId",
    text: "Beacon Hex ID",
  },
  {
    key: "vehicleName",
    value: "vehicleName",
    text: "Vehicle/Aircraft Name",
  },
  {
    key: "callSign",
    value: "callSign",
    text: "Radio Call Sign",
  },
  {
    key: "MMSI",
    value: "MMSI",
    text: "MMSI Number",
  },
  {
    key: "vehicleRegistrationNumber",
    value: "vehicleRegistrationNumber",
    text: "Vehicle Registration Number",
  },
  {
    key: "aircraft24BitAddress",
    value: "aircraft24BitAddress",
    text: "Aircraft 24 Bit Address",
  },
  {
    key: "typeApprovalCertificate",
    value: "typeApprovalCertificate",
    text: "Beacon C‐S Type Approval Number",
  },
  {
    key: "beaconType",
    value: "beaconType",
    text: "Beacon Type",
  },
  {
    key: "serialNumber",
    value: "serialNumber",
    text: "Beacon Serial Number",
  },
  {
    key: "specialStatus",
    value: "specialStatus",
    text: "Beacon Special Status",
  },
  {
    key: "ownerName",
    value: "ownerName",
    text: "Beacon Owner Name",
  },
  {
    key: "countryName",
    value: "countryName",
    text: "Country",
  },
  {
    key: "beaconCountryCode",
    value: "beaconCountryCode",
    text: "Country Code",
  },
  {
    key: "emailAddress",
    value: "emailAddress",
    text: "Email Address",
  },
  {
    key: "lastEditDate",
    value: "lastEditDate",
    text: "Last Edit Date",
  },
  {
    key: "lastConfirmationDate",
    value: "lastConfirmationDate",
    text: "Date of last confirmation request",
  },
];

let exportTypeOptions = [
  {
    key: "xml",
    value: "xml",
    text: "XML (eXtensible Markup Language)",
  },
  {
    key: "csv",
    value: "csv",
    text: "CSV (Comma Separated Values)",
  },
  {
    key: "tsv",
    value: "tsv",
    text: "TSV (Tab Separated Values)",
  },
  {
    key: "json",
    value: "json",
    text: "JSON (JavaScript Object Notation)",
  },
  // {
  //   key: "printFormat",
  //   value: "printFormat",
  //   text: "Print format",
  // },
];

let managerId = ["1", "3", "4"];

@observer
export default class Beacons extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  state = {
    searchCriteriaObj: {},
    searchString: "",
    beaconPerPage: 25,
    currentPage: 1,
    isLoading: false,
    showExportModal: false,
    currentExportType: "xml",
    criteriaComponents: [
      { index: 0, key: searchFields[0].value, value: "" },
      { index: 1, key: searchFields[1].value, value: "" },
      { index: 2, key: searchFields[2].value, value: "" },
      { index: 3, key: searchFields[3].value, value: "" },
    ],
    isExporting: false,
    showClearConfirm: false,
    showDeleteCriteriaConfirm: false,
    criteriaToDelete: {},
    showExitExportConfirm: false,
    showModalDelegation: false,
    isBulkDelegation: false,
    delegatedBeaconId: null,
    delegationStatusToUpdate: false,
    delegationHeader: "",
    delegationSucessMessage: "",
    delegationIncompleteMessage: "",
    isSaving: false,
    notSupportMessage: "",
    showNotSupportModal: false,
    inputPage: 1,
    displaySearch: false,
  };

  componentDidMount() {
    this.manageFilterForRoleId();
    UserState.getMaintProviderUsers();

    // DashboardState.setIsLoading(true);
    // DashboardState.searchBeacons({}).then(() => {
    //   DashboardState.setIsLoading(false);
    // });
    DashboardState.setBeaconsPage(1);
  }

  manageFilterForRoleId = () => {
    // for all RoleId : in case has removed
    let moreFields = [
      {
        key: "ownerName",
        value: "ownerName",
        text: "Beacon Owner Name",
      },
      {
        key: "emailAddress",
        value: "emailAddress",
        text: "Email Address",
      },
    ];
    moreFields.map((row) => {
      let x = searchFields.find((entry) => entry.value === row.value);
      if (!x) {
        searchFields.push(row);
      }
    });

    // add more filters for SAR
    let addMoreFieldByRoles = ["2", "4", "3"];
    if (addMoreFieldByRoles.includes(UserState.currentUserProfile.roleId)) {
      let moreFields = [
        {
          key: "ownerUsername",
          value: "ownerUsername",
          text: "Account Owner Username",
        },
        // {
        //   key: "lastConfirmationDate",
        //   value: "lastConfirmationDate",
        //   text: "Date of last confirmation request",
        // },
      ];
      moreFields.map((row) => {
        let x = searchFields.find((entry) => entry.value === row.value);
        if (!x) {
          searchFields.push(row);
        }
      });
    }

    // remove owner name, username for Ship Inspector
    let shipRoleId = ["2"];
    if (shipRoleId.includes(UserState.currentUserProfile.roleId)) {
      let removeFields = [
        {
          key: "ownerName",
          value: "ownerName",
          text: "Beacon Owner Name",
        },
        {
          key: "ownerUsername",
          value: "ownerUsername",
          text: "Account Owner Username",
        },
        {
          key: "emailAddress",
          value: "emailAddress",
          text: "Email Address",
        },
      ];
      removeFields.map((row) => {
        searchFields = searchFields.filter((entry) => entry.value != row.value);
      });
    }

    // add "Account Owner Name" for Admin, SAR, NDP Task_ID: #60493586
    // let managerId = ["1","3","4"];
    let _fields = [
      {
        key: "accountOwnerName",
        value: "accountOwnerName",
        text: "Account Owner Name",
      },
    ];
    if (managerId.includes(UserState.currentUserProfile.roleId)) {
      _fields.map((row) => {
        let x = searchFields.find((entry) => entry.value === row.value);
        if (!x) {
          searchFields.push(row);
        }
      });
    } else {
      _fields.map((row) => {
        searchFields = searchFields.filter((entry) => entry.value != row.value);
      });
    }
  };

  onExpandSearch = () => {
    this.setState({ displaySearch: !this.state.displaySearch });
  };

  buildFilterObject = (rawFilter) => {
    let searchCriteriaObj = {};
    searchCriteriaObj = rawFilter.reduce((result, currentValue) => {
      let key = currentValue.key;
      if (currentValue.value != "") {
        if (!result[key]) {
          result[key] = [];
        }
        if (key == "lastEditDate" || key == "lastConfirmationDate") {
          result[key] = { condition: currentValue.value, date: currentValue.dateValue };
        } else {
          result[key].push(currentValue.value);
        }
      }

      return result;
    }, {});

    return searchCriteriaObj;
  };

  onChangeBeaconPerPage = (e, { value }) => {
    DashboardState.setBeaconsPerPage(value);
    /*this.setState({
      beaconPerPage: value,
    });*/
  };

  onSetPage = (e, data) => {
    DashboardState.setBeaconsPage(data.activePage);
    /*this.setState({
      currentPage: data.activePage,
    });*/
  };

  // onInputPageChange = (e, data) => {
  //   this.setState({inputPage: data.value});
  // }
  onInputPageChange = (e) => {
    let pattern = /^\d+$/;
    let isNumber = pattern.test(e.target.value);
    if (isNumber || isNumber == "") {
      this.setState({ inputPage: e.target.value });
    }
  };
  onSetPageByBlur = (nbOfPages) => {
    if (!this.state.inputPage || this.state.inputPage == "") return;
    if (parseInt(this.state.inputPage) < 1) {
      this.setState({ inputPage: 1 });
      DashboardState.setBeaconsPage(1);
    } else if (parseInt(this.state.inputPage) > nbOfPages) {
      this.setState({ inputPage: nbOfPages });
      DashboardState.setBeaconsPage(nbOfPages);
    } else {
      DashboardState.setBeaconsPage(parseInt(this.state.inputPage));
    }
  };
  keyPressed = (event, nbOfPages) => {
    if (event.key === "Enter") {
      this.onSetPageByBlur(nbOfPages);
    }
  };

  onPreviousPageClick = () => {
    let currentPage = parseInt(DashboardState.beaconsCurrentPage);
    if (currentPage > 1) {
      this.setState({ inputPage: currentPage - 1 });
      DashboardState.setBeaconsPage(currentPage - 1);
    }
  };

  onNextPageClick = (nbOfPages) => {
    let currentPage = parseInt(DashboardState.beaconsCurrentPage);
    if (currentPage < nbOfPages) {
      this.setState({ inputPage: currentPage + 1 });
      DashboardState.setBeaconsPage(currentPage + 1);
    }
  };

  // new logic for beacon search
  onAddCriteriaComponent = () => {
    const _index = this.state.criteriaComponents.length;
    let criteria = { index: _index, key: searchFields[_index].value, value: "" };
    this.state.criteriaComponents.push(criteria);
    this.setState({ criteriaComponents: this.state.criteriaComponents });
  };

  onFieldChange = (e, data) => {
    let objIndex = this.state.criteriaComponents.findIndex((obj) => obj.index == data.criteria.index);
    this.state.criteriaComponents[objIndex].value = data.value;
    this.setState({ criteriaComponents: this.state.criteriaComponents });
  };

  onCriteriaChange = (e, data) => {
    let objIndex = this.state.criteriaComponents.findIndex((obj) => obj.index == data.criteria.index);
    this.state.criteriaComponents[objIndex].key = data.value;
    this.state.criteriaComponents[objIndex].value = ""; // reset value if field is changed
    this.setState({ criteriaComponents: this.state.criteriaComponents });
  };

  onLastEditDateChange = (e, data) => {
    let objIndex = this.state.criteriaComponents.findIndex((obj) => obj.index == data.criteria.index);
    this.state.criteriaComponents[objIndex].dateValue = data.value;
    this.setState({ criteriaComponents: this.state.criteriaComponents });
  };

  onSearchClick = () => {
    let checkSupportedByRole = ["2", "4", "3"];
    if (checkSupportedByRole.includes(UserState.currentUserProfile.roleId)) {
      if (this.checkSupportedBeacon()) {
        this.searchBeacon();
      }
    } else {
      this.searchBeacon();
    }
  };

  checkSupportedBeacon = async () => {
    try {
      let searchCriteriaObj = this.buildFilterObject(this.state.criteriaComponents);
      if (searchCriteriaObj.hexId) {
        let decodedBeacon = await RegistrationState.decodedBeaconInfo(searchCriteriaObj.hexId[0]);
        if (!decodedBeacon) {
          // show not support link with poc=N/A
          let message = RegistrationState.getNotSupportMessage(decodedBeacon, null);
          this.setState({
            notSupportMessage: message,
            showNotSupportModal: true,
          });
          return false;
        }

        let midInfo = AppConfigState.config.MIDInfo.find((row) => row.Mid == decodedBeacon.beaconCountryCode);
        if (!midInfo) {
          // show not support link with poc=N/A
          let message = RegistrationState.getNotSupportMessage(decodedBeacon, null);
          this.setState({
            notSupportMessage: message,
            showNotSupportModal: true,
          });
          return false;
        }

        let beaconType = "-1";
        if (decodedBeacon.beaconType.includes("EPIRB")) {
          beaconType = "0";
        } else if (decodedBeacon.beaconType.includes("ELT")) {
          beaconType = "1";
        } else if (decodedBeacon.beaconType.includes("PLB")) {
          beaconType = "2";
        } else if (decodedBeacon.beaconType.includes("SSAS")) {
          beaconType = "3";
        }
        let isSupported = false;
        // check if individual support
        if (midInfo.Supported == "Y" && midInfo.SupportedTypes.includes(beaconType)) {
          isSupported = true;
        }
        // check if NDP support
        else if (midInfo.NDPSupportedTypes.includes(beaconType)) {
          isSupported = true;
        }
        if (!isSupported) {
          let pocInfo = await RegistrationState.getPointOfContact(midInfo, beaconType); // or decodedBeacon.locationProtocol
          let message = RegistrationState.getNotSupportMessage(decodedBeacon, pocInfo);
          // show not support link with poc
          this.setState({
            notSupportMessage: message,
            showNotSupportModal: true,
          });
        }
        return isSupported;
      }
    } catch (error) {
      console.error("checkSupportedBeacon: cannot decode beacon", error);
    }
  };

  closeNotSupportModal = () => {
    this.setState({
      notSupportMessage: "",
      showNotSupportModal: false,
    });
  };

  searchBeacon = () => {
    DashboardState.setIsLoading(true);
    let searchCriteriaObj = this.buildFilterObject(this.state.criteriaComponents);
    DashboardState.searchBeacons(searchCriteriaObj).then((result) => {
      if (searchCriteriaObj.lastEditDate) {
        // make new ui for "lastEditDate"
        let lastObj = this.state.criteriaComponents.find((row) => row.value == searchCriteriaObj.lastEditDate.condition && row.dateValue == searchCriteriaObj.lastEditDate.date);
        let removed = this.state.criteriaComponents.filter((row) => row.key != "lastEditDate");
        removed.push(lastObj);
        removed.sort((a, b) => (a.index > b.index ? 1 : -1));
        this.setState({
          // isLoading: false,
          criteriaComponents: removed,
        });
        DashboardState.setIsLoading(false);
      } else {
        // this.setState({ isLoading: false });
        DashboardState.setIsLoading(false);
      }
      this.setState({ inputPage: 1 });
      DashboardState.setBeaconsPage(1);
    });
    RegistrationState.setIsBeaconSaved(false);
  };

  onDeleteCriteria = (criteria) => {
    // let newCriteria = this.state.criteriaComponents.filter((item) => item.index != criteria.index);
    // this.setState({ criteriaComponents: newCriteria });

    this.setState({ showDeleteCriteriaConfirm: true, criteriaToDelete: criteria });
  };

  acceptDeleteCriteria = () => {
    this.setState({ showDeleteCriteriaConfirm: false, criteriaToDelete: {} });

    if (this.state.criteriaToDelete) {
      let newCriteria = this.state.criteriaComponents.filter((item) => item.index != this.state.criteriaToDelete.index);
      this.setState({ criteriaComponents: newCriteria });
    }

    if (DashboardState.filterUsername && this.state.criteriaToDelete.key === "ownerUsername") {
      DashboardState.resetSearchByUsername();
    }
  };

  cancelDeleteCriteria = () => {
    this.setState({ showDeleteCriteriaConfirm: false, criteriaToDelete: {} });
  };

  onClearCriteria = () => {
    // confirm to clear
    this.setState({ showClearConfirm: true });
  };

  acceptClear = () => {
    this.setState({ showClearConfirm: false });

    DashboardState.setIsLoading(true);
    this.setState({
      criteriaComponents: [
        { index: 0, key: searchFields[0].value, value: "" },
        { index: 1, key: searchFields[1].value, value: "" },
        { index: 2, key: searchFields[2].value, value: "" },
        { index: 3, key: searchFields[3].value, value: "" },
      ],
      inputPage: 1,
    });

    // clear searchcriteria in dashboardState
    DashboardState.beaconSearchCriteria = {};
    DashboardState.setBeaconsPage(1);
    DashboardState.setSpecialQuery(false);
    if (DashboardState.filterUsername) {
      DashboardState.resetSearchByUsername();
    } else {
      DashboardState.searchBeacons({}).then(() => {
        DashboardState.setIsLoading(false);
      });
    }
  };

  clearFiltersForUser = () => {
    this.setState({
      criteriaComponents: [
        { index: 0, key: searchFields[0].value, value: "" },
        { index: 1, key: searchFields[1].value, value: "" },
        { index: 2, key: searchFields[2].value, value: "" },
        { index: 3, key: searchFields[3].value, value: "" },
      ],
    });
  };

  cancelClear = () => {
    this.setState({ showClearConfirm: false });
  };

  onShowExportModal = () => {
    this.setState({ showExportModal: true, currentExportType: "xml" });
  };

  onCloseExportModale = () => {
    this.setState({ showExitExportConfirm: true });

    // this.setState({ showExportModal: false });
  };

  acceptExitExport = () => {
    this.setState({ showExitExportConfirm: false, showExportModal: false });
  };

  cancelExitExport = () => {
    this.setState({ showExitExportConfirm: false });
  };

  onExportTypeChange = (e, data) => {
    if (data) {
      this.setState({ currentExportType: data.value });
    }
  };

  onSearchBeaconForExport = async () => {
    let searchCriteriaObj = this.buildFilterObject(this.state.criteriaComponents);
    return await DashboardState.searchBeaconForExport(searchCriteriaObj);
  };

  onUpdateDelegationStatus = (beacon) => {
    if(!AppConfigState.delegationEnabledByType(beacon)) return
    this.setState({
      showModalDelegation: true,
      isBulkDelegation: false,
      delegatedBeaconId: beacon._id,
      delegationStatusToUpdate: beacon.isDelegated || false,
      delegationHeader: "Edit Beacon Delegation",
      delegationSucessMessage: "",
      delegationIncompleteMessage: "",
    });
  };

  onBulkUpdateDelegationStatus = () => {
    if (DashboardState.selectedBeacons.length == 0) {
      return;
    }
    let count = DashboardState.selectedBeacons.filter((x) => x.checked).length;
    this.setState({
      showModalDelegation: true,
      isBulkDelegation: true,
      delegatedBeaconId: null,
      delegationStatusToUpdate: false,
      delegationHeader: "Edit Beacon Delegation for " + count + " record(s) selected",
      delegationSucessMessage: "",
      delegationIncompleteMessage:
        DashboardState.selectedBeacons.filter((x) => x.checked).length > 0
          ? ""
          : i18n.t('no-records-selected'),
    });
  };

  onCloseModalDelegation = () => {
    this.setState({
      showModalDelegation: false,
      isSaving: false,
    });
  };

  delegationStatusChange = (e, data) => {
    this.setState({ delegationStatusToUpdate: data.value });
  };

  onSaveDelegationStatus = async () => {
    this.setState({ isSaving: true });

    let selectedBeaconIds = [];
    if (this.state.isBulkDelegation) {
      DashboardState.selectedBeacons.forEach((item) => {
        if (item.checked) selectedBeaconIds.push(item.id);
      });
      let result = await RegistrationState.bulkUpdateDelegationStatus(selectedBeaconIds, this.state.delegationStatusToUpdate);
      if (result.error > 0 || result.statusError > 0) {
        let message = "";
        if (result.statusError > 0) {
          message += i18n.t('cannot-delegate-beacon-that-has-sold-or-maintenance-status');
        }
        if (result.error > 0) {
          message +=
            "An email address is required in order to set 'Delegation' to 'NDP Delegated'. Under 'Delegation Status' please check the 'Ndp Delegated Incomplete' records and add an email address in the Beacon Record under 'Owner Details'. ";
        }
        this.setState({ delegationIncompleteMessage: message });
      }

      let message = "Delegation process is complete. " + result.affected + " row(s) affected, " + (result.error + result.statusError) + " row(s) error.";
      this.setState({ delegationSucessMessage: message });
    } else {
      //Single Delegation
      selectedBeaconIds.push(this.state.delegatedBeaconId);
      let result = await RegistrationState.bulkUpdateDelegationStatus(selectedBeaconIds, this.state.delegationStatusToUpdate);
      if (result.error > 0 || result.statusError > 0) {
        let message = "";
        if (result.statusError > 0) {
          message += "Cannot delegate beacon that has 'sold' or 'maintenance' status. ";
        }
        if (result.error > 0) {
          message +=
            "An email address is required in order to set 'Delegation' to 'NDP Delegated'. Under 'Delegation Status' please check the 'NDP Delegated Incomplete' records and add an email address in the Beacon Record under 'Owner Details'.";
        }
        this.setState({ delegationIncompleteMessage: message });
      } else {
        this.onCloseModalDelegation();
      }
    }

    DashboardState.searchBeacons({}).then(() => {});
  };

  onExportBeaconClick = (currentExportType) => async () => {
    if (!currentExportType || currentExportType == "") {
      return null;
    }
    this.setState({ isExporting: true });
    let beaconsToExport = await this.onSearchBeaconForExport();
    BeaconUtils.beaconExport(currentExportType, beaconsToExport);
    this.setState({ isExporting: false });
    this.setState({ showExportModal: false });
  };

  onResize = (width, height) => {
    if (width < 768) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  };

  onUpdateSearch = (ownerName, accountOwnerName, ownerUsername) => {
    this.setState({
      criteriaComponents: [
        { index: 0, key: "ownerName", value: ownerName },
        { index: 1, key: "accountOwnerName", value: accountOwnerName },
        { index: 2, key: "ownerUsername", value: ownerUsername },
        { index: 3, key: searchFields[3].value, value: "" },
      ],
    });
  };

  onUpdateSearchForAccount = (accountOwnerName) => {
    this.setState({
      criteriaComponents: [
        { index: 0, key: "accountOwnerName", value: accountOwnerName },
        { index: 1, key: searchFields[1].value, value: "" },
        { index: 2, key: searchFields[2].value, value: "" },
        { index: 3, key: searchFields[3].value, value: "" },
      ],
    });
  };

  onAddBeacon = (e) => {
    e.stopPropagation(); //This prevent the tab itself to register the click.
    RegistrationState.resetSteps();
    RouteState.setRoute("/newBeacon");
  };

  render() {
    let {
      isLoading,
      criteriaComponents,
      showExportModal,
      currentExportType,
      isExporting,
      showClearConfirm,
      showDeleteCriteriaConfirm,
      showExitExportConfirm,
      showModalDelegation,
      isBulkDelegation,
      delegationStatusToUpdate,
      delegationHeader,
      delegationSucessMessage,
      delegationIncompleteMessage,
      isSaving,
      notSupportMessage,
      showNotSupportModal,
      inputPage,
      isMobile,
      displaySearch,
    } = this.state;
    let beacons = DashboardState.beacons;
    let beaconsLastUpdate = DashboardState.beaconsLastUpdate; //Since .beacons is not observable anymore this replace the value for updates.
    let currentPage = DashboardState.beaconsCurrentPage;
    let beaconPerPage = DashboardState.beaconPerPage;
    let nbOfPages = Math.ceil(DashboardState.totalBeacons / beaconPerPage);

    let roleId = UserState.currentUserProfile.roleId;
    let canEditBeacon = true;
    if (UserState.notAllowToEditByRoleId.includes(roleId)) {
      canEditBeacon = false;
    }

    if (UserState.hasExpendedSearchAtFirst.includes(roleId)) {
    }
    let isSpecialQuery = DashboardState.isSpecialQuery;
    console.log("log: render -> isSpecialQuery", isSpecialQuery);
    if (isSpecialQuery || DashboardState.filterUsername) {
      displaySearch = true;
    }

    let searchComponents = null;
    let searchIconClass = "caret down";

    if (displaySearch) {
      searchIconClass = "caret up";
      searchComponents = (
        <>
          <Grid.Row className="dashboard-beacons-gridRow  ">
            <Grid.Column width={16}>
              <Form autoComplete="off">
                <SearchCriteriaDisplay
                  criteriaComponents={criteriaComponents}
                  searchFields={searchFields}
                  onCriteriaChange={this.onCriteriaChange}
                  onFieldChange={this.onFieldChange}
                  onLastEditDateChange={this.onLastEditDateChange}
                  onDeleteCriteria={this.onDeleteCriteria}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="dashboard-beacons-gridRow-last animated fadeIn slow">
            <Grid.Column width={16}>
              <Button primary onClick={this.onAddCriteriaComponent} className="button-mobile">
                {i18n.t('add-search-criteria')}
              </Button>
              <Button primary onClick={this.onSearchClick} className="button-mobile">
                {i18n.t('search')}
              </Button>

              <div style={{ display: "inline-block", float: "right" }}>
                {roleId == "1" && AppConfigState.delegationEnabled && (
                  <Button onClick={this.onBulkUpdateDelegationStatus} className="button-mobile">
                    Update Delegation
                  </Button>
                )}

                <Button onClick={this.onClearCriteria} className="button-mobile">
                  {i18n.t('clear')}
                </Button>
              </div>
            </Grid.Column>
            {/* <Grid.Column width={8} textAlign="right">
              { roleId=="1" &&
                <Button onClick={this.onBulkUpdateDelegationStatus}>Update Delegation</Button>
              }
              <Button onClick={this.onShowExportModal} className="button-mobile">Export</Button>
              <Button onClick={this.onClearCriteria} className="button-mobile">Clear</Button>
            </Grid.Column> */}
          </Grid.Row>
        </>
      );
    }

    return (
      <>
        <ReactResizeDetector onResize={this.onResize} />
        <Grid stackable className="fadeIn faster">
          <Grid.Row className="dashboard-beacons-gridRow">
            <Grid.Column width={10} className="beacons-optionsRow">
              <div style={{ display: "inline-block" }} className="pagination-mobile">
                {i18n.t('total-records')}: {DashboardState.totalBeacons}
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
                <Button
                  icon="left chevron"
                  size="tiny"
                  onClick={() => this.onPreviousPageClick()}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
                {i18n.t('page')}:{" "}
                <Input
                  className="beacon-page-number"
                  // style={{width:'60px', height:'30.8px'}}
                  name="currentPage"
                  min={1}
                  type="number"
                  size="small"
                  disabled={DashboardState.isLoading}
                  onChange={this.onInputPageChange}
                  value={inputPage}
                  onBlur={() => this.onSetPageByBlur(nbOfPages)}
                  onKeyPress={(e) => this.keyPressed(e, nbOfPages)}
                />{" "}
                of{" " + nbOfPages + " "}
                <Button
                  icon="right chevron"
                  size="tiny"
                  onClick={() => this.onNextPageClick(nbOfPages)}
                  disabled={DashboardState.isLoading}
                  style={{ color: "red", backgroundColor: "transparent" }}
                />
              </div>

              <div style={{ display: "inline-block" }} className="pagination-mobile">
               {i18n.t('records-per-page')} {"  "}
                <Dropdown
                  inline
                  header="Adjust records per page"
                  className="fadeIn"
                  options={paginationOptions}
                  defaultValue={paginationOptions[0].value}
                  onChange={this.onChangeBeaconPerPage}
                />
                <Button onClick={this.onShowExportModal} size="small" className="button-mobile">
                  {i18n.t('export-records')}
                </Button>
              </div>
            </Grid.Column>

            <Grid.Column width={6} textAlign="right" className="pagination-mobile">
              {canEditBeacon && UserState.currentUserProfile.roleId !== '3' && (
                <Button onClick={this.onAddBeacon} primary size="small" className="button-mobile">
                  {i18n.t('add-beacon')}
                  <Icon name="plus" className="beacons-search-icon" />
                </Button>
              )}
              <Button basic onClick={this.onExpandSearch} size="small" className="button-mobile">
                {i18n.t('search-and-filter')}
                <Icon color="red" name={searchIconClass} className="beacons-search-icon" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          {searchComponents}
          <Grid.Row className="dashboard-beacons-gridRow-last ">
            <Grid.Column width={11} />
            <Grid.Column width={5} textAlign="right">
              {canEditBeacon ? i18n.t('click-the-beacon-hex-id-to-edit') : i18n.t('click-the-beacon-hex-id-to-view')}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <BeaconTable
          isMobile={isMobile}
          beacons={beacons}
          isLoading={isLoading}
          onSearchClick={this.onSearchClick}
          clearFiltersForUser={this.clearFiltersForUser}
          canEditBeacon={canEditBeacon}
          onUpdateDelegationStatus={this.onUpdateDelegationStatus}
          onUpdateSearch={this.onUpdateSearch}
          onUpdateSearchForAccount={this.onUpdateSearchForAccount}
        />

        {showExportModal && (
          <Modal size="tiny" centered={false} open={showExportModal} className="centered" onClose={this.onCloseExportModale} closeIcon>
            <Modal.Content>
              <Header className="modal-header">Choose export format</Header>
              <div className="activityDetail-submitContainer">
                <Form>
                  <Form.Field inline>
                    <Select
                      placeholder="Select"
                      className="export-selectInput"
                      name="exportType"
                      defaultValue={currentExportType}
                      options={exportTypeOptions}
                      onChange={this.onExportTypeChange}
                    />
                  </Form.Field>
                </Form>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button primary disabled={!currentExportType || currentExportType == ""} loading={isExporting} onClick={this.onExportBeaconClick(currentExportType)}>
                Export
              </Button>

              <Button onClick={this.onCloseExportModale}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}

        {showClearConfirm && (
          <Modal centered={false} open={showClearConfirm} size={"tiny"} className="centered">
            <Modal.Content>
              <Header className="modal-header">{i18n.t('warning')}</Header>
              {i18n.t('do-you-want-to-clear-all-fields')}
              <div className="activityDetail-submitContainer">
                <Button className="registerBeacon-submitButton" onClick={this.acceptClear}>
                  {i18n.t('yes')}
                </Button>
                <Button className="registerBeacon-submitButton" onClick={this.cancelClear} primary>
                  {i18n.t('no')}
                </Button>
              </div>
            </Modal.Content>
          </Modal>
        )}

        {showDeleteCriteriaConfirm && (
          <Modal centered={false} open={showDeleteCriteriaConfirm} size={"tiny"} className="centered">
            <Modal.Content>
              <Header className="modal-header">{i18n.t('warning')}</Header>
              {i18n.t('do-you-want-to-delete-this-filter')}
              <div className="activityDetail-submitContainer">
                <Button className="registerBeacon-submitButton" onClick={this.acceptDeleteCriteria}>
                  {i18n.t('yes')}
                </Button>
                <Button className="registerBeacon-submitButton" onClick={this.cancelDeleteCriteria} primary>
                  {i18n.t('no')}
                </Button>
              </div>
            </Modal.Content>
          </Modal>
        )}

        {showExitExportConfirm && (
          <Modal centered={false} open={showExitExportConfirm} size={"tiny"} className="centered">
            <Modal.Content>
              <Header className="modal-header">{i18n.t('warning')}</Header>
              {i18n.t('export-cancelled-by-user')}
              <div className="activityDetail-submitContainer">
                <Button className="registerBeacon-submitButton" onClick={this.acceptExitExport} primary>
                  {i18n.t('ok')}
                </Button>
                {/* <Button className="registerBeacon-submitButton" onClick={this.cancelExitExport} primary>No</Button> */}
              </div>
            </Modal.Content>
          </Modal>
        )}

        {/* delegation status */}
        <Modal size="tiny" centered={false} open={showModalDelegation} onClose={this.onCloseModalDelegation} className="dashboard-beacon-editBeacon" closeIcon>
          <Modal.Header>{delegationHeader}</Modal.Header>
          {delegationSucessMessage != "" || delegationIncompleteMessage != "" ? (
            <>
              <Modal.Content>
                <div>
                  <b>{delegationSucessMessage}</b>
                </div>
                {delegationIncompleteMessage != "" && (
                  <div>
                    <Icon name="times circle" color="red" /> {delegationIncompleteMessage}
                  </div>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button primary onClick={this.onCloseModalDelegation}>
                  {i18n.t('close')}
                </Button>
              </Modal.Actions>
            </>
          ) : (
            <>
              <Modal.Content>
                <Form>
                  <Form.Field inline>
                    <label>Delegation Status: </label>
                    <Select
                      placeholder="Select"
                      className="beaconDetailform-selectInput"
                      name="isDelegated"
                      defaultValue={delegationStatusToUpdate}
                      options={delegationStatusOptions}
                      onChange={this.delegationStatusChange}
                    />
                  </Form.Field>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button primary onClick={this.onSaveDelegationStatus} loading={isSaving}>
                  {i18n.t('save')}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Modal>

        {/* popup for MID is not supported */}
        <Modal centered={false} open={showNotSupportModal} size={"tiny"} className="centered">
          <Modal.Content>
            <Header className="modal-header">WARNING</Header>
            <div className="modal-content hexValidateMessage" dangerouslySetInnerHTML={{ __html: notSupportMessage }} />
            <div className="submitContainer">
              <Button className="submitButton" onClick={this.closeNotSupportModal}>
                {i18n.t('ok')}
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const SearchCriteriaDisplay = ({ criteriaComponents, searchFields, onFieldChange, onCriteriaChange, onDeleteCriteria, onLastEditDateChange /*, filterUsername*/ }) => {
  let filterUsername = DashboardState.filterUsername;
  let chunkedArray = chunkArray(criteriaComponents, 2); //This is just to create rows, turning the set into chunks
  let gridRows = chunkedArray.map((chunk, index) => {
    //for every chunks
    let gridCells = chunk.map((
      criteria,
      index //for every criteria in chunk
    ) => (
      <Grid.Column className="SearchCriteriaDisplay-column" key={index}>
        <SearchCriteria
          criteriaComponents={criteriaComponents}
          defaultValue={criteria.key}
          searchFields={searchFields}
          onFieldChange={onFieldChange}
          currentField={criteria.key}
          criteria={criteria}
          onCriteriaChange={onCriteriaChange}
          onDeleteCriteria={() => onDeleteCriteria(criteria)}
          onLastEditDateChange={onLastEditDateChange}
        />
      </Grid.Column>
    ));

    return <Grid.Row key={index}>{gridCells}</Grid.Row>;
  });

  //Add a row here with search Criteria if filterUsername !== null from props passed from beacon.js
  function onDeleteCriteriaFilter(criteria) {
    //call resetSearchByUsername here in delete function
    onDeleteCriteria(criteria);
  }

  if (filterUsername !== null) {
    let criteria = { key: "ownerUsername", value: filterUsername, text: "Account Owner Username" };
    let filterByUsernameRow = (
      <Grid.Row key={filterUsername}>
        <Grid.Column className="SearchCriteriaDisplay-column" key={filterUsername}>
          <SearchCriteria
            key={filterUsername}
            criteriaComponents={criteriaComponents}
            defaultValue={criteria.key}
            searchFields={searchFields}
            onFieldChange={onFieldChange}
            currentField={criteria.key}
            criteria={criteria}
            onCriteriaChange={onCriteriaChange}
            onDeleteCriteria={() => onDeleteCriteriaFilter(criteria)}
            onLastEditDateChange={onLastEditDateChange}
            preventEdit={true} //not allow edit on this props? default being false.
          />
        </Grid.Column>
      </Grid.Row>
    );
    gridRows.push(filterByUsernameRow);
  }

  return (
    <Grid celled container stackable columns={2} style={{ boxShadow: "none" }}>
      {gridRows}
    </Grid>
  );
};

const SearchCriteria = (props) => {
  let { defaultValue, searchFields, onFieldChange, criteria, onCriteriaChange, onDeleteCriteria, onLastEditDateChange, criteriaComponents, preventEdit } = props;

  const inputFields = [
    "hexId",
    "vehicleName",
    "callSign",
    "MMSI",
    "ownerName",
    "vehicleRegistrationNumber",
    "aircraft24BitAddress",
    "aircraft24BitAddressDecoded",
    "typeApprovalCertificate",
    "ownerUsername",
    "serialNumber",
    "emailAddress",
    "accountOwnerName",
  ];
  const beaconTypeOptions = [
    // { key: "0", value: "", text: "Select" },
    { key: "EPIRB", value: "EPIRB", text: "EPIRB" },
    { key: "ELT", value: "ELT", text: "ELT" },
    { key: "PLB", value: "PLB", text: "PLB" },
  ];
  const countryNameOptions = AppConfigState.countryNames.sort((a, b) => a.text.localeCompare(b.text));
  const countryCodeOptions = AppConfigState.countryNamesWithCode.sort((a, b) => a.key.localeCompare(b.key));
  const lastEditOptions = [{ key: "Exact", value: "Exact", text: "Exact" }, { key: "Before", value: "Before", text: "Before" }, { key: "After", value: "After", text: "After" }];
  let dropDownOptions = [];
  switch (criteria.key) {
    case "beaconType":
      dropDownOptions = beaconTypeOptions;
      break;
    case "specialStatus":
      dropDownOptions = AppConfigState.beaconStatusOption;
      break;
    case "countryName":
      dropDownOptions = countryNameOptions;
      break;
    case "beaconCountryCode":
      dropDownOptions = countryCodeOptions;
      break;
    case "lastEditDate":
      dropDownOptions = lastEditOptions;
      break;
  }

  let filteredSearchFields = searchFields.filter((field) => {
    let criteriaComponentIndex = criteriaComponents.findIndex((component) => component.key === field.key);
    if (criteriaComponentIndex !== -1) {
      return false;
    } else {
      return true;
    }
  });
  let currentlySelectedField = searchFields.find((field) => field.key === criteria.key);
  // filteredSearchFields.push(currentlySelectedField);
  filteredSearchFields.unshift(currentlySelectedField); // Fixed: scroll top first

  return (
    <div className="beacon-searchCriteria">
      <Dropdown
        value={criteria.key || ""}
        selection
        options={filteredSearchFields}
        onChange={onCriteriaChange}
        style={{ marginRight: "5px" }}
        criteria={criteria}
        className="beacon-searchCriteria-field"
        disabled={preventEdit}
      />
      {inputFields.includes(criteria.key) ? (
        <Input onChange={onFieldChange} criteria={criteria} value={criteria.value} className="beacon-searchCriteria-input" disabled={preventEdit} />
      ) : (
        ""
      )}
      {criteria.key == "beaconType" ? (
        <div className="beacon-searchCriteria-input">
          <Dropdown
            selection
            options={beaconTypeOptions}
            onChange={onFieldChange}
            criteria={criteria}
            clearable
            className="beacon-searchCriteria-inputDropdown"
            value={criteria.value}
            placeholder={i18n.t('select')}
          />{" "}
        </div>
      ) : (
        ""
      )}
      {criteria.key == "specialStatus" ? (
        <div className="beacon-searchCriteria-input">
          <Dropdown
            selection
            options={AppConfigState.beaconStatusOption}
            onChange={onFieldChange}
            criteria={criteria}
            clearable
            className="beacon-searchCriteria-inputDropdown"
            value={criteria.value}
            placeholder={i18n.t('select')}
          />{" "}
        </div>
      ) : (
        ""
      )}
      {criteria.key == "countryName" ? (
        <div className="beacon-searchCriteria-input">
          <Dropdown
            selection
            options={countryNameOptions}
            onChange={onFieldChange}
            criteria={criteria}
            clearable
            search
            className="beacon-searchCriteria-inputDropdown"
            value={criteria.value}
            placeholder={i18n.t('select')}
          />{" "}
        </div>
      ) : (
        ""
      )}
      {criteria.key == "beaconCountryCode" ? (
        <div className="beacon-searchCriteria-input">
          <Dropdown
            selection
            options={countryCodeOptions}
            onChange={onFieldChange}
            criteria={criteria}
            clearable
            search
            className="beacon-searchCriteria-inputDropdown"
            value={criteria.value}
            placeholder={i18n.t('select')}
          />{" "}
        </div>
      ) : (
        ""
      )}
      {criteria.key == "lastEditDate" ? (
        <div className="beacon-searchCriteria-input">
          <Dropdown
            selection
            options={lastEditOptions}
            onChange={onFieldChange}
            value={criteria.value || ""}
            criteria={criteria}
            className="beacon-searchCriteria-inputDropdown"
            value={criteria.value}
            placeholder={i18n.t('select')}
          />
          <DateInput
            closable
            icon=""
            value={criteria.dateValue || ""}
            onChange={onLastEditDateChange}
            dateFormat="YYYY/MM/DD"
            className="date-picker-inline beacon-searchCriteria-inputDropdown"
            criteria={criteria}
            style={{ width: "100%" }}
            placeholder={i18n.t('select-date')}
          />
        </div>
      ) : (
        ""
      )}
      {criteria.key == "lastConfirmationDate" ? (
        <div className="beacon-searchCriteria-input">
          <Dropdown
            selection
            options={lastEditOptions}
            onChange={onFieldChange}
            value={criteria.value || ""}
            criteria={criteria}
            className="beacon-searchCriteria-inputDropdown"
            placeholder={i18n.t('select')}
          />
          <DateInput
            closable
            icon=""
            value={criteria.dateValue || ""}
            onChange={onLastEditDateChange}
            dateFormat="YYYY/MM/DD"
            className="date-picker-inline beacon-searchCriteria-inputDropdown"
            criteria={criteria}
            style={{ width: "100%" }}
            placeholder={i18n.t('select-date')}
          />
        </div>
      ) : (
        ""
      )}

      <Label
        color="red"
        floating
        className="beacon-searchCriteria-closeButton"
        onClick={() => {
          onDeleteCriteria(criteria);
        }}
      >
        <Icon name="delete" />
      </Label>
    </div>
  );
};

@observer
class BeaconTable extends Component {
  state = {
    showModal: false,
    editBeacon: null,
    showModalStatus: false,
    isSaving: false,
    showExitModal: false,
    maintenanceStatus: false,
    isShowOwnerPopup: false,
    isShowNdpPopup: false,
    isShowAccountPopup: false,
  };

  onBeaconClick = (beacon) => {
    RegistrationState.resetSteps();

    if (UserState.notAllowToEditByRoleId.includes(UserState.currentUserProfile.roleId) || UserState.isAdmin()) {
      RegistrationState.setCurrentStep(4);
      RegistrationState.setIsPrintCertificate(true);
      RegistrationState.setIsPrintSummary(true);
    }

    RegistrationState.setIsNewRegisterBeacon(false);
    this.setState({
      showModal: true,
      editBeacon: beacon,
    });
    RegistrationState.setDecodedBeacon(beacon);
    RegistrationState.setIsBeaconSaved(false);
  };

  onAdminBeaconClick = (beacon) => {
    this.onCloseModal();
    RegistrationState.setIsNewRegisterBeacon(false);
    this.setState({
      showModal: true,
      editBeacon: beacon,
    });
    RegistrationState.setDecodedBeacon(beacon);
    RegistrationState.setIsBeaconSaved(false);
  };

  handleSort = (clickedColumn) => () => {
    if (clickedColumn === DashboardState.column) {
      DashboardState.direction = DashboardState.direction === "descending" ? "ascending" : "descending";
    }
    DashboardState.handleSort(clickedColumn);
  };

  onCloseModal = () => {
    if (!RegistrationState.isBeaconSaved) {
      if (this.props.canEditBeacon && !RegistrationState.isPrintCertificate) {
        this.setState({
          showExitModal: true,
        });
      } else {
        this.setState({
          showModal: false,
        });
        RegistrationState.setIsPrintCertificate(false);
        RegistrationState.resetBeaconData();
      }
    } else {
      this.setState({
        showModal: false,
      });
      RegistrationState.resetBeaconData();
      this.props.onSearchClick();
    }
    // RegistrationState.setIsPrintSummary(false);
    if (RegistrationState.isUpdateLastEditDate) {
      this.props.onSearchClick();
      RegistrationState.setIsUpdateLastEditDate(false);
    }
    // RegistrationState.resetBeaconData();
  };

  onEditSpecialStatus = (beacon) => {
    RegistrationState.resetSteps();
    RegistrationState.setIsNewRegisterBeacon(false);
    this.setState({
      showModalStatus: true,
      editBeacon: { ...beacon, ...{ newSpecialStatus: beacon.specialStatus || "ACTIVE", newMaintProvider: beacon.maintProvider || "" } },
      maintenanceStatus: beacon.specialStatus && beacon.specialStatus == "RELEASETOMAINTENANCE",
    });
  };

  onCloseModalStatus = () => {
    this.setState({
      showModalStatus: false,
      isSaving: false,
    });
  };

  statusChange = (e, data) => {
    let updatedStatus = { ...this.state.editBeacon, ...{ newSpecialStatus: data.value } };
    this.setState({
      editBeacon: updatedStatus,
      maintenanceStatus: data.value == "RELEASETOMAINTENANCE",
    });
    // RegistrationState.setDecodedBeacon(updatedStatus)
  };

  maintProviderChange = (e, data) => {
    let updatedStatus = { ...this.state.editBeacon, ...{ newMaintProvider: data.value } };
    this.setState({
      editBeacon: updatedStatus,
    });
  };

  onSpecialStatusInfoChange = (e, data) => {
    let updatedStatus = { ...this.state.editBeacon, ...{ specialStatusInfo: data.value } };
    this.setState({
      editBeacon: updatedStatus,
    });
  };

  onSaveSpecialStatus = () => {
    this.setState({ isSaving: true });
    let db = dbService();
    let dataStatus = {
      specialStatus: this.state.editBeacon.newSpecialStatus,
      previousBeaconStatus: this.state.editBeacon.specialStatus || "ACTIVE",
      specialStatusDate: new Date(),
      initialDate: new Date(),
      maintProvider: this.state.editBeacon.newSpecialStatus == "RELEASETOMAINTENANCE" ? this.state.editBeacon.newMaintProvider : this.state.editBeacon.maintProvider || "",
    };
    let updatedStatus = { ...this.state.editBeacon, ...dataStatus };

    // save section
    delete updatedStatus.newSpecialStatus;
    delete updatedStatus.newMaintProvider;

    RegistrationState.setDecodedBeacon(updatedStatus);
    RegistrationState.saveBeacon(true).then(() => {
      this.setState({ isSaving: false });
      this.onCloseModalStatus();
      DashboardState.searchBeacons({}).then(() => {});
    });
  };

  onPrintCertificate = (beacon) => {
    RegistrationState.setCurrentStep(4);
    RegistrationState.setIsNewRegisterBeacon(false);
    RegistrationState.setDecodedBeacon(beacon);
    RegistrationState.setIsPrintCertificate(true);
    this.setState({
      showModal: true,
      editBeacon: beacon,
    });
  };

  onModalCancel = () => {
    this.setState({
      showExitModal: false,
    });
  };

  onModalAccept = () => {
    this.setState({
      showExitModal: false,
    });
    // exit beacon edit
    RegistrationState.setIsPrintCertificate(false);
    this.setState({
      showModal: false,
    });
    if (RegistrationState.isBeaconSaved) {
      this.props.onSearchClick();
      RegistrationState.setIsBeaconSaved(false);
    }
    RegistrationState.resetBeaconData();
  };

  onOwnerClick = (beacon) => {
    this.setState({ isShowOwnerPopup: true, editBeacon: beacon });
  };

  onCloseOwnerPopup = () => {
    this.setState({ isShowOwnerPopup: false });
  };

  onNdpClick = (beacon) => {
    this.setState({ isShowNdpPopup: true, editBeacon: beacon });
  };

  onCloseNdpPopup = () => {
    this.setState({ isShowNdpPopup: false });
  };

  onAccountClick = (beacon) => {
    this.setState({ isShowAccountPopup: true, editBeacon: beacon });
  };

  onCloseAccountPopup = () => {
    this.setState({ isShowAccountPopup: false });
  };

  render() {
    const { beacons, isLoading, onSearchClick, clearFiltersForUser, canEditBeacon, onUpdateDelegationStatus, isMobile, onUpdateSearch, onUpdateSearchForAccount } = this.props;
    const { showModal, editBeacon, showModalStatus, isSaving, showExitModal, maintenanceStatus, isShowOwnerPopup, isShowNdpPopup, isShowAccountPopup } = this.state;
    const column = DashboardState.column;
    const direction = DashboardState.direction;
    const statusOptions = AppConfigState.beaconStatusOption.sort((a, b) => a.text.localeCompare(b.text));
    let maintProviderOptions = UserState.maintProviderOptions;
    let roleId = UserState.currentUserProfile.roleId;

    let roleIdForBriefCertificate = UserState.roleIdForBriefCertificate;
    let isBriefCerUser = roleIdForBriefCertificate.includes(UserState.currentUserProfile.roleId) ? true : false;

    let editButton = null;
    if (RegistrationState.isPrintSummary && UserState.isAdmin()) {
      editButton = (
        <Button floated="right" primary icon onClick={() => this.onAdminBeaconClick(RegistrationState.decodedBeacon)}>
          <Icon name="edit" />
         {i18n.t('edit-beacon')}
        </Button>
      );
    }

    return (
      <>
        <Segment>
          {/* <Dimmer active={isLoading} inverted> */}
          <Dimmer active={DashboardState.isLoading} inverted>
            <Loader>{i18n.t('loading')}</Loader>
          </Dimmer>
          <div className="sidescroll">
            <Table celled striped selectable sortable className="beacon-mobile">
              <Table.Header className="contactList-tableHeader">
                <Table.Row>
                  {UserState.currentUserProfile && UserState.currentUserProfile.roleId == "1" && AppConfigState.delegationEnabled && (
                    <>
                      <Table.HeaderCell className="dashboard-beacon-tableHeader">
                        <Checkbox value="selectAll" checked={DashboardState.selectAll} onChange={DashboardState.toggleSelectAll} />
                      </Table.HeaderCell>
                      <Table.HeaderCell className="dashboard-beacon-tableHeader">Delegation Status</Table.HeaderCell>
                    </>
                  )}
                  <Table.HeaderCell className="dashboard-beacon-tableHeader" />
                  <Table.HeaderCell sorted={column === "hexId" ? direction : null} onClick={this.handleSort("hexId")} className="dashboard-beacon-tableHeader">
                   {i18n.t('beacon-hex-id')}
                  </Table.HeaderCell>
                  <Table.HeaderCell sorted={column === "beaconType" ? direction : null} onClick={this.handleSort("beaconType")} className="dashboard-beacon-tableHeader">
                    {i18n.t('type')}
                  </Table.HeaderCell>
                  <Table.HeaderCell sorted={column === "specialStatus" ? direction : null} onClick={this.handleSort("specialStatus")} className="dashboard-beacon-tableHeader">
                    {i18n.t('status')}
                  </Table.HeaderCell>
                  <Table.HeaderCell sorted={column === "vehicleType" ? direction : null} onClick={this.handleSort("vehicleType")} className="dashboard-beacon-tableHeader">
                    {i18n.t('vehicle')}
                  </Table.HeaderCell>
                  {UserState.currentUserProfile && UserState.currentUserProfile.roleId != "2" && (
                    <Table.HeaderCell sorted={column === "ownerName" ? direction : null} onClick={this.handleSort("ownerName")} className="dashboard-beacon-tableHeader">
                      {i18n.t('beacon-owner')}
                    </Table.HeaderCell>
                  )}
                  {UserState.currentUserProfile && managerId.includes(UserState.currentUserProfile.roleId) && (
                    <Table.HeaderCell
                      /*sorted={column === "accountOwnerName" ? direction : null} onClick={this.handleSort("accountOwnerName")}*/ className="dashboard-beacon-tableHeader"
                      style={{ cursor: "initial" }}
                    >
                      {i18n.t('account-owner')}
                    </Table.HeaderCell>
                  )}
                  {UserState.currentUserProfile && ["3", "4"].includes(UserState.currentUserProfile.roleId) && AppConfigState.delegationEnabled && (
                    <Table.HeaderCell
                      /*sorted={column === "delegatedBy" ? direction : null} onClick={this.handleSort("delegatedBy")}*/ className="dashboard-beacon-tableHeader"
                      style={{ cursor: "initial" }}
                    >
                      {i18n.t('delegated-by-ndp')}
                    </Table.HeaderCell>
                  )}
                  {UserState.currentUserProfile && ["3", "4"].includes(UserState.currentUserProfile.roleId) && (
                    <Table.HeaderCell sorted={column === "username" ? direction : null} onClick={this.handleSort("username")} className="dashboard-beacon-tableHeader">
                      {i18n.t('account-owner-username')}
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell sorted={column === "lastEditDate" ? direction : null} onClick={this.handleSort("lastEditDate")} className="dashboard-beacon-tableHeader">
                    {i18n.t('last-edit-date')}
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={column === "aircraft24BitAddress" ? direction : null}
                    onClick={this.handleSort("aircraft24BitAddress")}
                    className="dashboard-beacon-tableHeader"
                  >
                    {i18n.t('aircraft-24-bit-address')}
                    <br />
                    {i18n.t('decoded-24-bit-address')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "typeApprovalCertificate" ? direction : null}
                    onClick={this.handleSort("typeApprovalCertificate")}
                    className="dashboard-beacon-tableHeader"
                  >
                   {i18n.t('beacon-c-s-type-approval-number')}
                  </Table.HeaderCell>
                  <Table.HeaderCell sorted={column === "serialNumber" ? direction : null} onClick={this.handleSort("serialNumber")} className="dashboard-beacon-tableHeader">
                    {i18n.t('beacon-serial-number')}
                  </Table.HeaderCell>

                  <Table.HeaderCell sorted={column === "countryName" ? direction : null} onClick={this.handleSort("countryName")} className="dashboard-beacon-tableHeader">
                    {i18n.t('country-name')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "beaconCountryCode" ? direction : null}
                    onClick={this.handleSort("beaconCountryCode")}
                    className="dashboard-beacon-tableHeader"
                  >
                    {i18n.t('country-code')}
                  </Table.HeaderCell>

                  <Table.HeaderCell sorted={column === "vehicleName" ? direction : null} onClick={this.handleSort("vehicleName")} className="dashboard-beacon-tableHeader">
                    {i18n.t('vehicle-aircraft-name')}
                  </Table.HeaderCell>
                  <Table.HeaderCell sorted={column === "callSign" ? direction : null} onClick={this.handleSort("callSign")} className="dashboard-beacon-tableHeader">
                    {i18n.t('radio-call-sign')}
                    <br />
                    {i18n.t('decoded-call-sign')}
                  </Table.HeaderCell>
                  <Table.HeaderCell sorted={column === "MMSI" ? direction : null} onClick={this.handleSort("MMSI")} className="dashboard-beacon-tableHeader">
                    {i18n.t('mmsi-number')}
                    <br />
                    {i18n.t('decoded-mmsi')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "vehicleRegistrationNumber" ? direction : null}
                    onClick={this.handleSort("vehicleRegistrationNumber")}
                    className="dashboard-beacon-tableHeader"
                  >
                    {i18n.t('vehicle-registration-number')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "lastConfirmationDate" ? direction : null}
                    onClick={this.handleSort("lastConfirmationDate")}
                    className="dashboard-beacon-tableHeader"
                  >
                    {i18n.t('last-confirmation-date')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {beacons.map((beacon) => (
                  <TableRow
                    key={beacon._id}
                    beacon={beacon}
                    isMobile={isMobile}
                    onClick={this.onBeaconClick}
                    onEditSpecialStatus={this.onEditSpecialStatus}
                    onPrintCertificate={this.onPrintCertificate}
                    canEditBeacon={canEditBeacon}
                    onUpdateDelegationStatus={onUpdateDelegationStatus}
                    onOwnerClick={this.onOwnerClick}
                    onNdpClick={this.onNdpClick}
                    onAccountClick={this.onAccountClick}
                  />
                ))}
              </Table.Body>
            </Table>
          </div>
          <EmptyStatePlaceHolder beacons={beacons} />
        </Segment>
        {showModal && (
          <Modal id="editBeaconModal" centered={false} open={showModal} onClose={this.onCloseModal} closeOnDimmerClick={true} className="dashboard-beacon-editBeacon" closeIcon>
            {RegistrationState.isPrintCertificate ? (
              <Modal.Header>
                {i18n.t('beacon-certificate')}{" "}{editButton}
             {" "}
              </Modal.Header>
            ) : RegistrationState.currentStep === 3 ? (
              <Modal.Header>{i18n.t('beacon-details')}</Modal.Header>
            ) : (
              <Modal.Header>{i18n.t('edit-beacon-details')}</Modal.Header>
            )}
            <Modal.Content className="dashboard-beacon-editBeacon-content">
              {/* <RegisterBeacon beacon={editBeacon} onClose={this.onCloseModal} onDashboardEdit={true} /> */}
              <RegisterBeacon
                beacon={RegistrationState.decodedBeacon}
                onClose={this.onCloseModal}
                onDashboardEdit={true}
                onSearchClick={onSearchClick}
                clearFiltersForUser={clearFiltersForUser}
                onUpdateSearch={onUpdateSearchForAccount}
                onAdminBeaconClick={this.onAdminBeaconClick}
              />
            </Modal.Content>
          </Modal>
        )}

        {showExitModal && (
          <Modal size="tiny" centered={false} open={showExitModal} className="centered">
            <Modal.Content>
              <Header className="modal-header">WARNING</Header>
              {i18n.t('do-you-want-to-cancel-editing-and-return-to-the-dashboard')}
              <div className="activityDetail-submitContainer">
                <Button className="registerBeacon-submitButton" onClick={this.onModalAccept}>
                  {i18n.t('yes')}
                </Button>
                <Button className="registerBeacon-submitButton" onClick={this.onModalCancel} primary>
                  {i18n.t('no')}
                </Button>
              </div>
            </Modal.Content>
          </Modal>
        )}

        {/* edit special status */}
        {showModalStatus && (
          <Modal size="tiny" centered={false} open={showModalStatus} onClose={this.onCloseModalStatus} className="dashboard-beacon-editBeacon" closeIcon>
            <Modal.Header>Edit beacon status</Modal.Header>
            <Modal.Content>
              {/* <RegisterBeacon beacon={editBeacon} onClose={this.onCloseModalStatus} /> */}
              {roleId == "7" && <span style={{ color: "red" }}>{i18n.t('warning-once-you-click-save-you-will-no-longer-have-access-to-view-or-edit-the-beacon-record')}</span>}
              <Form>
                <Form.Field inline>
                  <label>{i18n.t('special-status')}: </label>
                  <Select
                    placeholder="Select"
                    className="beaconDetailform-selectInput"
                    name="specialStatus"
                    // value={editBeacon && editBeacon.specialStatus ? editBeacon.specialStatus : 'ACTIVE'}
                    defaultValue={editBeacon && editBeacon.specialStatus ? editBeacon.specialStatus : "ACTIVE"}
                    options={statusOptions}
                    onChange={this.statusChange}
                  />
                </Form.Field>
                {["1", "3"].includes(roleId) && ( // for admin & NDP
                  <Form.Field inline>
                    <label>{i18n.t('special-status-info')}:</label>
                    <Input
                      placeholder="Special Status Info"
                      className="beaconDetailform-selectInput"
                      name="specialStatusInfo"
                      defaultValue={editBeacon && editBeacon.specialStatusInfo ? editBeacon.specialStatusInfo : ""}
                      onChange={this.onSpecialStatusInfoChange}
                    />
                  </Form.Field>
                )}
                {!maintenanceStatus ? (
                  ""
                ) : (
                  <Form.Field inline>
                    <label>{i18n.t('maintenance-provider')}</label>
                    <Select
                      placeholder="Select"
                      className="beaconDetailform-selectInput"
                      name="maintProvider"
                      //value={editBeacon && editBeacon.maintProvider ? editBeacon.maintProvider : "" }
                      defaultValue={editBeacon && editBeacon.maintProvider ? editBeacon.maintProvider : ""}
                      options={maintProviderOptions}
                      onChange={this.maintProviderChange}
                    />
                  </Form.Field>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.onSaveSpecialStatus} loading={isSaving}>
                {i18n.t('save')}
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        {/* Owner detail */}
        {isShowOwnerPopup && (
          <Modal
            id="ownerPopup"
            centered={false}
            open={isShowOwnerPopup}
            onClose={this.onCloseOwnerPopup}
            closeOnDimmerClick={false}
            className="dashboard-beacon-editBeacon"
            closeIcon
          >
            <Modal.Header>{i18n.t('beacon-owner')}</Modal.Header>
            <Modal.Content className="dashboard-beacon-editBeacon-content">
              <OwnerDetail beacon={editBeacon} onClose={this.onCloseOwnerPopup} onUpdateSearch={onUpdateSearch} />
            </Modal.Content>
          </Modal>
        )}

        {/* NDP detail */}
        {isShowNdpPopup && (
          <Modal id="napPopup" centered={false} open={isShowNdpPopup} onClose={this.onCloseNdpPopup} closeOnDimmerClick={false} className="dashboard-beacon-editBeacon" closeIcon>
            <Modal.Header>{i18n.t('ndp-owner')}</Modal.Header>
            <Modal.Content className="dashboard-beacon-editBeacon-content">
              <NdpDetail beacon={editBeacon} onClose={this.onCloseNdpPopup} />
            </Modal.Content>
          </Modal>
        )}

        {/* Account detail */}
        {isShowAccountPopup && (
          <Modal
            id="accountPopup"
            centered={false}
            open={isShowAccountPopup}
            onClose={this.onCloseAccountPopup}
            closeOnDimmerClick={false}
            className="dashboard-beacon-editBeacon"
            closeIcon
          >
            <Modal.Header>{i18n.t('account-owner')}</Modal.Header>
            <Modal.Content className="dashboard-beacon-editBeacon-content">
              <AccountDetail beacon={editBeacon} onClose={this.onCloseAccountPopup} onUpdateSearch={onUpdateSearchForAccount} />
            </Modal.Content>
          </Modal>
        )}
      </>
    );
  }
}

const EmptyStatePlaceHolder = ({ beacons }) => {
  if (beacons && beacons.length > 0) {
    return null;
  }

  return (
    <Header as="h2" icon textAlign="center">
      <Icon name="file outline" circular />
      <Header.Content>{i18n.t('no-beacon-found')}</Header.Content>
    </Header>
  );
};

const TableRow = observer(
  ({ beacon, onClick, onEditSpecialStatus, onPrintCertificate, canEditBeacon, onUpdateDelegationStatus, isMobile, onOwnerClick, onNdpClick, onAccountClick }) => {
    let aircraft24BitAddress = beacon.aircraft24BitAddress;
    if (beacon.aircraft24BitAddressDecoded) {
      aircraft24BitAddress = beacon.aircraft24BitAddress + "\n(" + beacon.aircraft24BitAddressDecoded + ")";
      aircraft24BitAddress = aircraft24BitAddress.split("\n").map((item, i) => {
        return <div key={i}>{item}</div>;
      });
    }

    // let MMSI  = beacon.MMSI
    // if(beacon.MMSI !== beacon.MMSIDecoded){
    //   MMSI = beacon.MMSI + " (" + beacon.MMSIDecoded + ")"
    // }
    let MMSI = beacon.MMSI;
    if (beacon.MMSIDecoded && beacon.MMSIDecoded != "NULL") {
      if (beacon.MMSIDecoded.length <= 6) {
        MMSI = beacon.MMSI + "\n(" + beacon.beaconCountryCode + ("000000" + beacon.MMSIDecoded).slice(-6) + ")";
      } else {
        MMSI = beacon.MMSI + "\n(" + beacon.MMSIDecoded + ")";
      }
      MMSI = MMSI.split("\n").map((item, i) => {
        return <div key={i}>{item}</div>;
      });
    }

    let callSign = beacon.callSign;
    if (beacon.callSignDecoded) {
      callSign = beacon.callSign + "\n(" + beacon.callSignDecoded + ")";
      callSign = callSign.split("\n").map((item, i) => {
        return <div key={i}>{item}</div>;
      });
    }

    let beaconDelegateContent = null;

    if (beacon.isDelegated === "false" || beacon.isDelegated === false) {
      beaconDelegateContent = <span>NDP Managed</span>;
    } else if (beacon.delegationIncomplete) {
      beaconDelegateContent = (
        <span>
          <Icon title="NDP Delegated Incomplete" name="close" color="red" /> NDP Delegated Incomplete
        </span>
      );
    } else if (beacon.delegateConfirmDate) {
      beaconDelegateContent = (
        <span>
          <Icon title="NDP Delegated" name="check circle" color="green" /> NDP Delegated
        </span>
      );
    } else {
      beaconDelegateContent = (
        <span>
          <Icon title="NDP Delegated Pending" name="check circle" color="yellow" /> NDP Delegated Pending
        </span>
      );
    }

    let username = beacon.owner ? (beacon.owner.includes("org.couchdb.user:") ? beacon.owner.split(":")[1] : beacon.owner) : null;

    let delegatedBy =
      beacon.createdByNdp && beacon.isDelegated ? (beacon.createdByNdp.includes("org.couchdb.user:") ? beacon.createdByNdp.split(":")[1] : beacon.createdByNdp) : null;

    return (
      <Table.Row key={beacon._id}>
        {!isMobile ? (
          <>
            {UserState.currentUserProfile && UserState.currentUserProfile.roleId == "1" && AppConfigState.delegationEnabled && (
              <>
                <Table.Cell disabled={!AppConfigState.delegationEnabledByType(beacon)}>
                  <Checkbox
                    value={beacon._id}
                    checked={DashboardState.selectedBeacons.find((x) => x.id == beacon._id) ? DashboardState.selectedBeacons.find((x) => x.id == beacon._id).checked : false}
                    onChange={DashboardState.toggleSelectItem}
                    disabled={!AppConfigState.delegationEnabledByType(beacon)}
                  />
                </Table.Cell>
                <Table.Cell disabled={!AppConfigState.delegationEnabledByType(beacon)}>
                  <a
                    className="dashboard-beacon-resultCell noprint dashboard-beacon-resultCell-link"
                    onClick={() => {onUpdateDelegationStatus(beacon)}}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    {beaconDelegateContent}
                  </a>
                </Table.Cell>
              </>
            )}

            <Table.Cell>
              <a className="dashboard-beacon-resultCell noprint" onClick={() => onPrintCertificate(beacon)} style={{ cursor: "pointer", textDecoration: "none" }}>
                <Icon name="print" color="grey" size="large" />
              </a>
            </Table.Cell>

            <Table.Cell className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onClick(beacon)} style={{ cursor: "pointer" }}>
              {beacon.hexId || ""}
            </Table.Cell>

            <Table.Cell>{beacon.beaconType}</Table.Cell>

            {canEditBeacon ? (
              <Table.Cell className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onEditSpecialStatus(beacon)} style={{ cursor: "pointer" }}>
                {BeaconUtils.specialStatusToText(beacon.specialStatus) || "Active"}
              </Table.Cell>
            ) : (
              <Table.Cell>{BeaconUtils.specialStatusToText(beacon.specialStatus) || "Active"}</Table.Cell>
            )}

            <Table.Cell>{beacon.vehicleType || ""}</Table.Cell>

            {/* {UserState.currentUserProfile && UserState.currentUserProfile.roleId != "2" && <Table.Cell className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onOwnerClick(beacon)}>{beacon.ownerName || ""}</Table.Cell>} */}

            {UserState.currentUserProfile &&
              UserState.currentUserProfile.roleId != "2" &&
              (["3", "4"].includes(UserState.currentUserProfile.roleId) && beacon.ownerName ? (
                <Table.Cell className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onOwnerClick(beacon)}>
                  {beacon.ownerName || ""}
                </Table.Cell>
              ) : (
                <Table.Cell>{beacon.ownerName || ""}</Table.Cell>
              ))}

            {UserState.currentUserProfile &&
              managerId.includes(UserState.currentUserProfile.roleId) &&
              (beacon.accountOwnerName ? (
                <Table.Cell className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onAccountClick(beacon)}>
                  {beacon.accountOwnerName || ""}
                </Table.Cell>
              ) : (
                <Table.Cell className="dashboard-beacon-resultCell">{beacon.accountOwnerName || ""}</Table.Cell>
              ))}

            {UserState.currentUserProfile &&
              ["3", "4"].includes(UserState.currentUserProfile.roleId) &&
              (delegatedBy ? (
                <Table.Cell
                  className="dashboard-beacon-resultCell dashboard-beacon-resultCell-link"
                  onClick={() => {
                    onNdpClick(beacon);
                  }}
                >
                  {delegatedBy || ""}
                </Table.Cell>
              ) : (
                <Table.Cell className="dashboard-beacon-resultCell">{delegatedBy || ""}</Table.Cell>
              ))}

            {/*(UserState.currentUserProfile && ["3", "4"].includes(UserState.currentUserProfile.roleId)) && <Table.Cell>{username || ""}</Table.Cell>*/}

            <Table.Cell>{Utils.dateFormatWithTime(beacon.lastEditDate) || ""}</Table.Cell>
            <Table.Cell>{aircraft24BitAddress || ""}</Table.Cell>
            <Table.Cell>{beacon.typeApprovalCertificate || ""}</Table.Cell>
            <Table.Cell>{beacon.serialNumber || ""}</Table.Cell>
            <Table.Cell>{beacon.countryName  || AppConfigState.getCountryNameByMid(beacon.beaconCountryCode) || ""}</Table.Cell>
            <Table.Cell>{beacon.beaconCountryCode || ""}</Table.Cell>
            <Table.Cell>{beacon.vehicleName || ""}</Table.Cell>
            <Table.Cell>{callSign || ""}</Table.Cell>
            <Table.Cell>{MMSI || ""}</Table.Cell>
            <Table.Cell>{beacon.vehicleRegistrationNumber || ""}</Table.Cell>
            <Table.Cell>{Utils.dateFormatWithTime(beacon.lastConfirmationDate) || ""}</Table.Cell>
          </>
        ) : (
          <>
            {UserState.currentUserProfile && UserState.currentUserProfile.roleId == "1" && AppConfigState.delegationEnabled && (
              <>
                <Table.Cell className="contactList-cell">
                  <div className="contactList-cell-mobileHeader">Select</div>
                  <div className="contactList-cell-content">
                    <Checkbox
                      value={beacon._id}
                      checked={DashboardState.selectedBeacons.find((x) => x.id == beacon._id) ? DashboardState.selectedBeacons.find((x) => x.id == beacon._id).checked : false}
                      onChange={DashboardState.toggleSelectItem}
                      disabled={!AppConfigState.delegationEnabledByType(beacon)}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell className="contactList-cell">
                  <div className="contactList-cell-mobileHeader">{i18n.t('status')}</div>
                  <div className="contactList-cell-content">
                    <a
                      className="dashboard-beacon-resultCell noprint dashboard-beacon-resultCell-link"
                      onClick={() => {onUpdateDelegationStatus(beacon)}}
                      style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                      {/* {!beacon.isDelegated ? (
                        <span>NDP Managed</span>
                      ) : beacon.delegationIncomplete ? (
                        <span>
                          <Icon title="NDP Delegated Incomplete" name="close" color="red" /> NDP Delegated Incomplete
                        </span>
                      ) : beacon.delegateConfirmDate ? (
                        <span>
                          <Icon title="NDP Delegated" name="check circle" color="green" /> NDP Delegated
                        </span>
                      ) : (
                        <span>
                          <Icon title="NDP Delegated Pending" name="check circle" color="yellow" /> NDP Delegated Pending
                        </span>
                      )} */}
                      
                      {beaconDelegateContent}
                    </a>
                  </div>
                </Table.Cell>
              </>
            )}
            <Table.Cell className="contactList-cell">
              <div className="contactList-cell-mobileHeader">{i18n.t('print')}</div>
              <div className="contactList-cell-content">
                <a className="dashboard-beacon-resultCell noprint" onClick={() => onPrintCertificate(beacon)} style={{ cursor: "pointer", textDecoration: "none" }}>
                  <Icon name="print" color="grey" size="large" />
                </a>
              </div>
            </Table.Cell>
            <Table.Cell className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onClick(beacon)} style={{ cursor: "pointer" }}>
              <div className="contactList-cell-mobileHeader">{i18n.t('hex-id')}</div>
              <div className="contactList-cell-content">{beacon.hexId || ""}</div>
            </Table.Cell>
            {beacon.beaconType && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('type')}</div>
                <div className="contactList-cell-content">{beacon.beaconType}</div>
              </Table.Cell>
            )}

            {canEditBeacon ? (
              <Table.Cell
                className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link"
                onClick={() => onEditSpecialStatus(beacon)}
                style={{ cursor: "pointer" }}
              >
                <div className="contactList-cell-mobileHeader">{i18n.t('status')}</div>
                <div className="contactList-cell-content">{BeaconUtils.specialStatusToText(beacon.specialStatus) || "Active"}</div>
              </Table.Cell>
            ) : (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('status')}</div>
                <div className="contactList-cell-content">{BeaconUtils.specialStatusToText(beacon.specialStatus) || "Active"}</div>
              </Table.Cell>
            )}

            {beacon.vehicleType && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('vehicle')}</div>
                <div className="contactList-cell-content">{beacon.vehicleType || ""}</div>
              </Table.Cell>
            )}

            {UserState.currentUserProfile &&
              UserState.currentUserProfile.roleId != "2" &&
              beacon.ownerName &&
              (["3", "4"].includes(UserState.currentUserProfile.roleId) ? (
                <Table.Cell className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onOwnerClick(beacon)}>
                  <div className="contactList-cell-mobileHeader">{i18n.t('owner')}</div>
                  <div className="contactList-cell-content">{beacon.ownerName || ""}</div>
                </Table.Cell>
              ) : (
                <Table.Cell className="contactList-cell">
                  <div className="contactList-cell-mobileHeader">{i18n.t('owner')}</div>
                  <div className="contactList-cell-content">{beacon.ownerName || ""}</div>
                </Table.Cell>
              ))}

            {UserState.currentUserProfile && managerId.includes(UserState.currentUserProfile.roleId) && beacon.accountOwnerName && (
              <Table.Cell className="contactList-cell dashboard-beacon-resultCell dashboard-beacon-resultCell-link" onClick={() => onAccountClick(beacon)}>
                <div className="contactList-cell-mobileHeader">{i18n.t('account-owner')}</div>
                <div className="contactList-cell-content">{beacon.accountOwnerName || ""}</div>
              </Table.Cell>
            )}

            {UserState.currentUserProfile && ["3", "4"].includes(UserState.currentUserProfile.roleId) && AppConfigState.delegationEnabled && delegatedBy && (
              <Table.Cell className="contactList-cell dashboard-beacon-resultCell">
                <div className="contactList-cell-mobileHeader">{i18n.t('delegated-by-ndp')}</div>
                <div className="contactList-cell-content">
                  <a
                    className="dashboard-beacon-resultCell-link"
                    onClick={() => {
                      onNdpClick(beacon);
                    }}
                  >
                    {delegatedBy || ""}
                  </a>
                </div>
              </Table.Cell>
            )}

            {UserState.currentUserProfile && ["3", "4"].includes(UserState.currentUserProfile.roleId) && username && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('account-owner-username')}</div>
                <div className="contactList-cell-content">{username || ""}</div>
              </Table.Cell>
            )}

            {beacon.lastEditDate && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('last-edit-date')}</div>
                <div className="contactList-cell-content">{Utils.dateFormatWithTime(beacon.lastEditDate) || ""}</div>
              </Table.Cell>
            )}

            {aircraft24BitAddress && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">
                  {i18n.t('24-bit-address')}
                  <br />
                  {i18n.t('decoded')}
                </div>
                <div className="contactList-cell-content">{aircraft24BitAddress || ""}</div>
              </Table.Cell>
            )}

            {beacon.typeApprovalCertificate && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('c-s-number')}</div>
                <div className="contactList-cell-content">{beacon.typeApprovalCertificate || ""}</div>
              </Table.Cell>
            )}

            {beacon.serialNumber && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('serial-number')}</div>
                <div className="contactList-cell-content">{beacon.serialNumber || ""}</div>
              </Table.Cell>
            )}

            {beacon.countryName && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('country-name')}</div>
                <div className="contactList-cell-content">{beacon.countryName  || AppConfigState.getCountryNameByMid(beacon.beaconCountryCode) }</div>
              </Table.Cell>
            )}

            {beacon.beaconCountryCode && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('country-code')}</div>
                <div className="contactList-cell-content">{beacon.beaconCountryCode || ""}</div>
              </Table.Cell>
            )}

            {beacon.vehicleName && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('vehicle-aircraft-name')}</div>
                <div className="contactList-cell-content">{beacon.vehicleName || ""}</div>
              </Table.Cell>
            )}

            {callSign && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">
                  {i18n.t('call-sign')}
                  <br />
                  {i18n.t('decoded')}
                </div>
                <div className="contactList-cell-content">{callSign || ""}</div>
              </Table.Cell>
            )}

            {MMSI && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">
                  {i18n.t('mmsi')}
                  <br />
                  {i18n.t('decoded')}
                </div>
                <div className="contactList-cell-content">{MMSI || ""}</div>
              </Table.Cell>
            )}

            {beacon.vehicleRegistrationNumber && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('reg-number')}</div>
                <div className="contactList-cell-content">{beacon.vehicleRegistrationNumber || ""}</div>
              </Table.Cell>
            )}

            {beacon.lastConfirmationDate && (
              <Table.Cell className="contactList-cell">
                <div className="contactList-cell-mobileHeader">{i18n.t('confirmation-date')}</div>
                <div className="contactList-cell-content">{Utils.dateFormatWithTime(beacon.lastConfirmationDate) || ""}</div>
              </Table.Cell>
            )}
          </>
        )}
      </Table.Row>
    );
  }
);

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
function chunkArray(myArray, chunk_size) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}
