import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./semantic/dist/semantic.min.css";
import { setConfig } from "react-hot-loader";
import AppState from "./state/appState";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as english from "./i18n/en.json";
import * as french from "./i18n/fr.json";
console.log("log ~ file: index.js ~ line 11 ~ english", english);
setConfig({
  ignoreSFC: true,
  pureRender: true,
});

i18n
  .use(LanguageDetector)
  .init({
   
    debug: true,
    fallbackLng: "en",
    resources: {
      en: { translation: english.default },
      fr: { translation: french.default },
    },
  })
  .then(function(t) {
    console.log("log ~ file: index.js ~ line 27 ~ t", t);
    AppState.t = t;
    // initialized and ready to go!
    ReactDOM.render(<App />, document.getElementById("root"));

    i18n.changeLanguage(null, (err, t) => {
      if (err) return console.log(" index.js something went wrong loading", err);
      console.log("*********************  index.js current language:" + i18n.language);
      let shortFormLanguage;
      if(i18n.language.includes("en")){
        shortFormLanguage = "en";
      } else if(i18n.language.includes("fr")){
        shortFormLanguage = "fr";
      }
      AppState.setLanguage(shortFormLanguage);
    });
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.register(); //Temporary disable service working while in dev mode.

var IBRD_CONFIG = {
  "apikey": "19PU3-amHoD4_KBQOv0ozGvONA7nCDZfpiNc3h3lw_Dt",
  "host": "b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix.cloudantnosqldb.appdomain.cloud",
  "iam_apikey_description": "Auto-generated for key 21da7471-8e9b-456a-b698-55c8f60b3237",
  "iam_apikey_name": "ibrdtest",
  "iam_role_crn": "crn:v1:bluemix:public:iam::::serviceRole:Manager",
  "iam_serviceid_crn": "crn:v1:bluemix:public:iam-identity::a/9094160ed5244abe9359eabf56ccdd47::serviceid:ServiceId-0f09e657-0a5a-49a0-9a74-d5f730ce3df5",
  "password": "e1924aec6a94741172d24863587c9525e77bd433b21331d9b19e90c08257d65b",
  "port": 443,
  "url": "https://b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix.cloudantnosqldb.appdomain.cloud",
  "username": "b7ae5786-8abe-4fba-bf17-15cbf21db7ef-bluemix",
  "authActionsEndpoint" : "https://srn99qz2n6.execute-api.us-east-1.amazonaws.com/default/authActions",
  "sendMailEndpoint": "https://od8adksff4.execute-api.us-east-1.amazonaws.com/staging",
  "sendMailContactUs": "https://iyanouw7ee.execute-api.us-east-1.amazonaws.com/default/ibrdsendmail",
  "adminEmail": "william.thivierge@ofitechnology.com",
  //"baseUrl": "https://www.406registration.com",
  "baseUrl": "http://ibrd-staging.s3-website-us-east-1.amazonaws.com",
  "createUserEndpoint":"https://srn99qz2n6.execute-api.us-east-1.amazonaws.com/default/createUser",
  "uploadFileEndpoint" : "https://lig3q2ynk1.execute-api.us-east-1.amazonaws.com/dev/fileupload",
}

window.IBRD_CONFIG = IBRD_CONFIG;
