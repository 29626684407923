import db, { getUserProfileDb, getAccountDb, getTokenDb, getOfflineAccountDb, storeOffline, getOfflineUserProfileDb } from "./dbService";
import { insertOrUpdateDocument } from "./serviceUtils";
import AppConfigState from "../state/appConfigState";
import AppState from "../state/appState";
import { fetchDatafromAPI } from "./serviceUtils";
import * as Cryptojs from "crypto-js";

const passphrase = "Spite Reduction Bribery Defense 1";

export function signUp(username, password, meta) {
  console.log("log: signUp -> sername, password, meta", username, password, meta);

  return new Promise((resolve, reject) => {
    db().signUp(
      username,
      password,
      {
        metadata: meta,
      },
      function(err, response) {
        console.log("log: signUp -> err, response", err, response);
        if (err) {
          reject(err);
        }
        resolve(response);
      }
    );
  });
}

export async function saveUserProfile(userProfile) {
  console.log("UserService: saveUserProfile -> userProfile", userProfile);
  let userProfileDb = getUserProfileDb();
  try {
    let result = await insertOrUpdateDocument(userProfile, userProfileDb);
    return result;
  } catch (error) {
    console.log("log: saveUserProfile -> error", error);
    throw error;
  }
}

export async function lambdaUpdateUserProfile(values) {
  try {
    let data = {
      values: values,
      action: "updateUserProfile",
    };
    return await fetchDatafromAPI(IBRD_CONFIG.createUserEndpoint, data);
  } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
  }
}

export async function login(username, password) {
  console.log("log: login -> username, password", username, password);
  try {
    let result;
    if (AppState.isOnline) {
      result = await db().login(username, password);

      console.log("log ~ file: userServices.js ~ line 61 ~ login ~ result", result);
      await storeCredentials(username, password, result);
      let test = await loginOffline(username, password);
      //console.log('log ~ file: userServices.js ~ line 63 ~ login ~ test', test);
    } else {
      result = await loginOffline(username, password);
      console.log("log ~ file: userServices.js ~ line 68 ~ login ~ result", result);
    }

    return result;
  } catch (err) {
    if (err.name === "unauthorized") {
      console.warn("userService -> unauthorized - trying by hexid or email", err);
      try {
        let findUsernameResult = await tryFindUsername(username, password, err);
        if (findUsernameResult.isLegacyUser) {
          return findUsernameResult;
        }
        let secondTryResult = await db().login(findUsernameResult.username, password);
        return secondTryResult;
      } catch (error) {
        throw error;
      }
    } else {
      console.error("userService -> unauthorized - other error", err);
    }
    throw err;
  }
}

export async function tryFindUsername(username, password, err) {
  try {
    let result = await lambdaFindUsername(username);
    console.log("userService: tryFindUsername -> result", result);

    if (!result.username) {
      throw err;
    }
    return result;
  } catch (error) {
    throw error;
  }
}

export async function logOut() {
  try {
    let result = await db().logOut();
    console.log("log: logOut -> result", result);
    return result;
  } catch (error) {
    console.log("log: logOut -> error", error);
  }
}

export async function getUserProfile(userId) {
  console.log("log: getUserProfile -> userId", userId);
  let userProfileDb = getUserProfileDb();
  try {
    let userProfile = await userProfileDb.get(userId);
    let offlineUserProfileDb = getOfflineUserProfileDb();
    let result = await storeOffline(userProfile, offlineUserProfileDb);
    console.log("log ~ file: userServices.js ~ line 123 ~ getUserProfile ~ result", result);
    return userProfile;
  } catch (error) {
    console.log("log: getUserProfile -> error", error);
    //throw error
    // find legacy user!
    /*let username = userId.split(":")[1];
    let userProfile = await userProfileDb.get(username);
    // console.log("log: getUserProfile -> find legacy user: ", username, userProfile);
    if (userProfile) {
      error.isLegacyUser = true;
      error.legacyUserProfile = userProfile;
    }
    ;*/
  }
}

export async function getUserObject(userName) {
  try {
    let userObject;
    if (AppState.isOnline) {
      userObject = await db().getUser(userName);
      console.log("log ~ file: userServices.js ~ line 144 ~ getUserObject ~ userObject", userObject);
      storeUserObject(userName, userObject);
    } else {
      let offlineAccountDb =  getOfflineAccountDb();
      let offlineCache = await offlineAccountDb.get("org.couchdb.user:" + userName);
      userObject = offlineCache.userObject;
      userObject._id = offlineCache._id; //just mocking the original format because of the offline workaround
    }

    return userObject;
  } catch (error) {
    throw error;
  }
}

export async function checkForSession() {
  try {
    let session = await db().getSession();
    console.log("log: checkForSession -> session", session);
    let user;

    if (session.userCtx.name) {
      console.log("log: checkForSession -> session.userCtx.name", session.userCtx.name);
      user = await db().getUser(session.userCtx.name);
      console.log("log: checkForSession -> user", user);
    }
    console.log("log: initDbConnections -> session", session, user);
    return user;
  } catch (error) {
    console.log("log: initDbConnections -> error", error);
  }
}

export async function getUser(userId) {
  let authDb_remote = getAccountDb();
  return authDb_remote.get(userId);
}

export async function putUser(user) {
  let authDb_remote = getAccountDb();
  return authDb_remote.put(user);
}

export async function lambdaSignup(values) {
  try {
    let data = {
      values: values,
      action: "createUser",
    };
    return await fetchDatafromAPI(IBRD_CONFIG.createUserEndpoint, data);
  } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
  }
}

export async function lambdaCreateDelegatedUser(values) {
  try {
    let data = {
      values: values,
      action: "createDelegatedUser",
    };
    return await fetchDatafromAPI(IBRD_CONFIG.createUserEndpoint, data);
  } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
  }
}

export async function lambdaDeleteUser(userId) {
  try {
    let result;
    if (userId.includes("org.couchdb.user:")) {
      let data = {
        userId: userId,
        action: "deleteUser",
      };
      result = await fetchDatafromAPI(IBRD_CONFIG.createUserEndpoint, data);
    } else {
      let userProfileDb = getUserProfileDb();
      let userDoc = await userProfileDb.get(userId);
      result = await userProfileDb.remove(userDoc);
    }

    console.log("log: lambdaDeleteUser -> result", result);
    return result;
  } catch (error) {
    console.log("log: lambdaDeleteUser -> error", error);
  }
}

export async function lambdaFindUsername(values) {
  try {
    let data = {
      values: values,
      action: "findUsername",
    };
    return await fetchDatafromAPI(IBRD_CONFIG.createUserEndpoint, data);
  } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
  }
}

export async function lambdaResetpassword(userId, values, token) {
  console.log("log: lambdaResetpassword -> token", token);
  try {
    let data = {
      values: values,
      userId: userId,
      token: token,
      action: "resetPassword",
    };
    return await fetchDatafromAPI(IBRD_CONFIG.createUserEndpoint, data);
  } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
  }
}

export async function lambdaUploadFile(base64String) {
  try {
    let data = {
      base64String: base64String,
    };
    console.log("log: lambdaUploadFile -> base64String", data);
    return await fetchDatafromAPI(IBRD_CONFIG.uploadFileEndpoint, data);
  } catch (error) {}
}

export async function findUserAndCreateResetPasswordToken(values) {
  try {
    let data = {
      values: values,
      action: "findUserAndCreateResetPasswordToken",
    };
    return await fetchDatafromAPI(IBRD_CONFIG.createUserEndpoint, data);
  } catch (error) {
    console.log("log: createValidateEmailToken -> error", error);
  }
}

export async function checkIfUsernameExist(username) {
  let db = getAccountDb();
  try {
    //let user = db.get("org.couchdb.user:" + username);
    //return user;
    let result = await db.find({
      selector: { name: username },
      limit: 1,
    });
    console.log("log: userService -> checkIfUsernameExist -> result.docs", result.docs);
    return result.docs.length == 0 ? false : result.docs[0];
  } catch (error) {
    console.log("log: userService -> checkIfUsernameExist -> error", error);
    throw error;
  }
}

export async function checkIfEmailExist(emailAddress) {
  try {
    let userProfileDb = getUserProfileDb();
    var result = await userProfileDb.find({
      selector: { emailAddress: emailAddress },
      limit: 1,
    });
    console.log("log: userService -> checkIfEmailExist -> result.docs", result.docs);
    return result.docs.length == 0 ? false : result.docs[0];
  } catch (error) {
    console.log("log: userService -> checkIfEmailExist -> error: ", error);
    throw error;
  }
}

export async function searchUsers(criteria) {
  try {
    console.log(">>>>>>> search users: ", criteria);

    let db = getUserProfileDb();
    let selector = {
      // _id: { $gte: "org.couchdb.user:", $lte: "org.couchdb.user:\uffff" },
    };
    if (criteria.ownerName) {
      selector["ownerName"] = { $regex: "(?i)" + criteria.ownerName[0] + "(?i)" };
    }
    if (criteria.username) {
      selector["_id"] = { $regex: "(?i)" + criteria.username[0] + "(?i)" };
    }
    if (criteria.title) {
      selector["title"] = { $gte: criteria.title[0].toLowerCase(), $lte: criteria.title[0] + "\uffff" };
    }
    if (criteria.challengeQuestion) {
      selector["challengeQuestion"] = { $gte: criteria.challengeQuestion[0].toLowerCase(), $lte: criteria.challengeQuestion[0] + "\uffff" };
    }
    if (criteria.countryNumber) {
      selector["countryNumber"] = { $elemMatch: { $eq: criteria.countryNumber[0] } };
    }
    if (criteria.roleId) {
      selector["roleId"] = { $eq: criteria.roleId[0] };
    }
    if (criteria.emailAddress) {
      selector["emailAddress"] = { $regex: "(?i)" + criteria.emailAddress[0] + "(?i)" };
    }

    let result = await db.find({
      selector: selector,
    });

    console.log("log: searchUsers -> selector", selector, result);
    // console.log('>>>>>>> search users: ', result);
    result.docs.map((row) => {
      if (!row) return;
      let _username = row._id.includes("org.couchdb.user:") ? row._id.split(":")[1] : row._id;
      row.username = _username;
      return row;
    });
    return result.docs;
  } catch (error) {
    console.log("log: userService -> searchUsers -> error: ", error);
  }
}

export async function searchUsersByRole(roleId) {
  try {
    let db = getUserProfileDb();
    var result = await db.find({
      selector: {
        roleId: roleId,
      },
    });
    //console.log('UserService:searchUsersByRole - result', result);
    return result;
  } catch (err) {
    console.error("UserService:searchUsersByRole - error", err);
    throw err;
  }
}

export async function getUserProfileByOwnerName(ownerName) {
  try {
    let db = getUserProfileDb();
    var result = await db.find({
      selector: {
        ownerName: { $regex: "(?i)" + ownerName + "(?i)" },
      },
    });

    let _ids = [];
    if (result) {
      _ids = result.docs.map((row) => {
        return row._id;
      });
    }
    return _ids;
  } catch (err) {
    console.error("UserService:searchUsersByRole - error", err);
    throw err;
  }
}

export async function getAllUserProfiles() {
  try {
    let userProfileDb = getUserProfileDb();
    // let userProfile = await userProfileDb.allDocs({include_docs: true, startkey: 'org.couchdb.user:', endkey: 'org.couchdb.user:\uffff' });
    let userProfile = await userProfileDb.allDocs({ include_docs: true });
    return userProfile;
  } catch (error) {
    throw error;
  }
}

export async function deleteUser(userId) {
  console.log("log: deleteUser -> userId", userId);
  try {
    let db = getAccountDb();
    let doc = await db.get(userId);
    console.log("log: deleteUser -> doc", doc);
    return await db.remove(doc);
  } catch (error) {
    console.warn("log: deleteUser -> error", error);
  }
}

export async function deleteUserProfile(userId) {
  console.log("log: deleteUser -> userId", userId);
  try {
    let db = getUserProfileDb();
    let doc = await db.get(userId);
    console.log("log: deleteUser -> doc", doc);
    return await db.remove(doc);
  } catch (error) {
    console.warn("log: deleteUser -> error", error);
  }
}

export async function getPasswordResetInfo(token) {
  console.log("log: getPasswordResetInfo -> token", token);
  try {
    let db = getTokenDb();
    let tokenObject = await db.get(token);
    return tokenObject;
  } catch (error) {
    console.warn("log: getPasswordResetInfo -> error", error);
  }
}

async function storeCredentials(username, password, result) {
  console.log("log ~ file: userServices.js ~ line 433 ~ storeCredentials ~ username", username);
  var encrypted = Cryptojs.AES.encrypt(password, passphrase);
  let encryptedString = encrypted.toString();
  let localAccountDb = getOfflineAccountDb();
  let offlineCredObject = {
    _id: username,
    passsword: encryptedString,
    account: result,
  };
  try {
    await storeOffline(offlineCredObject, localAccountDb);
  } catch (error) {
    console.log("log ~ file: userServices.js ~ line 442 ~ storeCredentials ~ error", error);
  }
}

async function storeUserObject(username, userObject) {
  let localAccountDb = getOfflineAccountDb();
  let offlineUserObject = {
    _id: "org.couchdb.user:" + username,
    userObject: userObject,
  };
  try {
    await storeOffline(offlineUserObject, localAccountDb);
  } catch (error) {
    console.log("log ~ file: userServices.js ~ line 467 ~ storeUserObject ~ error", error);
  }
}

async function loginOffline(username, password) {
  console.log("checking credentials");
  let localAccountDb = getOfflineAccountDb();

  try {
    let localStorageUser = await localAccountDb.get(username);
    //console.log("log ~ file: userServices.js ~ line 444 ~ loginOffline ~ localStorageUser", localStorageUser);
    var decrypted = Cryptojs.AES.decrypt(localStorageUser.passsword, passphrase);
    var decryptedString = decrypted.toString(Cryptojs.enc.Utf8);
    //console.log("log ~ file: userServices.js ~ line 446 ~ loginOffline ~ decryptedString", decryptedString);
    if (decryptedString == password) {
      console.log("log ~ file: userServices.js ~ line 472 ~ loginOffline ~ decryptedString == password", decryptedString == password);
      return localStorageUser.account;
    } else {
      throw new Error("Wrong Password");
    }
  } catch (error) {
    throw error;
  }
}
